// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  DatePicker,
  DatePickerInput,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  TextArea,
  TextInput
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function ReportRequest(props) {
  const state = props.location.state;
  const [reportTitle, setReportTitle] = useState('');
  const [reportDescription, setReportDescription] = useState('');
  const [reportDetail, setReportDetail] = useState();
  const [recipients, setRecipients] = useState('');
  const [format, setFormat] = useState('CSV');
  const [generationDate, setGenerationDate] = useState('');
  const [generationTime, setGenerationTime] = useState('');
  const [frequency, setFrequency] = useState('1');
  const [filter, setFilter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({});

  const timeprops = {
    timepicker: () => ({
      pattern: '(1[012]|[1-9]):[0-5][0-9](\\s)?',
      placeholder: 'hh:mm',
      disabled: false,
      light: false,
      labelText: 'Select a time',
      invalid: false,
      maxLength: 5
    })
  };

  var formIsValid = true;
  var errorList = [];



  /**
   * Validators
   */
  function validateAttr(attrName, attrValue, mandatory) {
    if (mandatory) {
      if (attrValue === null || attrValue === '') {
        errorList[attrName] = 'This is a mandatory attribute';
        formIsValid = false;
      }
    }
  }

  function validateHHMM(attrName, attrValue) {
    let regexHHMM = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    if (attrValue === '') {
      // don't validate
    } else if (!regexHHMM.test(attrValue)) {
      errorList[attrName] = 'Invalid time format, try HH:MM instead';
      formIsValid = false;
    }
  }

  function validateEmail(attrName, attrValue) {
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (attrValue === '') {
      // don't validate
    } else if (!regexEmail.test(attrValue)) {
      errorList[attrName] = 'Invalid email address format';
      formIsValid = false;
    }
  }

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    errorList = [];
    formIsValid = true;
    validateAttr('recipients', recipients, true);
    validateAttr('format', format, true);
    validateAttr('generationdate', generationDate, true);
    validateAttr('generationtime', generationTime, true);
    validateHHMM('generationtime', generationTime);
    validateAttr('frequency', frequency, true);

    // validate email addresses
    var emails = recipients.split(",");
    emails.forEach((email) => {
      validateEmail('recipients', email);
    });

    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    var emails = recipients.split(",");
    if (validateForm()) {
      var payLoad = {
        report: state.reportid,
        recipients: emails,
        startDate: generationDate,
        executionTime: generationTime,
        frequency: frequency,
        format: format,
        filter: filter
      }

      axios.post(apiuri + '/reportschedule', payLoad, { headers: httpHeaders })
        .then((response) => { window.location.href = '/reports'; })
        .catch((error) => {
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.reason) {
              setModalMessage('There has been an error requesting your report: ' + error.response.data.reason);
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }



  /**
   * Load data
   */
  useEffect(() => {
    axios.get(apiuri + '/report/' + state.reportid, { headers: httpHeaders })
      .then((response) => {
        setReportTitle(response.data.name);
        setReportDescription(response.data.description);
        var repAttrs = response.data.attributes.map((attr) =>
          <li>{attr}</li>
        );
        setReportDetail(repAttrs);
      })
      .catch((error) => {
        setModalMessage('An error has occurred retrieving the report definition.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }, []);

  /**
   * Return the component detail
   */
  return (
      <>
        <UMTHeader />
        <Navigation />
        <Content id="main-content">
          <div className="bx--grid bx--grid--full-width">
            <div className="bx--row">
              <div className="bx--col-lg-10">
                <Breadcrumb
                  noTrailingSlash
                  aria-label="Page navigation"
                  className="umt--breadcrumb"
                >
                  <BreadcrumbItem href="/reports">Reports</BreadcrumbItem>
                  <BreadcrumbItem href="/reports">
                    Run/schedule a report
                  </BreadcrumbItem>
                </Breadcrumb>

                <h3 className="umt--heading">{reportTitle}</h3>

                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['recipients']}</span>
                    <TextArea
                      cols={50}
                      helperText="Add a comma-separated list of email address, e.g. joe.bloggs@acme.com,support@acme.com"
                      id="recipients"
                      labelText="Report recipients"
                      placeholder="joe.bloggs@acme.com,support@acme.com"
                      rows={4}
                      onChange={(e) => setRecipients(e.target.value)}
                    />
                  </div>

                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['format']}</span>
                    <Select
                      id="format"
                      labelText="Report format"
                      onChange={(e) => setFormat(e.target.value)}
                    >
                      <SelectItem text="Comma Separated (CSV)" value="CSV" />
                      <SelectItem text="Adobe Acrobat (PDF)" value="PDF" />
                      <SelectItem text="Microsoft Excel (XLSX)" value="XLSX" />
                      <SelectItem text="Microsoft Word (DOCX)" value="DOCX" />
                    </Select>
                  </div>

                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['generationdate']}</span>
                    <DatePicker
                      id="generationdate"
                      datePickerType="single"
                      dateFormat="d/m/Y"
                      onChange={(e) => setGenerationDate(e)}
                    >
                      <DatePickerInput
                        id="generationdateselect"
                        labelText="Report generation date"
                        onChange={(e) => setGenerationDate(e)}
                      />
                    </DatePicker>
                  </div>

                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['generationtime']}</span>
                    <TextInput
                      value={generationTime}
                      id="generationtime"
                      type="text"
                      labelText="Report generation time"
                      placeholder="09:47/21:47"
                      onChange={(e) => setGenerationTime(e.target.value)}
                    />
                  </div>

                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['frequency']}</span>
                    <Select
                      helperText="Reports will also be available for download from within the UMT platform in accordance with your tenancy's retention period policy"
                      defaultValue="1"
                      id="frequency"
                      labelText="Report frequency"
                      onChange={(e) => setFrequency(e.target.value)}
                    >
                      <SelectItem text="One off" value="1" />
                      <SelectItem text="Daily" value="2" />
                      <SelectItem text="Weekly" value="3" />
                      <SelectItem text="Monthly" value="4" />
                      <SelectItem text="Quarterly" value="5" />
                      <SelectItem text="Bi-annually" value="6" />
                      <SelectItem text="Annually" value="7" />
                    </Select>
                  </div>

                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['filter']}</span>
                    <TextInput
                      value={filter}
                      id="filter"
                      type="text"
                      labelText="Filter"
                      placeholder='{ "displayName": "Joe Bloggs" }'
                      onChange={(e) => setFilter(e.target.value)}
                    />
                  </div>

                  <ButtonSet>
                    <Button kind="primary" type="submit">
                      Submit
                    </Button>
                    <Button
                      kind="secondary"
                      onClick={() =>
                        (window.location.href = '/reports')
                      }
                    >
                      Cancel
                    </Button>
                  </ButtonSet>
                </Form>
              </div>
              <div className="bx--col-lg-6">
                <h3 className="umt--helper-heading">{reportTitle} description</h3>
                <p className="umt--helper-p">{reportDescription}</p>
                <h3 className="umt--helper-heading">{reportTitle} definition</h3>
                <p className="umt--helper-p">
                  <ul>
                    {reportDetail}
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </Content>
        {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Report request</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
      </>
    );
  }
// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  Checkbox,
  ComposedModal,
  Content,
  DataTable,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TextArea,
  TextInput,
  Tile
} from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';

const apiuri = process.env.REACT_APP_APIURI;

const headers = [
  { key: 'displayName', header: 'Display name' }
];

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function Role({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);

  const [userTypes, setUserTypes] = useState([]);
  
  const [roleId, setRoleId] = useState('');
  const [roleName, setRoleName] = useState('');
  const [roleNameInit, setRoleNameInit] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [roleEmail, setRoleEmail] = useState('');
  const [roleProtected, setRoleProtected] = useState(false);
  const [attributeR, setattributeR] = useState(false);
  const [attributeW, setattributeW] = useState(false);
  const [auditR, setauditR] = useState(false);
  const [bulkW, setbulkW] = useState(false);
  const [lcrR, setlcrR] = useState(false);
  const [lcrW, setlcrW] = useState(false);
  const [mailtemplateR, setmailtemplateR] = useState(false);
  const [mailtemplateW, setmailtemplateW] = useState(false);
  const [namevaluepairR, setnamevaluepairR] = useState(false);
  const [namevaluepairW, setnamevaluepairW] = useState(false);
  const [reportR, setreportR] = useState(false);
  const [reportW, setreportW] = useState(false);
  const [reportscheduleR, setreportscheduleR] = useState(false);
  const [reportscheduleW, setreportscheduleW] = useState(false);
  const [roleR, setroleR] = useState(false);
  const [roleW, setroleW] = useState(false);
  const [settingsR, setsettingsR] = useState(false);
  const [settingsW, setsettingsW] = useState(false);
  const [thirdpartyR, setthirdpartyR] = useState(false);
  const [thirdpartyW, setthirdpartyW] = useState(false);
  const [userR, setuserR] = useState(false);
  const [userW, setuserW] = useState(false);
  const [usertypeR, setusertypeR] = useState(false);
  const [usertypeW, setusertypeW] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // Validate roleName
    if (roleName === '') {
      errorList.roleName = 'Role name cannot be empty';
      formIsValid = false;
    } else if (roleName.length > 64) {
      errorList.roleName = 'Role name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate roleEmail
    if (roleEmail) {
      if (roleEmail !== '') {
        if (!regexEmail.test(roleEmail)) {
          errorList.email = 'Invalid email address format';
          formIsValid = false;
        }
      }
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    if (validateForm()) {
      return isAddMode ? createRole() : updateRole();
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function buildPayload() {
    var payLoad = { name: roleName }
    if (roleDescription !== '') { payLoad.description = roleDescription }
    if (roleEmail !== '') { payLoad.email = roleEmail }
    var uts = [];
    userTypes.forEach((element) => {
      if (element.checked) {
        uts.push(element.id);
      }
    })
    payLoad.validUserTypes = uts;
    var permissions = [];
    if (attributeR) { permissions.push('attribute_r') }
    if (attributeW) { permissions.push('attribute_w') }
    if (auditR) { permissions.push('audit_r') }
    if (bulkW) { permissions.push('bulk_w') }
    if (lcrR) { permissions.push('lcr_r'); permissions.push('mailtemplate_r'); }
    if (lcrW) { permissions.push('lcr_w'); permissions.push('mailtemplate_r'); }
    if (mailtemplateR) { permissions.push('mailtemplate_r') }
    if (mailtemplateW) { permissions.push('mailtemplate_w') }
    if (namevaluepairR) { permissions.push('namevaluepair_r') }
    if (namevaluepairW) { permissions.push('namevaluepair_w') }
    if (reportR) { permissions.push('report_r') }
    if (reportW) { permissions.push('report_w') }
    if (reportscheduleR) { permissions.push('reportschedule_r') }
    if (reportscheduleW) { permissions.push('reportschedule_w') }
    if (roleR) { permissions.push('role_r') }
    if (roleW) { permissions.push('role_w') }
    if (settingsR) { permissions.push('settings_r') }
    if (settingsW) { permissions.push('settings_w') }
    if (thirdpartyR) { permissions.push('thirdparty_r'); permissions.push('role_r'); }
    if (thirdpartyW) { permissions.push('thirdparty_w'); permissions.push('role_r'); }
    if (userR) { permissions.push('user_r') }
    if (userW) { permissions.push('user_w') }
    if (usertypeR) { permissions.push('usertype_r'); permissions.push('role_r'); }
    if (usertypeW) { permissions.push('usertype_w'); permissions.push('role_r'); }
    
    payLoad.permissions = permissions;

    return payLoad;
  }

  function createRole() {
    var payLoad = buildPayload();
    axios
      .post(apiuri + '/role', payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/role') })
      .catch((error) => {
        setModalMessage('An error has occurred creating the role.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred creating the role: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateRole() {
    var payLoad = buildPayload();
    axios
      .put(apiuri + '/role/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/role'); })
      .catch((error) => {
        setModalMessage('An error has occurred updating the role.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            var errMsg = "";
            if (error.response.data.message) {
              errMsg = error.response.data.message;
            }
            if (error.response.data.reason[0]) {
              errMsg = error.response.data.reason[0];
            }
            setModalMessage('An error has occurred updating the role: ' + errMsg);
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function handleMemberRemoval(userId) {
    var payLoad = {
      "schemas": ['urn:ietf:params:scim:api:messages:2.0:PatchOp'],
      "Operations": [
          {
            "op": "remove",
            "path": "members",
            "value": userId
          }
      ]
    }

    axios
      .patch(apiuri + '/role/' + roleId, payLoad, { headers: httpHeaders })
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setModalMessage('An error has occurred removing a user from the role.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred removing the user from the role: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      })
  }



  /**
   * Load data
   */
  function loadData() {
    axios.get(apiuri + '/usertype/all', { headers: httpHeaders })
    .then((response) => {
      var utArray = [];
      response.data.forEach((element) => {
        if (element.enabled) {
          utArray.push({ id: element.id, label: element.name });
        }
      });

      if (isAddMode) {
        setUserTypes(utArray);
      } else {
        axios
        .get(apiuri + '/role/' + id, { headers: httpHeaders })
        .then((response) => {
          if (response.data) {
            var role = response.data;

            var payLoad = [];
            var aUsers = 0;
            var iUsers = 0;
            role.members.forEach((member) => {
              var obj = {
                id: member._id
              }
              if (!member.displayName) {
                if ((!member.name) || ((!member.name.givenName) && (!member.name.familyName))) {
                  // This should never happen!
                } else {
                  member.displayName = member.name.givenName + " " + member.name.familyName;
                }
              }
              
              // If we still don't have a displayname - all bets are off
              if (member.displayName) {
                obj.displayName = <Link href={'/users/edit/' + member._id}>{member.displayName}</Link>;
                payLoad.push(obj);
                if (member.active) {
                  aUsers++;
                } else {
                  iUsers++;
                }
              }
            })
            setRows(payLoad);
            setTotalItems(payLoad.length);
            setTotalUsers(payLoad.length);
            setTotalActiveUsers(aUsers);
            setTotalInactiveUsers(iUsers);

            setRoleId(id);
            setRoleName(role.name);
            setRoleNameInit(role.name);
            setRoleDescription(role.description);
            setRoleEmail(role.email);
            setRoleProtected(role.protected);

            var vuts = "";
            if (role.validUserTypes) {
              vuts = role.validUserTypes.toString();
            }

            var vut = [];
            utArray.forEach((element) => {
              if (vuts.indexOf(element.id) > -1) {
                element.checked = true;
              } else {
                element.checked = false;
              }
              vut.push(element);
            });
            setUserTypes(vut);
           
            if (role.permissions) {
              if(role.permissions.includes('attribute_r')) { setattributeR(true) }
              if(role.permissions.includes('attribute_w')) { setattributeW(true) }
              if(role.permissions.includes('audit_r')) { setauditR(true) }
              if(role.permissions.includes('bulk_w')) { setbulkW(true) }
              if(role.permissions.includes('lcr_r')) { setlcrR(true) }
              if(role.permissions.includes('lcr_w')) { setlcrW(true) }
              if(role.permissions.includes('mailtemplate_r')) { setmailtemplateR(true) }
              if(role.permissions.includes('mailtemplate_w')) { setmailtemplateW(true) }
              if(role.permissions.includes('namevaluepair_r')) { setnamevaluepairR(true) }
              if(role.permissions.includes('namevaluepair_w')) { setnamevaluepairW(true) }
              if(role.permissions.includes('report_r')) { setreportR(true) }
              if(role.permissions.includes('report_w')) { setreportW(true) }
              if(role.permissions.includes('reportschedule_r')) { setreportscheduleR(true) }
              if(role.permissions.includes('reportschedule_w')) { setreportscheduleW(true) }
              if(role.permissions.includes('role_r')) { setroleR(true) }
              if(role.permissions.includes('role_w')) { setroleW(true) }
              if(role.permissions.includes('settings_r')) { setsettingsR(true) }
              if(role.permissions.includes('settings_w')) { setsettingsW(true) }
              if(role.permissions.includes('thirdparty_r')) { setthirdpartyR(true) }
              if(role.permissions.includes('thirdparty_w')) { setthirdpartyW(true) }
              if(role.permissions.includes('usertype_r')) { setusertypeR(true) }
              if(role.permissions.includes('usertype_w')) { setusertypeW(true) }
              if(role.permissions.includes('user_r')) { setuserR(true) }
              if(role.permissions.includes('user_w')) { setuserW(true) }
            }
          }
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the list of role.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the role: ' + error.response.data.message);
              }
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });  // end of GET of role
      }
    }); // end of GET user types
  }



  function handleCheckboxChange(event) {
    const id = event.target.value;
    const isChecked = event.target.checked;

    var uts = [];
    userTypes.forEach((element) => {
      if (element.id === id) {
        if (isChecked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      }
      uts.push(element);
    })

    setUserTypes(uts);
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="bx--grid bx--grid--full-width">
            <div className="bx--row">
              <div className="bx--col-lg-8">
                <Breadcrumb noTrailingSlash aria-label="Page navigation" className="umt--breadcrumb">
                  <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                  <BreadcrumbItem href="/admin/role">Roles</BreadcrumbItem>
                </Breadcrumb>

                <h3 className="umt--heading">
                  {isAddMode ? 'Add role' : 'Edit role'}
                </h3>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['roleName']}</span>
                  <TextInput
                    value={roleName}
                    id="name"
                    type="text"
                    labelText="Role name"
                    helperText="Unique name for the role"
                    onChange={(e) => setRoleName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['roleDescription']}
                  </span>
                  <TextArea
                    value={roleDescription}
                    cols={50}
                    id="description"
                    invalidText="Invalid error message."
                    labelText="Role description"
                    helperText="Describe how the role will be used, e.g., approvals or administration"
                    rows={4}
                    onChange={(e) => setRoleDescription(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['roleEmail']}
                  </span>
                  <TextInput
                    value={roleEmail}
                    id="email"
                    type="text"
                    labelText="Shared mailbox address"
                    helperText="Notifications for users with this role will be sent to this email, rather than the users' individual emails"
                    onChange={(e) => setRoleEmail(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <fieldset>
                    <legend className="cds--label">User types</legend>
                    {userTypes.map(item => (
                      <label key={item.id}>
                        <input
                          type="checkbox"
                          value={item.id}
                          checked={item.checked}
                          onChange={(e) => handleCheckboxChange(e)}
                        />
                        {item.label}
                        <br />
                      </label>
                    ))}
                  </fieldset>
                </div>

                <hr />

                <div className="bx--row">
                  <div className="bx--col-lg-5">
                    <div style={{ marginBottom: '2rem' }}>
                      <p>Read permissions</p>
                      <Checkbox labelText='View all users' id='checkbox-r-users' checked={userR} onChange={(e) => setuserR(!userR)} />
                      <Checkbox labelText='Run reports' id='checkbox-r-reports' checked={reportscheduleR} onChange={(e) => setreportscheduleR(!reportscheduleR)} />
                      <Checkbox labelText='View third parties' id='checkbox-r-tp' checked={thirdpartyR} onChange={(e) => setthirdpartyR(!thirdpartyR)} />
                      <Checkbox labelText='View user types' id='checkbox-r-usertype' checked={usertypeR} onChange={(e) => setusertypeR(!usertypeR)} />
                      <Checkbox labelText='View roles' id='checkbox-r-role' checked={roleR} onChange={(e) => setroleR(!roleR)} />
                      <Checkbox labelText='View lifecycle rules' id='checkbox-r-lcr' checked={lcrR} onChange={(e) => setlcrR(!lcrR)} />
                      <Checkbox labelText='View mail templates' id='checkbox-r-mt' checked={mailtemplateR} onChange={(e) => setmailtemplateR(!mailtemplateR)} />
                      <Checkbox labelText='View name-value pairs' id='checkbox-r-nvp' checked={namevaluepairR} onChange={(e) => setnamevaluepairR(!namevaluepairR)} />
                      <Checkbox labelText='View schema attributes' id='checkbox-r-schema' checked={attributeR} onChange={(e) => setattributeR(!attributeR)} />
                      <Checkbox labelText='View settings' id='checkbox-r-settings' checked={settingsR} onChange={(e) => setsettingsR(!settingsR)} />
                      <Checkbox labelText='View audit log' id='checkbox-r-audit' checked={auditR} onChange={(e) => setauditR(!auditR)} />
                    </div>
                  </div>
                  <div className="bx--col-lg-5">
                    <div style={{ marginBottom: '2rem' }}>
                      <p>Write permissions</p>
                      <Checkbox labelText='Manage all users' id='checkbox-w-users' checked={userW} onChange={(e) => setuserW(!userW)} />
                      <Checkbox labelText='Bulk add users' id='checkbox-w-bulk' checked={bulkW} onChange={(e) => setbulkW(!bulkW)} />
                      <Checkbox labelText='Create reports' id='checkbox-w-reports' checked={reportW} onChange={(e) => setreportW(!reportW)} />
                      <Checkbox labelText='Manage third parties' id='checkbox-w-tp' checked={thirdpartyW} onChange={(e) => setthirdpartyW(!thirdpartyW)} />
                      <Checkbox labelText='Manage user types' id='checkbox-w-usertype' checked={usertypeW} onChange={(e) => setusertypeW(!usertypeW)} />
                      <Checkbox labelText='Manage roles' id='checkbox-w-role' checked={roleW} onChange={(e) => setroleW(!roleW)} />
                      <Checkbox labelText='Manage lifecycle rules' id='checkbox-w-lcr' checked={lcrW} onChange={(e) => setlcrW(!lcrW)} />
                      <Checkbox labelText='Manage mail templates' id='checkbox-w-mt' checked={mailtemplateW} onChange={(e) => setmailtemplateW(!mailtemplateW)} />
                      <Checkbox labelText='Manage name-value pairs' id='checkbox-w-nvp' checked={namevaluepairW} onChange={(e) => setnamevaluepairW(!namevaluepairW)} />
                      <Checkbox labelText='Manage schema attributes' id='checkbox-w-schema' checked={attributeW} onChange={(e) => setattributeW(!attributeW)} />
                      <Checkbox labelText='Manage settings' id='checkbox-w-settings' checked={settingsW} onChange={(e) => setsettingsW(!settingsW)} />
                    </div>
                  </div>
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    {isAddMode ? 'Create role' : 'Update role'}
                  </Button>
                  <Button kind="secondary" onClick={() => (window.location.href = '/admin/role/')}>
                    Cancel
                  </Button>
                </ButtonSet>

              </div>

              <div className="bx--col-lg-8">
                {!isAddMode
                  ?
                  <>
                    <h3 className="umt--helper-heading">{roleNameInit} statistics</h3>
                    <div className="bx--row">
                      <div className="bx--col-lg-3">
                        <Tile>
                          <h3 className="umt--tile-header">
                            Total Users
                          </h3>
                          <p className="umt--tile-value">{totalUsers}</p>
                        </Tile>
                      </div>
                      <div className="bx--col-lg-3" />
                      <div className="bx--col-lg-3">
                        <Tile>
                          <h3 className="umt--tile-header">
                            Active Users
                          </h3>
                          <p className="umt--tile-value">{totalActiveUsers}</p>
                        </Tile>
                      </div>
                      <div className="bx--col-lg-3" />
                      <div className="bx--col-lg-3">
                        <Tile>
                          <h3 className="umt--tile-header">
                            Inactive Users
                          </h3>
                          <p className="umt--tile-value">{totalInactiveUsers}</p>
                        </Tile>
                      </div>
                    </div>
                    <p className="umt--helper-p"></p>

                    <h3 className="umt--helper-heading">{roleNameInit} members</h3>
                    <DataTable
                      rows={rows.slice(
                        firstRowIndex,
                        firstRowIndex + currentPageSize
                      )}
                      headers={headers}
                      isSortable
                      render={({
                        rows,
                        headers,
                        getHeaderProps,
                        getTableProps
                      }) => (
                        <TableContainer className="umt--table-container">
                          <Table className="umt--table" {...getTableProps()}>
                            <TableHead>
                              <TableRow>
                                {headers.map((header) => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                                <TableHeader className="umt--table-overflow" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell) => (
                                    <TableCell key={cell.id}>
                                        {cell.value}
                                    </TableCell>
                                  ))}
                                    <TableCell className="umt--table-cell">
                                      <Button
                                        size="small"
                                        kind="ghost"
                                        renderIcon={TrashCan16}
                                        hasIconOnly
                                        iconDescription="Remove user from role"
                                        tooltipAlignment="end"
                                        onClick={() => { handleMemberRemoval(row.id) }}
                                      ></Button>
                                    </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    />
                    <Pagination
                      className="umt--pagination"
                      size="sm"
                      totalItems={totalItems}
                      backwardText="Previous page"
                      forwardText="Next page"
                      pageSize={currentPageSize}
                      pageSizes={[10]}
                      itemsPerPageText="# per page"
                      pageInputDisabled
                      pageSizeInputDisabled
                      onChange={({ page, pageSize }) => {
                        if (pageSize !== currentPageSize) {
                          setCurrentPageSize(pageSize);
                        }
                        setFirstRowIndex(pageSize * (page - 1));
                      }}
                    />
                  </>
                :
                  <>
                    <h3 className="umt--helper-heading">Help</h3>
                    <p className="umt--helper-p">
                      A role is a collection of users who perform a specific function.
                      That function could be that they perform approvals for
                      requests such as contractor extensions, or it could be an
                      administrative role that covers the administration of a
                      collection of users, e.g., Helpdesk.
                    </p>
                  </>
                }
              </div>
            </div>
          </div>
        </Form>
      </Content>

      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Role update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';
import { AppContext } from '../../lib/context';

// Import elements
import {
  Button,
  ButtonSet,
  ComposedModal,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  Tab,
  Tabs,
  TextInput,
} from 'carbon-components-react';

import { Breadcrumb, BreadcrumbItem, Content } from 'carbon-components-react';
import { useAppContext } from '../../lib/context';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

const pjson = require('../../../package.json');

/**
 * Core function
 */
export default function Settings({ history }) {
  const [tenantName, setTenantName] = useState('');
  const [smtpServer, setSMTPServer] = useState('');
  const [smtpPort, setSMTPPort] = useState('');
  const [smtpUser, setSMTPUser] = useState('');
  const [smtpPass, setSMTPPass] = useState('');
  const [email, setEmailAddress] = useState('');
  const [reportRetention, setReportRetention] = useState('');
  const [allowPreApproval, setAllowPreApproval] = useState(false);
  const [allowSelfApproval, setAllowSelfApproval] = useState(false);
  const [reviewPeriod, setReviewPeriod] = useState();
  const {isOidcConfig, setIsOidcConfig} = useAppContext();
  const [enableOIDC, setEnableOIDC] = useState(false);
  const [oidcClientURL, setOIDCClientURL] = useState('');
  const [oidcClientID, setOIDCClientID] = useState('');
  const [oidcClientSecret, setOIDCClientSecret] = useState('');
  const [apiVersion, setApiVersion] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalMessage, setModalMessage] = useState();
  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Handle form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    let regexNumber = /^[0-9]*$/i;

    // Validate tenantName
    if (tenantName === '') {
      errorList.tenantName = 'Tenant name cannot be empty';
      formIsValid = false;
    } else if (tenantName.length > 64) {
      errorList.tenantName =
        'Tenant name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate email
    if (email === '') {
      errorList.email = 'Email address cannot be empty';
      formIsValid = false;
    } else if (!regexEmail.test(email)) {
      errorList.email = 'Invalid email address format';
      formIsValid = false;
    }

    // Validate SMTP Server
    if (smtpServer === '') {
      errorList.smtpServer = 'SMTP server cannot be empty';
      formIsValid = false;
    }

    // Validate SMTP Port
    if (smtpPort === '') {
      errorList.smtpPort = 'SMTP port cannot be empty';
      formIsValid = false;
    } else if (!regexNumber.test(smtpPort)) {
      errorList.smtpPort = 'SMTP port must be numeric';
      formIsValid = false;
    }

    // Validate Review Period
    if (reviewPeriod === '') {
      errorList.reviewPeriod = 'Review period cannot be empty';
      formIsValid = false;
    } else if (!regexNumber.test(reviewPeriod)) {
      errorList.reviewPeriod = 'Review period must be numeric';
      formIsValid = false;
    }

    // Validate OIDC settings
    if (enableOIDC === 'true' || enableOIDC === true) {
      if (oidcClientID === '') {
        errorList.oidcClientID = 'Client ID cannot be empty';
        formIsValid = false;
      }    
      if (oidcClientURL === '') {
        errorList.oidcClientURL = 'Client URL cannot be empty';
        formIsValid = false;
      }
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit() {
    if (validateForm()) {
      var payLoad = {
        tenantName: tenantName,
        email: email,
        smtp: {
          server: smtpServer,
          port: smtpPort,
          user: smtpUser,
          pass: smtpPass
        },
        reports: {
          retentionPeriod: reportRetention,
        },
        approvals: {
          allowSelfApproval: allowSelfApproval,
          allowPreApproval: allowPreApproval
        },
        reviews: {
          reviewPeriod: reviewPeriod
        },
        oidc: {
          enabled: enableOIDC,
          clientURL: oidcClientURL,
          clientID: oidcClientID,
          clientSecret: oidcClientSecret
        }
      };

      if (enableOIDC === true && oidcClientID !== '' && oidcClientSecret !== '' && oidcClientURL !== '') {
        setIsOidcConfig(true);
      }

      axios
        .put(apiuri + '/settings', payLoad, { headers: httpHeaders })
        .then((response) => {
          setModalMessage('Settings successfully updated');
          setModalType('primary');
          setModalOpen(true);
          history.push('/admin/settings');
        })
        .catch((error) => {
          setModalMessage('An error has occurred updating the settings');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred updating the settings: ' + error.response.data.message);
              }
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  useEffect(() => {
    axios
      .get(apiuri + '/settings', { headers: httpHeaders })
      .then((response) => {
        setTenantName(response.data.tenantName);
        setEmailAddress(response.data.email);
        setSMTPServer(response.data.smtp.server);
        setSMTPPort(response.data.smtp.port.toString());
        setSMTPUser(response.data.smtp.user);
        setSMTPPass(response.data.smtp.pass);
        setReportRetention(response.data.reports.retentionPeriod.toString());
        setAllowSelfApproval(response.data.approvals.allowSelfApproval);
        setAllowPreApproval(response.data.approvals.allowPreApproval);
        setReviewPeriod(response.data.reviews.reviewPeriod);
        setEnableOIDC(response.data.oidc.enabled);
        if (typeof response.data.oidc.clientID == 'undefined') {
          setOIDCClientID('');
        } else {
          setOIDCClientID(response.data.oidc.clientID);
        }
        if (typeof response.data.oidc.clientSecret == 'undefined') {
          setOIDCClientSecret('');
        } else {
          setOIDCClientSecret(response.data.oidc.clientSecret);
        }
        if (typeof response.data.oidc.clientURL == 'undefined') {
          setOIDCClientURL('');
        } else {
          setOIDCClientURL(response.data.oidc.clientURL);
        }

        axios
          .get(apiuri + '/version', { headers: httpHeaders })
          .then((response) => {
            setApiVersion(response.data);
          })
          .catch((error) => {
            setModalMessage('An error has occurred retrieving the settings');
            if (error.response) {
              if (error.response.status === 401) {
                reauth();
              }
              if (error.response.status === 403) {
                setModalMessage('You are not permitted to perform that operation');
              } else {
                if (error.response.data.message) {
                  setModalMessage('An error has occurred retrieving the settings: ' + error.response.data.message);
                }
              }
            }
            setModalType('danger');
            setModalOpen(true);
          });
      })
      .catch((error) => {
        setModalMessage('An error has occurred retrieving the settings');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred retrieving the settings: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }, []);

  /**
   * Show details
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content>
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-10">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">Settings</h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <Tabs scrollIntoView={false} contained>
                    <Tab
                      href="#"
                      id="tab-1"
                      label="Tenancy settings"
                    >
                        <span style={{ color: 'red' }}>{formErrors['tenantName']}</span>
                        <div style={{ marginBottom: '2rem' }}>
                          <TextInput
                            value={tenantName}
                            helperText="The name for the tenancy"
                            id="tenancy"
                            labelText="Tenant name"
                            onChange={(e) => setTenantName(e.target.value)}
                          />
                        </div>

                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['email']}</span>
                          <TextInput
                            helperText="The address used as the FROM address for outgoing emails from the UMT system"
                            id="tifrom"
                            invalidText="Invalid error message."
                            labelText="Email address"
                            value={email}
                            onChange={(e) => setEmailAddress(e.target.value)}
                          />
                        </div>
                    </Tab>
                    <Tab
                      href="#"
                      id="tab-2"
                      label="SMTP settings"
                    >
                      <div className="some-content">
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>
                            {formErrors['smtpServer']}
                          </span>
                          <TextInput
                            helperText="The URL for your preferred SMTP server, e.g., smtp.acme.org"
                            id="smtpserver"
                            labelText="SMTP server URL"
                            value={smtpServer}
                            onChange={(e) => setSMTPServer(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['smtpPort']}</span>
                          <TextInput
                            value={smtpPort}
                            helperText="The port for your preferred SMTP server, e.g., 25, 587"
                            id="smtpport"
                            labelText="SMTP server port"
                            onChange={(e) => setSMTPPort(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['smtpUser']}</span>
                          <TextInput
                            value={smtpUser}
                            helperText="The SMTP user"
                            id="smtpuser"
                            labelText="SMTP user"
                            onChange={(e) => setSMTPUser(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['smtpPass']}</span>
                          <TextInput.PasswordInput
                            value={smtpPass}
                            helperText="The SMTP user's password"
                            id="smtppass"
                            labelText="SMTP password"
                            onChange={(e) => setSMTPPass(e.target.value)}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      href="#"
                      id="tab-3"
                      label='Report settings'
                    >
                      <div className="some-content">
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            helperText="The length of time that reports are kept within the system before being permanently deleted"
                            value={reportRetention}
                            id="reportRetention"
                            invalidText="Invalid error message."
                            labelText="Report retention period (days)"
                            onChange={(e) => setReportRetention(e.target.value)}
                          >
                            <SelectItem text="1 day" value="1" />
                            <SelectItem text="7 days" value="7" />
                            <SelectItem text="30 days" value="30" />
                            <SelectItem text="60 days" value="60" />
                            <SelectItem text="90 days" value="90" />
                            <SelectItem text="180 days" value="180" />
                            <SelectItem text="365 days" value="365" />
                            <SelectItem text="730 days" value="730" />
                          </Select>
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      href="#"
                      id="tab-4"
                      label='Workflow settings'
                    >
                      <div className="some-content">
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            value={allowPreApproval}
                            helperText="Pre-approves request if request is initiated by the approver"
                            id="allowPreApproval"
                            labelText="Allow pre-approval"
                            onChange={(e) => setAllowPreApproval(e.target.value)}
                          >
                            <SelectItem text="TRUE" value="true" />
                            <SelectItem text="FALSE" value="false" />
                          </Select>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            value={allowSelfApproval}
                            helperText="Approvers can approve their own requests"
                            id="allowSelfApproval"
                            labelText="Allow self-approval"
                            onChange={(e) => setAllowSelfApproval(e.target.value)}
                          >
                            <SelectItem text="TRUE" value="true" />
                            <SelectItem text="FALSE" value="false" />
                          </Select>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['reviewPeriod']}</span>
                          <TextInput
                            value={reviewPeriod}
                            helperText="The period (in days) within which a user must be reviewed"
                            id="reviewPeriod"
                            labelText="Review period"
                            onChange={(e) => setReviewPeriod(e.target.value)}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      href="#"
                      id="tab-5"
                      label='OIDC settings'
                    >
                      <div className="some-content">
                        <div style={{ marginBottom: '2rem' }}>
                          <Select
                            value={enableOIDC}
                            helperText="Enables or disables OIDC integration with a third-party Identity Provider (IdP)"
                            id="enableOIDC"
                            labelText="Enable OIDC"
                            onChange={(e) => {setEnableOIDC(e.target.value)}}
                          >
                            <SelectItem text="TRUE" value="true" />
                            <SelectItem text="FALSE" value="false" />
                          </Select>
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['oidcClientURL']}</span>
                          <TextInput
                            value={oidcClientURL}
                            helperText="The OIDC Client URL"
                            id="oidcclienturl"
                            labelText="Client URL"
                            onChange={(e) => setOIDCClientURL(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['oidcClientID']}</span>
                          <TextInput
                            value={oidcClientID}
                            helperText="The OIDC Client ID"
                            id="oidcclientid"
                            labelText="Client ID"
                            onChange={(e) => setOIDCClientID(e.target.value)}
                          />
                        </div>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['oidcClientSecret']}</span>
                          <TextInput.PasswordInput
                            value={oidcClientSecret}
                            helperText="The OIDC Client Secret"
                            id="oidcclientsecret"
                            labelText="Client secret"
                            onChange={(e) => setOIDCClientSecret(e.target.value)}
                          />
                        </div>
                      </div>
                    </Tab>
                    <Tab
                      id="tab-5"
                      label="Version"
                    >
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                          value={apiVersion}
                          id="apiversion"
                          labelText="API version"
                          readOnly={true}
                        />
                      </div>
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                          value={pjson.version}
                          id="uiversion"
                          labelText="UI version"
                          readOnly={true}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    Submit
                  </Button>
                  <Button
                    kind="secondary"
                    onClick={() => (window.location.href = '/admin/settings/')}
                  >
                    Reset
                  </Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-6"></div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Settings update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

/**
 * Language information sourced from https://datahub.io/core/language-codes
 */

// Import core modules
import React from 'react';

// Import elements
import {
  SelectItem
} from 'carbon-components-react';



/**
 * Core function
 */
export default function SnipPreferredLanguage() {
  return (
    <>
      <SelectItem text="N/A" value="" />
      <SelectItem text="Abkhazian" value="ab" />
      <SelectItem text="Afar" value="aa" />
      <SelectItem text="Afrikaans" value="af" />
      <SelectItem text="Akan" value="ak" />
      <SelectItem text="Albanian" value="sq" />
      <SelectItem text="Amharic" value="am" />
      <SelectItem text="Arabic" value="ar" />
      <SelectItem text="Aragonese" value="an" />
      <SelectItem text="Armenian" value="hy" />
      <SelectItem text="Assamese" value="as" />
      <SelectItem text="Avaric" value="av" />
      <SelectItem text="Avestan" value="ae" />
      <SelectItem text="Aymara" value="ay" />
      <SelectItem text="Azerbaijani" value="az" />
      <SelectItem text="Bambara" value="bm" />
      <SelectItem text="Bashkir" value="ba" />
      <SelectItem text="Basque" value="eu" />
      <SelectItem text="Belarusian" value="be" />
      <SelectItem text="Bengali" value="bn" />
      <SelectItem text="Bihari languages" value="bh" />
      <SelectItem text="Bislama" value="bi" />
      <SelectItem text="BokmÃ¥l, Norwegian; Norwegian BokmÃ¥l" value="nb" />
      <SelectItem text="Bosnian" value="bs" />
      <SelectItem text="Breton" value="br" />
      <SelectItem text="Bulgarian" value="bg" />
      <SelectItem text="Burmese" value="my" />
      <SelectItem text="Catalan; Valencian" value="ca" />
      <SelectItem text="Central Khmer" value="km" />
      <SelectItem text="Chamorro" value="ch" />
      <SelectItem text="Chechen" value="ce" />
      <SelectItem text="Chichewa; Chewa; Nyanja" value="ny" />
      <SelectItem text="Chinese" value="zh" />
      <SelectItem text="Church Slavic; Old Slavonic; Church Slavonic; Old Bulgarian; Old Church Slavonic" value="cu" />
      <SelectItem text="Chuvash" value="cv" />
      <SelectItem text="Cornish" value="kw" />
      <SelectItem text="Corsican" value="co" />
      <SelectItem text="Cree" value="cr" />
      <SelectItem text="Croatian" value="hr" />
      <SelectItem text="Czech" value="cs" />
      <SelectItem text="Danish" value="da" />
      <SelectItem text="Divehi; Dhivehi; Maldivian" value="dv" />
      <SelectItem text="Dutch; Flemish" value="nl" />
      <SelectItem text="Dzongkha" value="dz" />
      <SelectItem text="English" value="en" />
      <SelectItem text="Esperanto" value="eo" />
      <SelectItem text="Estonian" value="et" />
      <SelectItem text="Ewe" value="ee" />
      <SelectItem text="Faroese" value="fo" />
      <SelectItem text="Fijian" value="fj" />
      <SelectItem text="Finnish" value="fi" />
      <SelectItem text="French" value="fr" />
      <SelectItem text="Fulah" value="ff" />
      <SelectItem text="Gaelic; Scottish Gaelic" value="gd" />
      <SelectItem text="Galician" value="gl" />
      <SelectItem text="Ganda" value="lg" />
      <SelectItem text="Georgian" value="ka" />
      <SelectItem text="German" value="de" />
      <SelectItem text="Greek, Modern (1453-)" value="el" />
      <SelectItem text="Guarani" value="gn" />
      <SelectItem text="Gujarati" value="gu" />
      <SelectItem text="Haitian; Haitian Creole" value="ht" />
      <SelectItem text="Hausa" value="ha" />
      <SelectItem text="Herero" value="hz" />
      <SelectItem text="Hindi" value="hi" />
      <SelectItem text="Hiri Motu" value="ho" />
      <SelectItem text="Hungarian" value="hu" />
      <SelectItem text="Icelandic" value="is" />
      <SelectItem text="Ido" value="io" />
      <SelectItem text="Igbo" value="ig" />
      <SelectItem text="Indonesian" value="id" />
      <SelectItem text="Interlingua (International Auxiliary Language Association)" value="ia" />
      <SelectItem text="Interlingue; Occidental" value="ie" />
      <SelectItem text="Inuktitut" value="iu" />
      <SelectItem text="Inupiaq" value="ik" />
      <SelectItem text="Irish" value="ga" />
      <SelectItem text="Italian" value="it" />
      <SelectItem text="Japanese" value="ja" />
      <SelectItem text="Javanese" value="jv" />
      <SelectItem text="Kalaallisut; Greenlandic" value="kl" />
      <SelectItem text="Kannada" value="kn" />
      <SelectItem text="Kanuri" value="kr" />
      <SelectItem text="Kashmiri" value="ks" />
      <SelectItem text="Kazakh" value="kk" />
      <SelectItem text="Kikuyu; Gikuyu" value="ki" />
      <SelectItem text="Kinyarwanda" value="rw" />
      <SelectItem text="Kirghiz; Kyrgyz" value="ky" />
      <SelectItem text="Komi" value="kv" />
      <SelectItem text="Kongo" value="kg" />
      <SelectItem text="Korean" value="ko" />
      <SelectItem text="Kuanyama; Kwanyama" value="kj" />
      <SelectItem text="Kurdish" value="ku" />
      <SelectItem text="Lao" value="lo" />
      <SelectItem text="Latin" value="la" />
      <SelectItem text="Latvian" value="lv" />
      <SelectItem text="Limburgan; Limburger; Limburgish" value="li" />
      <SelectItem text="Lingala" value="ln" />
      <SelectItem text="Lithuanian" value="lt" />
      <SelectItem text="Luba-Katanga" value="lu" />
      <SelectItem text="Luxembourgish; Letzeburgesch" value="lb" />
      <SelectItem text="Macedonian" value="mk" />
      <SelectItem text="Malagasy" value="mg" />
      <SelectItem text="Malay" value="ms" />
      <SelectItem text="Malayalam" value="ml" />
      <SelectItem text="Maltese" value="mt" />
      <SelectItem text="Manx" value="gv" />
      <SelectItem text="Maori" value="mi" />
      <SelectItem text="Marathi" value="mr" />
      <SelectItem text="Marshallese" value="mh" />
      <SelectItem text="Mongolian" value="mn" />
      <SelectItem text="Nauru" value="na" />
      <SelectItem text="Navajo; Navaho" value="nv" />
      <SelectItem text="Ndebele, North; North Ndebele" value="nd" />
      <SelectItem text="Ndebele, South; South Ndebele" value="nr" />
      <SelectItem text="Ndonga" value="ng" />
      <SelectItem text="Nepali" value="ne" />
      <SelectItem text="Northern Sami" value="se" />
      <SelectItem text="Norwegian" value="no" />
      <SelectItem text="Norwegian Nynorsk; Nynorsk, Norwegian" value="nn" />
      <SelectItem text="Occitan (post 1500)" value="oc" />
      <SelectItem text="Ojibwa" value="oj" />
      <SelectItem text="Oriya" value="or" />
      <SelectItem text="Oromo" value="om" />
      <SelectItem text="Ossetian; Ossetic" value="os" />
      <SelectItem text="Pali" value="pi" />
      <SelectItem text="Panjabi; Punjabi" value="pa" />
      <SelectItem text="Persian" value="fa" />
      <SelectItem text="Polish" value="pl" />
      <SelectItem text="Portuguese" value="pt" />
      <SelectItem text="Pushto; Pashto" value="ps" />
      <SelectItem text="Quechua" value="qu" />
      <SelectItem text="Romanian; Moldavian; Moldovan" value="ro" />
      <SelectItem text="Romansh" value="rm" />
      <SelectItem text="Rundi" value="rn" />
      <SelectItem text="Russian" value="ru" />
      <SelectItem text="Samoan" value="sm" />
      <SelectItem text="Sango" value="sg" />
      <SelectItem text="Sanskrit" value="sa" />
      <SelectItem text="Sardinian" value="sc" />
      <SelectItem text="Serbian" value="sr" />
      <SelectItem text="Shona" value="sn" />
      <SelectItem text="Sichuan Yi; Nuosu" value="ii" />
      <SelectItem text="Sindhi" value="sd" />
      <SelectItem text="Sinhala; Sinhalese" value="si" />
      <SelectItem text="Slovak" value="sk" />
      <SelectItem text="Slovenian" value="sl" />
      <SelectItem text="Somali" value="so" />
      <SelectItem text="Sotho, Southern" value="st" />
      <SelectItem text="Spanish; Castilian" value="es" />
      <SelectItem text="Sundanese" value="su" />
      <SelectItem text="Swahili" value="sw" />
      <SelectItem text="Swati" value="ss" />
      <SelectItem text="Swedish" value="sv" />
      <SelectItem text="Tagalog" value="tl" />
      <SelectItem text="Tahitian" value="ty" />
      <SelectItem text="Tajik" value="tg" />
      <SelectItem text="Tamil" value="ta" />
      <SelectItem text="Tatar" value="tt" />
      <SelectItem text="Telugu" value="te" />
      <SelectItem text="Thai" value="th" />
      <SelectItem text="Tibetan" value="bo" />
      <SelectItem text="Tigrinya" value="ti" />
      <SelectItem text="Tonga (Tonga Islands)" value="to" />
      <SelectItem text="Tsonga" value="ts" />
      <SelectItem text="Tswana" value="tn" />
      <SelectItem text="Turkish" value="tr" />
      <SelectItem text="Turkmen" value="tk" />
      <SelectItem text="Twi" value="tw" />
      <SelectItem text="Uighur; Uyghur" value="ug" />
      <SelectItem text="Ukrainian" value="uk" />
      <SelectItem text="Urdu" value="ur" />
      <SelectItem text="Uzbek" value="uz" />
      <SelectItem text="Venda" value="ve" />
      <SelectItem text="Vietnamese" value="vi" />
      <SelectItem text="VolapÃ¼k" value="vo" />
      <SelectItem text="Walloon" value="wa" />
      <SelectItem text="Welsh" value="cy" />
      <SelectItem text="Western Frisian" value="fy" />
      <SelectItem text="Wolof" value="wo" />
      <SelectItem text="Xhosa" value="xh" />
      <SelectItem text="Yiddish" value="yi" />
      <SelectItem text="Yoruba" value="yo" />
      <SelectItem text="Zhuang; Chuang" value="za" />
      <SelectItem text="Zulu" value="zu" />
    </>
  );
}

import { OidcSecure, type OidcUserInfo, OidcUserStatus, useOidcAccessToken, useOidcIdToken, useOidcUser } from '@axa-fr/react-oidc';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loading } from 'carbon-components-react';

import { useAppContext } from '../../lib/context';

const apiuri = process.env.REACT_APP_APIURI;

interface OidcUserRoleInfo extends OidcUserInfo{
  role?: string[];
}

export default function Profile() {
  const { oidcUser, oidcUserLoadingState, reloadOidcUser } = useOidcUser<OidcUserRoleInfo>();
  const { idToken, idTokenPayload } = useOidcIdToken();
  const { isAuthenticated, setIsAuthenticated } = useAppContext();

  var payLoad = {
    idToken: idTokenPayload,
  }

  function oidcLogin() {
    axios.post(apiuri + '/auth/oidc', payLoad)
      .then((response) => {
        if (response.status === 201) {
          setIsAuthenticated(true);
          localStorage.setItem('isAuthenticated', "true");
          localStorage.setItem('accessToken', response.data.accessToken);
          window.location.assign('/users');
        }
      });
  };

  useEffect(() => {
    oidcLogin();
  }, [oidcUser]);

  return (
    <div className="container mt-3">
      <Loading active={true} withOverlay={true} />
    </div>
  );
};
// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  DataTable,
  Form,TextInput,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tag,
  Tile
} from 'carbon-components-react';
import { Add16 } from '@carbon/icons-react';
import { check } from 'prettier';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;
const headers = [
  { key: 'type', header: 'Type' },
  { key: 'name', header: 'Name' },
  { key: 'value', header: 'Value' },
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
} = DataTable;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};




/**
 * Core function
 */
export default function NvpPage({ history, match }) {
  const { id } = match.params;

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  
  var jwt = decodeJWT(localStorage.getItem('accessToken'));

  var breadcrumb = "Name-value pairs";
  
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [start, setStart] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [prevItem, setPrevItem] = useState();
  const [nextItem, setNextItem] = useState();
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [direction, setDirection] = useState();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [delId, setDelId] = useState('');
  const [delModalOpen, setDelModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();



  function UMTComponent(props) {  
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem itemText="View/Edit" onClick={() => { window.location.href = '/admin/nvp/edit/' + props.row }} />
        <OverflowMenuItem itemText="Delete" isDelete  onClick={() => { checkDelete(props.row)}} />
      </OverflowMenu>
    )
  }
  


  /**
   * Load data
   */
  function loadData() {
    var apiCall = apiuri + '/namevaluepair/all?prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
    var encodedApiCall = encodeURI(apiCall);
      axios
        .get(encodedApiCall, { headers: httpHeaders })
        .then((response) => {
          setPrevItem(response.data.prevItem);
          setNextItem(response.data.nextItem);
          setPrevPage(page);

          var payLoad = [];

          if (response.data.items) {
            response.data.items.forEach((element) => {
              var newElement = {};
              newElement._id = element._id;
              newElement.id = element._id;
              newElement.name = element.name;
              newElement.value = element.value;
              newElement.type = element.type;
              payLoad.push(newElement);
            });

            setTotalItems(response.data.count);
          } else {
            setTotalItems(0);
          }
          setRows(payLoad);
          setAllRows(payLoad);
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the list of name-value pairs.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the list of name-value pairs: ' + error.response.data.message);
              }
            }
          }
          setModalType("danger");
          setModalOpen(true);
        });
  }



  /**
   * Handle delete
   */
  function checkDelete(id) {
    setDelId(id);
    setDelModalOpen(true);
  }

  function handleDelete(id) {
    setDelModalOpen(false);
    axios.delete(apiuri + '/namevaluepair/' + id, { headers: httpHeaders, })
    .then((response) => {
      loadData();
    })
    .catch((error) => {
      setModalMessage('An error has occurred deleting the name-value pair.');
      if (error.response) {
        if (error.response.status === 401) {
          reauth();
        }
        if (error.response.status === 403) {
          setModalMessage('You are not permitted to perform that operation');
        } else {
          if (error.response.data.message) {
            setModalMessage('An error has occurred deleting the name-value pair: ' + error.response.data.message);
          }
        }
      }
      setModalType('danger');
      setModalOpen(true);
    });
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, page]);




  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/nvp">Administration</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">{breadcrumb}</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <Tile>
                <h3 className="umt--tile-header">Name-value pairs</h3>
                <p className="umt--tile-value">{totalItems}</p>
              </Tile>
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows}
                headers={headers}
                isSortable={false}
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getSelectionProps,
                  getToolbarProps,
                  getBatchActionProps,
                  onInputChange,
                  selectedRows,
                  getTableContainerProps,
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <Button
                          size="small"
                          kind="primary"
                          renderIcon={Add16}
                          href="/admin/nvp/add"
                        >
                          Add pair
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                              <UMTComponent
                                row={row.id}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      totalItems={totalItems}
                      backwardText="Previous page"
                      forwardText="Next page"
                      page={Math.floor(start / currentPageSize) + 1}
                      pageSize={currentPageSize}
                      pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                      itemsPerPageText="Items per page"
                      pageInputDisabled={true}
                      onChange={({ page, pageSize }) => {
                        if (totalItems === 0 || start < totalItems) {
                          // we should be grand
                        } else {
                          setStart(Math.max(start - (Math.floor((page - totalItems / pageSize) + 1) * pageSize), 0));
                        }
                        if (pageSize !== currentPageSize) {
                          setCurrentPageSize(pageSize);
                          setDirection(undefined);
                        }
                        setFirstRowIndex(pageSize * (page - 1));
                        setPage(page);

                        if (page > prevPage) {
                          setDirection('up');
                        } else if (page < prevPage) {
                          setDirection('down');
                        }
                      }}
                    />

                  </TableContainer>
                )}
              />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">User management</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
      {delModalOpen ? (
        <ComposedModal open={delModalOpen} onClose={() => setDelModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Confirmation</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">
              Are you sure you want to delete this name-value pair?
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonSet>
              <Button kind="danger" onClick={() => { handleDelete(delId) }}>
                Continue
              </Button>
              <Button kind="secondary" onClick={() => { setDelModalOpen(false); }}>
                Cancel
              </Button>
            </ButtonSet>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React from 'react';

// Import elements
import {
  SideNav,
  SideNavItems,
  SideNavLink,
  SwitcherDivider,
  HeaderContainer,
} from 'carbon-components-react';

/**
 * Core function
 */
export default function AdminNav() {
  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  
  var showSchema = false;
  var showAudit = false;
  var showLCR = false;
  var showMailTemplate = false;
  var showNVP = false;
  var showRole = false;
  var showSettings = false;
  var showThirdParty = false;
  var showUserType = false;
  
  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  if (jwt.permissions) {
    if(jwt.permissions.includes('attribute_r') || jwt.permissions.includes('attribute_w')) {
      showSchema = true;
    }
    if (jwt.permissions.includes('audit_r')) {
      showAudit = true;
    }
    if (jwt.permissions.includes('lcr_r') || jwt.permissions.includes('lcr_w')) {
      showLCR = true;
    }
    if (jwt.permissions.includes('mailtemplate_r') || jwt.permissions.includes('mailtemplate_w')) {
      showMailTemplate = true;
    }
    if (jwt.permissions.includes('namevaluepair_r') || jwt.permissions.includes('namevaluepair_w')) {
      showNVP = true;
    }
    if (jwt.permissions.includes('role_r') || jwt.permissions.includes('role_w')) {
      showRole = true;
    }
    if (jwt.permissions.includes('settings_r') ||  jwt.permissions.includes('settings_w')) {
      showSettings = true;
    }
    if (jwt.permissions.includes('thirdparty_r') || jwt.permissions.includes('thirdparty_w')) {
      showThirdParty = true;
    }
    if (jwt.permissions.includes('usertype_r') || jwt.permissions.includes('usertype_w')) {
      showUserType = true;
    }
  }

  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="Org navigation"
              className="umt--sidenav"
            >
              <SideNavItems className="umt--sidenavitems">
                <SideNavLink className="umt--sidenavlink" href="/admin">
                  Dashboard
                </SideNavLink>

                {showSettings
                ?
                  <>
                    <SwitcherDivider />
                    <SideNavLink className="umt--sidenavlink" href="/admin/settings">
                      Settings
                    </SideNavLink>
                  </>
                :
                  null
                }

                <SwitcherDivider />

                {showThirdParty
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/thirdparty">
                      Third parties
                    </SideNavLink>
                  </>
                :
                  null
                }
                {showUserType
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/usertype">
                      User types
                    </SideNavLink>
                  </>
                :
                  null
                }
                {showRole
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/role">
                      Roles
                    </SideNavLink>
                  </>
                :
                  null
                }
                {showLCR
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/lcr">
                      Lifecycle rules
                    </SideNavLink>
                  </>
                :
                  null
                }
                {showMailTemplate
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/mailtemplate">
                      Mail templates
                    </SideNavLink>
                  </>
                :
                  null
                }
                {showNVP
                ?
                <>
                  <SideNavLink className="umt--sidenavlink" href="/admin/nvp">
                    Name-value pairs
                  </SideNavLink>
                </>
                :
                null
                }
                {showSchema
                ?
                  <>
                    <SideNavLink className="umt--sidenavlink" href="/admin/schema">
                      Schema
                    </SideNavLink>
                  </>
                :
                  null
                }

                {showAudit
                ?
                  <>
                    <SwitcherDivider />
                    <SideNavLink className="umt--sidenavlink" href="/admin/audit">
                      Audit log
                    </SideNavLink>
                  </>
                :
                  null
                }
              </SideNavItems>
            </SideNav>
          </>
        )}
      />
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';
import SnipCountry from './components/SnipCountry';
import SnipLocale from './components/SnipLocale';
import SnipPreferredLanguage from './components/SnipPreferredLanguage';
import SnipTimezone from './components/SnipTimezone';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  DataTable,
  DatePicker,
  DatePickerInput,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  Select,
  SelectItem,
  Tag,
  TextArea,
  TextInput
} from 'carbon-components-react';

import { Add16, Search16, TrashCan16 } from '@carbon/icons-react';



// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

const headers = [
  { key: 'purchaseOrder', header: 'PO #' },
  { key: 'startDate', header: 'Start date' },
  { key: 'endDate', header: 'End date' },
  { key: 'state', header: 'State'}
];

const roleHeaders = [
  { key: 'name', header: 'Role name' }
];

const roleAllHeaders = [
  { key: 'name', header: 'Role name' },
  { key: 'description', header: 'Role description' }
];

const mgrHeaders = [
  { key: 'displayName', header: 'Manager name' },
  { key: 'email', header: 'Email address' },
  { key: 'department', header: 'Department' }
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent
} = DataTable;

function UMTComponent(props) {
  if (typeof props.attr === "undefined") {
    return null;
  }

  var attr = props.attr;
  if (attr.enabled === false && attr.protected === false) {
    return null;
  }

  var name = attr.name;
  var label = attr.labelen;
  var type = attr.type;

  var value = props.value;
  var func = props.func;
  var errorText = props.errorText;
  var element = '';

  if (type === "Boolean") {
    element = <Select
                value={value}
                key={name}
                id={name}
                labelText={label}
                onChange={func}
              >
                <SelectItem text="True" value="true" />
                <SelectItem text="False" value="false" />
              </Select>
  }
  if (type === "Select") {
    var validValues = attr.validValues.split('\n');
    element = <Select
                value={value}
                key={name}
                id={name}
                labelText={label}
                onChange={func}
              >
                {validValues.map((element) => (
                  <SelectItem text={element} value={element} />
                 ))}
              </Select>
  }
  if (type === "Long Text") {
    element = <TextArea
                value={value}
                cols={50}
                key={name}
                id={name}
                labelText={label}
                rows={4}
                onChange={func}
              />
  }
  if (type === "String") {
    element = <TextInput
                key={name}
                value={value}
                id={name}
                type="text"
                labelText={label}
                onChange={func}
              />
  }

  return (
    <div style={{ marginBottom: '2rem' }}>
      <span style={{ color: 'red' }}>{errorText}</span>
      {element}
    </div>
  )
}


/**
 * Core function
 */
export default function User({ history, match }) {
  const { id } = match.params;

  const isAddMode = !id;

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  var jwt = decodeJWT(localStorage.getItem('accessToken'));

  var isBulkMode;
  if (!id && history.location.pathname === '/users/add/bulk') {
    isBulkMode = true;

    // If not authorised, send them back to /users
    if (!jwt.permissions.includes('bulk_w')) {
      window.location.href = '/users';
    }
  } else {
    isBulkMode = false;
  }

  const [rows, setRows] = useState([]);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  const [roleRows, setRoleRows] = useState([]);
  const [roleFirstRowIndex, setRoleFirstRowIndex] = useState(0);
  const [roleCurrentPageSize, setRoleCurrentPageSize] = useState(5);
  const [roleTotalItems, setRoleTotalItems] = useState(0);

  const [roleAllRows, setRoleAllRows] = useState([]);
  const [roleAllFirstRowIndex, setRoleAllFirstRowIndex] = useState(0);
  const [roleAllCurrentPageSize, setRoleAllCurrentPageSize] = useState(10);
  const [roleAllTotalItems, setRoleAllTotalItems] = useState(0);

  const [mgrRows, setMgrRows] = useState([]);
  const [mgrFirstRowIndex, setMgrFirstRowIndex] = useState(0);
  const [mgrCurrentPageSize, setMgrCurrentPageSize] = useState(5);
  const [mgrTotalItems, setMgrTotalItems] = useState(0);
  const [managerSearch, setManagerSearch] = useState('');

  const [userTypes, setUserTypes] = useState([]);
  const [fteList, setFTEList] = useState([]);
  const [managedList, setManagedList] = useState([]);
  const [thirdParties, setThirdParties] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [validValues, setValidValues] = useState([]);
  const [labels, setLabels] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');

  const [modalAppOpen, setModalAppOpen] = useState(false);
  const [modalAppMessage, setModalAppMessage] = useState();

  const [modalMgrOpen, setModalMgrOpen] = useState(false);
  const [modalMgrMessage, setModalMgrMessage] = useState();

  const [modalRoleOpen, setModalRoleOpen] = useState(false);
  const [modalRoleMessage, setModalRoleMessage] = useState();

  // Number of users to be bulk loaded, if applicable
  const [bulkNumber, setBulkNumber] = useState('');

  // Individual schema attributes
  const [externalId, setExternalId] = useState('');
  const [userName, setUserName] = useState('');
  const [formatted, setFormatted] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [honorificPrefix, setHonorificPrefix] = useState('');
  const [honorificSuffix, setHonorificSuffix] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [nickName, setNickName] = useState('');
  const [profileUrl, setProfileUrl] = useState('');

  const [emailAddress, setEmailAddress] = useState('');

  const [streetAddress, setStreetAddress] = useState('');
  const [locality, setLocality] = useState('');
  const [region, setRegion] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [addressFormatted, setAddressFormatted] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');

//  const [photoValue, setPhotoValue] = useState('');
//  const [photoType, setPhotoType] = useState('');

  const [userType, setUserType] = useState('');
  const [utModalOpen, setUtModalOpen] = useState(false);
  const [newUserType, setNewUserType] = useState('');
  const [fte, setFTE] = useState(false);
  const [managed, setManaged] = useState(false);
  const [title, setTitle] = useState('');

  const [preferredLanguage, setPreferredLanguage] = useState('');
  const [locale, setLocale] = useState('');
  const [timezone, setTimezone] = useState('');

  const [x509Certificate, setX509Certificate] = useState('');

  const [employeeNumber, setEmployeeNumber] = useState('');
  const [costCenter, setCostCenter] = useState('');
  const [organization, setOrganization] = useState('');
  const [division, setDivision] = useState('');
  const [department, setDepartment] = useState('');
  const [manager, setManager] = useState('');
  const [managerId, setManagerId] = useState('');
  const [managerName, setManagerName] = useState('');

  const [umtThirdParty, setUMTThirdParty] = useState('');
  const [umtStartDate, setUMTStartDate] = useState('');
  const [umtEndDate, setUMTEndDate] = useState('');
  const [umtReviewDate, setUMTReviewDate] = useState('');
  const [umtPurchaseOrder, setUMTPurchaseOrder] = useState('');
  const [umtContractStartDate, setUMTContractStartDate] = useState('');
  const [umtContractEndDate, setUMTContractEndDate] = useState('');
  const [umtDomain, setUMTDomain] = useState('');
  const [umtNotes, setUMTNotes] = useState('');

  const [customAttribute01, setCustomAttribute01] = useState('');
  const [customAttribute02, setCustomAttribute02] = useState('');
  const [customAttribute03, setCustomAttribute03] = useState('');
  const [customAttribute04, setCustomAttribute04] = useState('');
  const [customAttribute05, setCustomAttribute05] = useState('');
  const [customAttribute06, setCustomAttribute06] = useState('');
  const [customAttribute07, setCustomAttribute07] = useState('');
  const [customAttribute08, setCustomAttribute08] = useState('');
  const [customAttribute09, setCustomAttribute09] = useState('');
  const [customAttribute10, setCustomAttribute10] = useState('');
  const [customAttribute11, setCustomAttribute11] = useState('');
  const [customAttribute12, setCustomAttribute12] = useState('');
  const [customAttribute13, setCustomAttribute13] = useState('');
  const [customAttribute14, setCustomAttribute14] = useState('');
  const [customAttribute15, setCustomAttribute15] = useState('');
  const [customAttribute16, setCustomAttribute16] = useState('');
  const [customAttribute17, setCustomAttribute17] = useState('');
  const [customAttribute18, setCustomAttribute18] = useState('');
  const [customAttribute19, setCustomAttribute19] = useState('');
  const [customAttribute20, setCustomAttribute20] = useState('');

  const dOptions = { year: 'numeric', month: 'short', day: 'numeric' };

  const {
    handleSubmit
  } = useForm({});



  var formIsValid = true;
  var errorList = [];

  

  /**
   * Configure functions for handling form submissions
   */
  function onSubmit(data) {
    if (validateForm()) {
      if (isBulkMode) {
        return bulkAdd();
      } else if (isAddMode) {
        return createUser();
      } else {
        return updateUser();
      }
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }



  /**
   * Validators
   */
  function validateText(attrName, attrValue, mandatory, maxSize) {
    if (mandatory) {
      if (attrValue === null || attrValue === '') {
        errorList[attrName] = 'This is a mandatory attribute';
        formIsValid = false;
      }
    }

    if (attrValue.length > maxSize) {
      errorList[attrName] = 'Value cannot be greater than ' + maxSize + ' characters in length';
      formIsValid = false;
    }

    if (attrName === "bulkNumber") {
      let bulkNo = parseInt(attrValue);
      if (bulkNo > 99 || bulkNo < 1 || isNaN(bulkNo)) {
        errorList[attrName] = 'Number of users must be an integer between 1 and 99';
        formIsValid = false;
      }
    }
  }

  function validateEmail(attrName, attrValue) {
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (attrValue === '') {
      // don't validate
    } else if (!regexEmail.test(attrValue)) {
      errorList[attrName] = 'Invalid email address format';
      formIsValid = false;
    }
  }

  /**
   * Validate data input
   */
  function validateForm() {
    errorList = [];
    formIsValid = true;

    if (isBulkMode) {
      validateText('bulkNumber', bulkNumber, true, 64);
    }

    validateText('userType', userType, true, 256);
    validateText('externalId', externalId, false, 64);
    validateText('userName', userName, false, 64);
    // Validate formatted
    validateText('familyName', familyName, true, 64);
    validateText('givenName', givenName, true, 64);
    validateText('middleName', middleName, false, 64);
    validateText('honorificPrefix', honorificPrefix, false, 64);
    validateText('honorificSuffix', honorificSuffix, false, 64);
    validateText('displayName', displayName, false, 256);
    validateText('nickName', nickName, false, 64);
    validateText('profileUrl', profileUrl, false, 256);
    validateText('emailAddress', emailAddress, false, 64);
    validateEmail('emailAddress', emailAddress);
    validateText('streetAddress', streetAddress, false, 64);
    validateText('locality', locality, false, 64);
    validateText('region', region, false, 64);
    validateText('postalCode', postalCode, false, 64);
    // Validate country - N/A - dropdown
    validateText('addressFormatted', addressFormatted, false, 256);
    validateText('phoneNumber', phoneNumber, false, 64);
    // Validate photoValue
    // Validate photoType
    validateText('title', title, false, 64);
    // Validate preferredLanguage - N/A - dropdown
    // Validate locale - N/A - dropdown
    // Validate timezone - N/A - dropdown
    validateText('x509Certificate', x509Certificate, false, 1024);
    validateText('employeeNumber', employeeNumber, false, 32);
    validateText('costCenter', costCenter, false, 32);
    validateText('organization', organization, false, 64);
    validateText('division', division, false, 64);
    validateText('department', department, false, 64);
    // Validate managerValue
    // Validate managerDisplayName
    validateText('umtThirdParty', umtThirdParty, true, 256);
    // Validate start/end dates
    if (umtStartDate && umtEndDate) {
      var sd = new Date(umtStartDate);
      var ed = new Date(umtEndDate);
      if (ed < sd) {
        errorList['umtEndDate'] = 'End date cannot be before the start date';
        formIsValid = false;
      }
    }
    validateText('umtDomain', umtDomain, false, 32);
    validateText('umtNotes', umtNotes, false, 1024);

    validateText('purchaseOrder', umtPurchaseOrder, false, 32);
    // Validate contract dates
    if (umtContractStartDate && umtContractEndDate) {
      var csd = new Date(umtContractStartDate);
      var ced = new Date(umtContractEndDate);
      if (ced < csd) {
        errorList['umtContractEndDate'] = 'Contract end date cannot be before the start date';
        formIsValid = false;
      }
    }

    validateText('customAttribute01', customAttribute01, false, 128);
    validateText('customAttribute02', customAttribute02, false, 128);
    validateText('customAttribute03', customAttribute03, false, 128);
    validateText('customAttribute04', customAttribute04, false, 128);
    validateText('customAttribute05', customAttribute05, false, 128);
    validateText('customAttribute06', customAttribute06, false, 128);
    validateText('customAttribute07', customAttribute07, false, 128);
    validateText('customAttribute08', customAttribute08, false, 128);
    validateText('customAttribute09', customAttribute09, false, 128);
    validateText('customAttribute10', customAttribute10, false, 128);
    validateText('customAttribute11', customAttribute11, false, 128);
    validateText('customAttribute12', customAttribute12, false, 128);
    validateText('customAttribute13', customAttribute13, false, 128);
    validateText('customAttribute14', customAttribute14, false, 128);
    validateText('customAttribute15', customAttribute15, false, 128);
    validateText('customAttribute16', customAttribute16, false, 128);
    validateText('customAttribute17', customAttribute17, false, 128);
    validateText('customAttribute18', customAttribute18, false, 128);
    validateText('customAttribute19', customAttribute19, false, 128);
    validateText('customAttribute20', customAttribute20, false, 128);

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }



  /**
   * 
   * Build payload for submission
   */
  function buildPayload() {
    var payLoad = {};
    if (externalId !== '') { payLoad.externalId = externalId };
    if (userName !== '') { payLoad.userName = userName; }
    
    var name = {};
    if (formatted !== '') { name.formatted = formatted; }
    if (familyName !== '') { name.familyName = familyName; }
    if (givenName !== '') { name.givenName = givenName; }
    if (middleName !== '') { name.middleName = middleName; }
    if (honorificPrefix !== '') { name.honorificPrefix = honorificPrefix; }
    if (honorificSuffix !== '') { name.honorificSuffix = honorificSuffix; }
    payLoad.name = name;

    if (displayName !== '') {
      payLoad.displayName = displayName;
    } else {
      payLoad.displayName = givenName + ' ' + familyName;
    }
    if (nickName !== '') { payLoad.nickName = nickName; }
    if (profileUrl !== '') { payLoad.profileUrl = profileUrl; }
    if (title !== '') { payLoad.title = title; }
    if (userType !== '') { payLoad.userType = userType; }
    if (preferredLanguage !== '') { payLoad.preferredLanguage = preferredLanguage; }
    if (locale !== '') { payLoad.locale = locale; }
    if (timezone !== '') { payLoad.timezone = timezone; }

    if (emailAddress !== '') {
      var emailAddresses = [];
      var mailObject = {};
      mailObject.value = emailAddress;
      mailObject.type = "Work";
      mailObject.primary = true;
      emailAddresses[0] = mailObject;
      payLoad.emails = emailAddresses;
    }

    if (phoneNumber !== '') {
      var phoneNumbers = [];
      var phoneObject = {};
      phoneObject.value = phoneNumber;
      phoneObject.type = "Work";
      phoneNumbers[0] = phoneObject;
      payLoad.phoneNumbers = phoneNumbers;
    }

    var addresses = [];
    var address = {};
    if (streetAddress !== '') { address.streetAddress = streetAddress; address.type = "Work"; address.primary = true; }
    if (locality !== '') { address.locality = locality; address.type = "Work"; address.primary = true; }
    if (region !== '') { address.region = region; address.type = "Work"; address.primary = true; }
    if (postalCode !== '') { address.postalCode = postalCode; address.type = "Work"; address.primary = true; }
    if (country !== '') { address.country = country; address.type = "Work"; address.primary = true; }
    if (addressFormatted !== '') { address.formatted = addressFormatted; address.type = "Work"; address.primary = true; }
    addresses[0] = address;
    payLoad.addresses = addresses;

    if (x509Certificate !== '') {
      var x509Array = [];
      var x509 = { value: x509Certificate };
      x509Array[0] = x509;
      payLoad.x509Certificates = x509Array;
    }

    var enterprise = {};
    if (employeeNumber !== '') { enterprise.employeeNumber = employeeNumber; }
    if (costCenter !== '') { enterprise.costCenter = costCenter; }
    if (organization !== '') { enterprise.organization = organization; }
    if (division !== '') { enterprise.division = division; }
    if (department !== '') { enterprise.department = department; }
    if (managerId !== '') {
      var entManager = {
        value: managerId,
        displayName: managerName
      }
      enterprise.manager = entManager;
    }
    payLoad.enterprise = enterprise;

    var umt = {};
    if (umtThirdParty !== '') { umt.thirdParty = umtThirdParty; }
    if (umtStartDate !== '') { umt.startDate = umtStartDate; }
    if (umtEndDate !== '') { umt.endDate = umtEndDate; }
    if (umtReviewDate !== '') { umt.reviewDate = umtReviewDate; }
    if (umtDomain !== '') { umt.domain = umtDomain; }
    if (umtNotes !== '') { umt.notes = umtNotes; }
    payLoad.umt = umt;

    var contract = {};
    if (isAddMode) {
      if (umtPurchaseOrder !== '') { contract.purchaseOrder = umtPurchaseOrder; }
      if (umtContractStartDate !== '') { contract.startDate = umtContractStartDate; }
      if (umtContractEndDate !== '') { contract.endDate = umtContractEndDate; }
    }
    payLoad.contract = contract;

    var custom = {};
    if (customAttribute01 !== '') { custom.customAttribute01 = customAttribute01; }
    if (customAttribute02 !== '') { custom.customAttribute02 = customAttribute02; }
    if (customAttribute03 !== '') { custom.customAttribute03 = customAttribute03; }
    if (customAttribute04 !== '') { custom.customAttribute04 = customAttribute04; }
    if (customAttribute05 !== '') { custom.customAttribute05 = customAttribute05; }
    if (customAttribute06 !== '') { custom.customAttribute06 = customAttribute06; }
    if (customAttribute07 !== '') { custom.customAttribute07 = customAttribute07; }
    if (customAttribute08 !== '') { custom.customAttribute08 = customAttribute08; }
    if (customAttribute09 !== '') { custom.customAttribute09 = customAttribute09; }
    if (customAttribute10 !== '') { custom.customAttribute10 = customAttribute10; }
    if (customAttribute11 !== '') { custom.customAttribute11 = customAttribute11; }
    if (customAttribute12 !== '') { custom.customAttribute12 = customAttribute12; }
    if (customAttribute13 !== '') { custom.customAttribute13 = customAttribute13; }
    if (customAttribute14 !== '') { custom.customAttribute14 = customAttribute14; }
    if (customAttribute15 !== '') { custom.customAttribute15 = customAttribute15; }
    if (customAttribute16 !== '') { custom.customAttribute16 = customAttribute16; }
    if (customAttribute17 !== '') { custom.customAttribute17 = customAttribute17; }
    if (customAttribute18 !== '') { custom.customAttribute18 = customAttribute18; }
    if (customAttribute19 !== '') { custom.customAttribute19 = customAttribute19; }
    if (customAttribute20 !== '') { custom.customAttribute20 = customAttribute20; }
    payLoad.custom = custom;
  
    return payLoad;
  }



  /**
   * Create a new user
   */
  function createUser() {
    var payLoad = buildPayload();
    axios
      .post(apiuri + '/user', payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/users') })
      .catch((error) => {
        var msg = 'An error has occurred creating the user.';
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            msg = 'You are not permitted to perform that operation';
          } else {
            if (error.response.data.message) {
              msg = 'An error has occurred creating the user: ' + error.response.data.message;
            }
            if (error.response.data.reason) {
              msg += ': ' + JSON.stringify(error.response.data.reason);
            }
          }
        }
        setModalMessage(msg);
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * Bulk add users
   */
  function bulkAdd() {
    for (let i = 0; i < bulkNumber; i++) {
      var payLoad = buildPayload();
      let newUserName = '';
      let newGivenName = '';
      let newFamilyName = '';
      let newDisplayName = '';

      let userNum;
      userNum = i + 1;

      if (userNum < 10) {
        if (payLoad.userName === undefined) {
          newUserName = null;
        } else {
          newUserName = payLoad.userName + '_0' + userNum.toString();
        }
        newGivenName = payLoad.name.givenName + '_0' + userNum.toString();
        newFamilyName = payLoad.name.familyName + '_0' + userNum.toString();
      } else {
        if (payLoad.userName === undefined) {
          newUserName = null;
        } else {
          newUserName = payLoad.userName + '_' + userNum.toString();
        }
        newGivenName = payLoad.name.givenName + '_' + userNum.toString();
        newFamilyName = payLoad.name.familyName + '_' + userNum.toString();
      }
      newDisplayName = newGivenName + ' ' + newFamilyName;

      // Now create them
      var newPayLoad = payLoad;
      newPayLoad.userName = newUserName;
      newPayLoad.name.givenName = newGivenName;
      newPayLoad.name.familyName = newFamilyName;
      newPayLoad.displayName = newDisplayName;

      axios
        .post(apiuri + '/user', newPayLoad, { headers: httpHeaders })
        .then((response) => { history.push('/users') })
        .catch((error) => {
          var msg = 'An error has occurred creating the user.';
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              msg = 'You are not permitted to perform that operation';
            } else {
              if (error.response.data.message) {
                msg = 'An error has occurred creating the user: ' + error.response.data.message;
              }
              if (error.response.data.reason) {
                msg += ': ' + JSON.stringify(error.response.data.reason);
              }
            }
          }
          setModalMessage(msg);
          setModalType('danger');
          setModalOpen(true);
        });
    }
  }



  /**
   * Update user
   */
  function updateUser() {
    var payLoad = buildPayload();
    axios
      .put(apiuri + '/user/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/users'); })
      .catch((error) => {
        setModalMessage('An error has occurred modifying the user.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred updating the user: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * handleNewContract
   */
  function handleNewContract() {
    let errorList = [];
    let formIsValid = true;

    if (umtContractStartDate && umtContractEndDate) {
      var sd = new Date(umtContractStartDate);
      var ed = new Date(umtContractEndDate);
      if (ed < sd) {
        errorList['umt.contractEndDate'] = 'Contract end date cannot be before the start date';
        formIsValid = false;
      }
    }

    if (!formIsValid) {
      setFormErrors(errorList);
    } else {
      var payLoad = {
        user: id,
        purchaseOrder: umtPurchaseOrder,
        startDate: umtContractStartDate,
        endDate: umtContractEndDate
      };

      axios.post(apiuri + '/contract/', payLoad, { headers: httpHeaders })
        .then((response) => {
          window.location.href = '/users/edit/' + id;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) { reauth(); }
            if (error.response.status === 403) {
              setModalAppMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalAppMessage('An error has occurred: ' + error.response.data.message);
              }
            }
          }
        });
    }
  }



  /**
   * handleNewRole
   */
  function addRole(roleId) {
    var payLoad = {
      "schemas": ['urn:ietf:params:scim:api:messages:2.0:PatchOp'],
      "Operations": [
          {
            "op": "add",
            "path": "members",
            "value": id
          }
      ]
    }

    axios
      .patch(apiuri + '/role/' + roleId, payLoad, { headers: httpHeaders })
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setModalMessage('An error has occurred adding a user to the role.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred adding the user to the role: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * Search for a manager
   */
  function searchForManager() {
    axios.get(apiuri + '/user/findByName?managerSearch=true&name=' + managerSearch, { headers: httpHeaders })
      .then((response) => {
        var payLoad = [];
        response.data.forEach((element) => {
          var userObject = {}
          userObject.id = element._id;
          if (element.displayName) {
            userObject.displayName = element.displayName;
          } else {
            userObject.displayName = element.name.givenName + " " + element.name.familyName;
          }
          if (element.emails) {
            if (element.emails.length > 0) {
              if (element.emails[0].value) { userObject.email = element.emails[0].value; }
            }
          }
          if (element.enterprise) {
            if (element.department) { userObject.department = element.enterprise.department; }
          }
          
          payLoad.push(userObject);
        });

        setMgrRows(payLoad);
        setMgrTotalItems(response.data.length);
      });
  }



  /**
   * Assign a manager
   */
  function assignManager(rowId) {
    mgrRows.forEach((element) => {
      if (element.id === rowId) {
        setManagerId(element.id);
        setManagerName(element.displayName);
      }
    });

    setModalMgrOpen(false);
  }



  /**
   * handleRoleRemoval
   */
  function removeRole(roleId) {
    var payLoad = {
      "schemas": ['urn:ietf:params:scim:api:messages:2.0:PatchOp'],
      "Operations": [
          {
            "op": "remove",
            "path": "members",
            "value": id
          }
      ]
    }

    axios
      .patch(apiuri + '/role/' + roleId, payLoad, { headers: httpHeaders })
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setModalMessage('An error has occurred removing a user from the role.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred removing the user from the role: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      })
  }


  /**
   * Check new usertype
   */
  function checkUt(utId) {
    setNewUserType(utId);
    setUtModalOpen(true);
  }



  /**
   * Set new usertype
   */
  function setUt() {
    setUserType(newUserType);
    setUtModalOpen(false);
  }



  /**
   * Decode JWT
   */
  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }



  /**
   * Load data
   */
  function loadData() {
    var jwt = decodeJWT(localStorage.getItem('accessToken'));
    var managerRoles = jwt.roles;

    if (isAddMode) {
      if (managerName === null || managerName === "") {
        setManagerId(jwt.id);
        setManagerName(jwt.name);
      }
    }
    axios.get(apiuri + '/role/all', { headers: httpHeaders })
        .then((response) => {
            // iterate over the roles and work out which user types the current user can transact on
            var validUserTypes = [];
            response.data.forEach((element) => {
              if (managerRoles.includes(element.id)) {
                if (element.validUserTypes) {
                  element.validUserTypes.forEach((vut) => {
                    validUserTypes.push(vut);
                  })
                }
              }
            });
            setRoleAllRows(response.data);
            setRoleAllTotalItems(response.data.length);

            axios.get(apiuri + '/usertype/all', { headers: httpHeaders })
                .then((response) => {
                    var utArray = [{ id: '', name: '' }];
                    var fList = [];
                    var mList = [];
                    response.data.forEach((element) => {
                      if (validUserTypes.includes(element.id) && element.enabled) {
                        if (isBulkMode) {
                          if (element.bulkLoadable === true) {
                            utArray.push({ id: element.id, name: element.name });
                          }
                        } else {
                          utArray.push({ id: element.id, name: element.name });
                        }
                      }
                      if (element.contractBased === false) {
                        fList.push(element.id);
                      }
                      if (element.managed === true) {
                        mList.push(element.id);
                      }
                    });
                    setUserTypes(utArray);
                    setFTEList(fList);
                    setManagedList(mList);

                    // Get third parties
                    axios.get(apiuri + '/thirdparty/all', { headers: httpHeaders })
                        .then((response) => {
                            var tpArray = [{ id: '', name: '' }];
                            response.data.forEach((element) => {
                              tpArray.push({ id: element.id, name: element.name });
                            });
                            setThirdParties(tpArray);

                            // Get attributes
                            axios.get(apiuri + '/attribute/all', { headers: httpHeaders })
                                .then((response) => {
                                    var schemaAttrs = response.data;

                                    var tmpArray1 = [];
                                    var tmpArray2 = [];
                                    schemaAttrs.forEach((element) => {
                                      tmpArray1[element.name] = element.labelen;
                                      tmpArray2[element.name] = element;
                                    });
                                    
                                    setLabels(tmpArray1);
                                    setValidValues(tmpArray2);

                                    if (userType) {
                                      // If a userType is selected, show the attributes on the form
                                      axios.get(apiuri + '/usertype/' + userType, { headers: httpHeaders })
                                        .then((response) => {
                                          var utAttrs = response.data.attributes;
                                          var attrArray = [];
                                          schemaAttrs.forEach((element) => {
                                            if ((utAttrs.includes(element._id) && element.enabled === true) || element.protected === true) {
                                              attrArray[element.name] = true;
                                            } else {
                                              attrArray[element.name] = false;
                                            }
                                          });
                                          if (attrArray["locale"].enabled || attrArray["timezone"].enabled || attrArray["preferredLanguage"].enabled) {
                                            attrArray["localeDetails"] = {enabled: true}
                                          }
                                          setAttributes(attrArray);
                                        });
                                    }

                                    if (!isAddMode) {
                                      // pre-populate the form
                                      axios.get(apiuri + '/user/' + id + "?expanded=true", { headers: httpHeaders })
                                          .then((response) => {
                                              response.data = response.data[0];
                                              if (response.data.externalId) { setExternalId(response.data.externalId); }
                                              if (response.data.userName) { setUserName(response.data.userName); }
                                              if (response.data.name) {
                                                if (response.data.name.givenName) { setGivenName(response.data.name.givenName); }
                                                if (response.data.name.middleName) { setMiddleName(response.data.name.middleName); }
                                                if (response.data.name.familyName) { setFamilyName(response.data.name.familyName); }
                                                if (response.data.name.honorificPrefix) { setHonorificPrefix(response.data.name.honorificPrefix); }
                                                if (response.data.name.honorificSuffix) { setHonorificSuffix(response.data.name.honorificSuffix); }
                                              }
                                              if (response.data.displayName) {
                                                setDisplayName(response.data.displayName);
                                              } else {
                                                setDisplayName(response.data.givenName + " " + response.data.familyName);
                                              }
                                              if (response.data.nickName) { setNickName(response.data.nickName); }
                                              if (response.data.profileUrl) { setProfileUrl(response.data.profileUrl); }
                                              if (response.data.title) { setTitle(response.data.title); }
                                              if (response.data.userType && !userType) {
                                                setUserType(response.data.userType);
                                                // Next section determines whether contracts should be shown on form
                                                if (fList.includes(response.data.userType)) {
                                                  setFTE(true);
                                                } else {
                                                  setFTE(false);
                                                }
                                                // Next section determines whether submit button should be shown on form
                                                if (mList.includes(response.data.userType)) {
                                                  setManaged(true);
                                                } else {
                                                  setManaged(false);
                                                }
                                              }
                                              if (response.data.preferredLanguage) { setPreferredLanguage(response.data.preferredLanguage); }
                                              if (response.data.locale) { setLocale(response.data.locale); }
                                              if (response.data.timezone) { setTimezone(response.data.timezone); }
                                              if (response.data.emails) {
                                                if (response.data.emails.length > 0) {
                                                  if (response.data.emails[0].value) { setEmailAddress(response.data.emails[0].value); }
                                                }
                                              }
                                              if (response.data.phoneNumbers) {
                                                if (response.data.phoneNumbers.length > 0) {
                                                  if (response.data.phoneNumbers[0].value) { setPhoneNumber(response.data.phoneNumbers[0].value); }
                                                }
                                              }
                                              if (response.data.addresses) {
                                                if (response.data.addresses.length > 0) {
                                                  if (response.data.addresses[0].streetAddress) { setStreetAddress(response.data.addresses[0].streetAddress); }
                                                  if (response.data.addresses[0].locality) { setLocality(response.data.addresses[0].locality); }
                                                  if (response.data.addresses[0].region) { setRegion(response.data.addresses[0].region); }
                                                  if (response.data.addresses[0].postalCode) { setPostalCode(response.data.addresses[0].postalCode); }
                                                  if (response.data.addresses[0].country) { setCountry(response.data.addresses[0].country); }
                                                  if (response.data.addresses[0].formatted) { setAddressFormatted(response.data.addresses[0].formatted); }
                                                }
                                              }
                                              if (response.data.x509Certificates) {
                                                if (response.data.x509Certificates.length > 0) { setX509Certificate(response.data.x509Certificates[0].value); }
                                              }
                                              if (response.data.enterprise) {
                                                if (response.data.enterprise.employeeNumber) { setEmployeeNumber(response.data.enterprise.employeeNumber); }
                                                if (response.data.enterprise.costCenter) { setCostCenter(response.data.enterprise.costCenter); }
                                                if (response.data.enterprise.organization) { setOrganization(response.data.enterprise.organization); }
                                                if (response.data.enterprise.division) { setDivision(response.data.enterprise.division); }
                                                if (response.data.enterprise.department) { setDepartment(response.data.enterprise.department); }
                                                if (response.data.enterprise.manager) {
                                                  setManagerId(response.data.enterprise.manager.value);
                                                  setManagerName(response.data.enterprise.manager.displayName);
                                                }
                                              }
                                              if (response.data.umt) {
                                                if (response.data.umt.thirdParty) { setUMTThirdParty(response.data.umt.thirdParty); }
                                                if (response.data.umt.startDate) { setUMTStartDate(response.data.umt.startDate); }
                                                if (response.data.umt.endDate) { setUMTEndDate(response.data.umt.endDate); }
                                                if (response.data.umt.reviewDate) { setUMTReviewDate(response.data.umt.reviewDate); }
                                                if (response.data.umt.domain) { setUMTDomain(response.data.umt.domain); }
                                                if (response.data.umt.notes) { setUMTNotes(response.data.umt.notes); }
                                              }
                                              if (response.data.custom) {
                                                if (response.data.custom.customAttribute01) { setCustomAttribute01(response.data.custom.customAttribute01); }
                                                if (response.data.custom.customAttribute02) { setCustomAttribute02(response.data.custom.customAttribute02); }
                                                if (response.data.custom.customAttribute03) { setCustomAttribute03(response.data.custom.customAttribute03); }
                                                if (response.data.custom.customAttribute04) { setCustomAttribute04(response.data.custom.customAttribute04); }
                                                if (response.data.custom.customAttribute05) { setCustomAttribute05(response.data.custom.customAttribute05); }
                                                if (response.data.custom.customAttribute06) { setCustomAttribute06(response.data.custom.customAttribute06); }
                                                if (response.data.custom.customAttribute07) { setCustomAttribute07(response.data.custom.customAttribute07); }
                                                if (response.data.custom.customAttribute08) { setCustomAttribute08(response.data.custom.customAttribute08); }
                                                if (response.data.custom.customAttribute09) { setCustomAttribute09(response.data.custom.customAttribute09); }
                                                if (response.data.custom.customAttribute10) { setCustomAttribute10(response.data.custom.customAttribute10); }
                                                if (response.data.custom.customAttribute11) { setCustomAttribute11(response.data.custom.customAttribute11); }
                                                if (response.data.custom.customAttribute12) { setCustomAttribute12(response.data.custom.customAttribute12); }
                                                if (response.data.custom.customAttribute13) { setCustomAttribute13(response.data.custom.customAttribute13); }
                                                if (response.data.custom.customAttribute14) { setCustomAttribute14(response.data.custom.customAttribute14); }
                                                if (response.data.custom.customAttribute15) { setCustomAttribute15(response.data.custom.customAttribute15); }
                                                if (response.data.custom.customAttribute16) { setCustomAttribute16(response.data.custom.customAttribute16); }
                                                if (response.data.custom.customAttribute17) { setCustomAttribute17(response.data.custom.customAttribute17); }
                                                if (response.data.custom.customAttribute18) { setCustomAttribute18(response.data.custom.customAttribute18); }
                                                if (response.data.custom.customAttribute19) { setCustomAttribute19(response.data.custom.customAttribute19); }
                                                if (response.data.custom.customAttribute20) { setCustomAttribute20(response.data.custom.customAttribute20); }
                                              }

                                              // Populate contract history
                                              if (response.data.allContracts) {
                                                var chPayload = [];
                                                var ch = response.data.allContracts;
                                                ch.forEach((element) => {
                                                  element.id = element._id;
                                                  if (element.startDate) {
                                                    element.startDate = new Date(element.startDate).toLocaleDateString(undefined, dOptions);
                                                  }
                                                  if (element.endDate) {
                                                    element.endDate = new Date(element.endDate).toLocaleDateString(undefined, dOptions);
                                                  }
                                                  if (element.state === "Approved" || element.state === "Approved (Auto)") {
                                                    element.state = <Tag type='green'>Approved</Tag>;
                                                  } else {
                                                    element.state = <Tag type='red'>{element.state}</Tag>;
                                                  }
                                                  chPayload.push(element);
                                                });
                                                setRows(chPayload);
                                                setTotalItems(chPayload.length);
                                              }

                                              // Populate roles
                                              if (response.data.roles) {
                                                var rolePayload = [];
                                                var roles = response.data.roles;
                                                roles.forEach((element) => {
                                                  element.id = element._id;
                                                  rolePayload.push(element);
                                                });
                                                setRoleRows(rolePayload);
                                                setRoleTotalItems(rolePayload.length);
                                              }
                                          }); // close call to user/:id

                                    }// close if isAddMode statement

                            }) // close call to attribute/all

                        }) // close call to thirdparty/all

                  }) // close call to usertype/all

          }) // close call to role/all
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/users">My users</BreadcrumbItem>
              </Breadcrumb>

              {isBulkMode
              ?
                <h3 className="umt--heading">Bulk add users</h3>
              :
              isAddMode
              ?
                <h3 className="umt--heading">Add user</h3>
              :
                <h3 className="umt--heading">Edit user: {displayName}</h3>
              }

              <Form onSubmit={handleSubmit(onSubmit)}>
                {isBulkMode
                ?
                <div style={{ marginBottom: '2rem' }}>
                <span style={{ color: 'red' }}>{formErrors['bulkNumber']}</span>
                  <TextInput
                    value={bulkNumber}
                    id={"bulkNumber"}
                    type="text"
                    labelText="Number of users"
                    helperText="How many users will be created"
                    onChange={(e) => setBulkNumber(e.target.value)}
                  />
                </div>
                :
                null
                }
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['userType']}</span>
                  <Select
                    value={userType}
                    id="userType"
                    invalidText="This is an invalid error message."
                    labelText={labels.userType}
                    onChange={(e) => {
                        if (userType) {
                          checkUt(e.target.value);
                        } else {
                          setUserType(e.target.value);
                        }
                      }
                    }
                  >
                    {userTypes.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['umtThirdParty']}</span>
                  <Select
                    value={umtThirdParty}
                    id="thirdParty"
                    labelText={labels['umt.thirdParty']}
                    onChange={(e) => setUMTThirdParty(e.target.value)}
                  >
                    {thirdParties.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                {attributes["externalId"]
                ?
                <UMTComponent
                  attr={validValues["externalId"]}
                  value={externalId}
                  func={(e) => setExternalId(e.target.value)}
                  errorText={formErrors['externalId']}
                />
                :
                null
                }

                {attributes["userName"]
                ?
                <UMTComponent
                  attr={validValues["userName"]}
                  value={userName}
                  func={(e) => setUserName(e.target.value)}
                  errorText={formErrors['userName']}
                />
                :
                null
                }

                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Name</h4>
                </div>
 
                <UMTComponent
                  attr={validValues["name.givenName"]}
                  value={givenName}
                  func={(e) => {setGivenName(e.target.value); setDisplayName(e.target.value + ' ' + familyName)}}
                  errorText={formErrors['givenName']}
                />

                {attributes["name.middleName"]
                ?
                <UMTComponent
                  attr={validValues["name.middleName"]}
                  value={middleName}
                  func={(e) => setMiddleName(e.target.value)}
                  errorText={formErrors['middleName']}
                />
                :
                null
                }

                <UMTComponent
                  attr={validValues["name.familyName"]}
                  value={familyName}
                  func={(e) => {setFamilyName(e.target.value); setDisplayName(givenName + ' ' + e.target.value)}}
                  errorText={formErrors['familyName']}
                />

                {attributes["name.honorificPrefix"]
                ?
                <UMTComponent
                  attr={validValues["name.honorificPrefix"]}
                  value={honorificPrefix}
                  func={(e) => setHonorificPrefix(e.target.value)}
                  errorText={formErrors['honorificPrefix']}
                />
                :
                null
                }

                {attributes["name.honorificSuffix"]
                ?
                <UMTComponent
                  attr={validValues["name.honorificSuffix"]}
                  value={honorificSuffix}formErrors
                  func={(e) => setHonorificSuffix(e.target.value)}
                  errorText={formErrors['honorificSuffix']}
                />
                :
                null
                }

                {attributes["displayName"]
                ?
                <UMTComponent
                  attr={validValues["displayName"]}
                  value={displayName}
                  func={(e) => setDisplayName(e.target.value)}
                  errorText={formErrors['displayName']}
                />
                :
                null
                }

                {attributes["nickName"]
                ?
                <UMTComponent
                  attr={validValues["nickName"]}
                  value={nickName}
                  func={(e) => setNickName(e.target.value)}
                  errorText={formErrors['nickName']}
                />
                :
                null
                }

                {attributes["profileUrl"]
                ?
                <UMTComponent
                  attr={validValues["profileUrl"]}
                  value={profileUrl}
                  func={(e) => setProfileUrl(e.target.value)}
                  errorText={formErrors['profileUrl']}
                />
                :
                null
                }

                {attributes["title"]
                ?
                <UMTComponent
                attr={validValues["title"]}
                value={title}
                func={(e) => setTitle(e.target.value)}
                errorText={formErrors['title']}
                />
                :
                null
                }

                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Contact details</h4>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['emailAddress']}</span>
                  <TextInput
                    value={emailAddress}
                    id="emailAddress"
                    type="text"
                    labelText={labels['emails.value']}
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>

                {attributes["phoneNumbers"]
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['phoneNumber']}</span>
                      <TextInput
                        value={phoneNumber}
                        id="phoneNumber"
                        type="text"
                        labelText={labels['phoneNumbers.value']}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </>
                :
                  null
                }



                {attributes["localeDetails"]
                ?
                  <>
                    <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                      <h4>Locale details</h4>
                    </div>
                  </>
                :
                  null
                }

                {attributes["preferredLanguage"]
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['preferredLanguage']}</span>
                      <Select
                        defaultValue="N/A"
                        value={preferredLanguage}
                        id="preferredLanguage"
                        labelText={labels['preferredLanguage']}
                        onChange={(e) => setPreferredLanguage(e.target.value)}
                      >
                        <SnipPreferredLanguage />
                      </Select>
                    </div>
                  </>
                :
                  null
                }

                {attributes["locale"]
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['locale']}</span>
                      <Select
                        defaultValue="N/A"
                        value={locale}
                        id="locale"
                        labelText={labels['locale']}
                        onChange={(e) => setLocale(e.target.value)}
                      >
                        <SnipLocale />
                      </Select>
                    </div>
                  </>
                :
                  null
                }

                {attributes["timezone"]
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['timezone']}</span>
                      <Select
                        defaultValue="N/A"
                        value={timezone}
                        id="timezone"
                        labelText={labels['timezone']}
                        onChange={(e) => setTimezone(e.target.value)}
                      >
                        <SnipTimezone />
                      </Select>
                    </div>
                  </>
                :
                  null
                }



                {attributes["addresses"]
                ?
                  <>
                    <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                      <h4>Address details</h4>
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['streetAddress']}</span>
                      <TextInput
                        value={streetAddress}
                        id="streetAddress"
                        type="text"
                        labelText={labels['addresses.streetAddress']}
                        onChange={(e) => setStreetAddress(e.target.value)}
                      />
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['locality']}</span>
                      <TextInput
                        value={locality}
                        id="locality"
                        type="text"
                        labelText={labels['addresses.locality']}
                        onChange={(e) => setLocality(e.target.value)}
                      />
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['region']}</span>
                      <TextInput
                        value={region}
                        id="region"
                        type="text"
                        labelText={labels['addresses.region']}
                        onChange={(e) => setRegion(e.target.value)}
                      />
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['postalCode']}</span>
                      <TextInput
                        value={postalCode}
                        id="postalCode"
                        type="text"
                        labelText={labels['addresses.postalCode']}
                        onChange={(e) => setPostalCode(e.target.value)}
                      />
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['country']}</span>
                      <Select
                        defaultValue="N/A"
                        value={country}
                        id="country"
                        labelText={labels['addresses.country']}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <SnipCountry />
                      </Select>
                    </div>

                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['addressFormatted']}</span>
                      <TextArea
                        value={addressFormatted}
                        cols={50}
                        id="addressFormatted"
                        labelText={labels['addresses.formatted']}
                        rows={4}
                        onChange={(e) => setAddressFormatted(e.target.value)}
                      />
                    </div>
                  </>
                :
                  null
                }

                {attributes["x509Certificate"]
                ?
                <UMTComponent
                  attr={validValues["x509Certificate"]}
                  value={x509Certificate}
                  func={(e) => setX509Certificate(e.target.value)}
                  errorText={formErrors['x509Certificate']}
                />
                :
                null
                }

                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Enterprise details</h4>
                </div>
 
                <div style={{ marginBottom: '2rem' }}>
                  <TextInput
                    value={managerName}
                    id="managerName"
                    type="text"
                    labelText={labels['enterprise.manager']}
                    disabled
                  />
                  <Link onClick={() => { setMgrRows([]); setMgrTotalItems(0); setManagerSearch('') ; setModalMgrOpen(true); }}><Search16 /> Manager search</Link>
                </div>
 
                {attributes["enterprise.employeeNumber"]
                ?
                <UMTComponent
                  attr={validValues["enterprise.employeeNumber"]}
                  value={employeeNumber}
                  func={(e) => setEmployeeNumber(e.target.value)}
                  errorText={formErrors['employeeNumber']}
                />
                :
                null
                }

                {attributes["enterprise.costCenter"]
                ?
                <UMTComponent
                  attr={validValues["enterprise.costCenter"]}
                  value={costCenter}
                  func={(e) => setCostCenter(e.target.value)}
                  errorText={formErrors['costCenter']}
                />
                :
                null
                }

                {attributes["enterprise.organization"]
                ?
                <UMTComponent
                  attr={validValues["enterprise.organization"]}
                  value={organization}
                  func={(e) => setOrganization(e.target.value)}
                  errorText={formErrors['organization']}
                />
                :
                null
                }
 
                {attributes["enterprise.division"]
                ?
                <UMTComponent
                  attr={validValues["enterprise.division"]}
                  value={division}
                  func={(e) => setDivision(e.target.value)}
                  errorText={formErrors['division']}
                />
                :
                null
                }
 
                {attributes["enterprise.department"]
                ?
                <UMTComponent
                  attr={validValues["enterprise.department"]}
                  value={department}
                  func={(e) => setDepartment(e.target.value)}
                  errorText={formErrors['department']}
                />
                :
                null
                }

                {attributes["umt"]
                ?
                  <>
                    <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                      <h4>UMT details</h4>
                    </div>
                  </>
                :
                  null
                }

                <>
                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['umt.startDate']}</span>
                    <DatePicker
                      id="startDate"
                      value={umtStartDate}
                      datePickerType="single"
                      dateFormat="d/m/Y"
                      onChange={(e) => setUMTStartDate(e)}
                    >
                      <DatePickerInput
                        id="startDateInput"
                        placeholder="dd/mm/yyyy"
                        labelText={labels['umt.startDate']}
                        onChange={(e) => {
                          if (e.target.value.length === 10) {
                            setUMTStartDate(new Date(e.target.value))
                          }
                        }}
                      />
                    </DatePicker>
                  </div>
                </>

                <>
                  <div style={{ marginBottom: '2rem' }}>
                    <span style={{ color: 'red' }}>{formErrors['umt.endDate']}</span>
                    <DatePicker
                      id="endDate"
                      value={umtEndDate}
                      datePickerType="single"
                      dateFormat="d/m/Y"
                      onChange={(e) => setUMTEndDate(e)}
                    >
                      <DatePickerInput
                        id="endDateInput"
                        placeholder="dd/mm/yyyy"
                        labelText={labels['umt.endDate']}
                        onChange={(e) => {
                          if (e.target.value.length === 10) {
                            setUMTEndDate(new Date(e.target.value))
                          }
                        }}
                      />
                    </DatePicker>
                  </div>
                </>

                {isAddMode
                ?
                  <>
                    <UMTComponent
                      attr={validValues["umt.purchaseOrder"]}
                      value={umtPurchaseOrder}
                      func={(e) => setUMTPurchaseOrder(e.target.value)}
                      errorText={formErrors['umt.purchaseOrder']}
                    />

                    {attributes["umt.contractStartDate"]
                    ?
                      <>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['umt.contractStartDate']}</span>
                          <DatePicker
                            id="contractStartDate"
                            value={umtContractStartDate}
                            datePickerType="single"
                            dateFormat="d/m/Y"
                            onChange={(e) => setUMTContractStartDate(e)}
                          >
                            <DatePickerInput
                              id="contractStartDateInput"
                              placeholder="dd/mm/yyyy"
                              labelText={labels['umt.contractStartDate']}
                              onChange={(e) => {
                                if (e.target.value.length === 10) {
                                  setUMTContractStartDate(new Date(e.target.value))
                                }
                              }}
                            />
                          </DatePicker>
                        </div>
                      </>
                    :
                      null
                    }

                    {attributes["umt.contractEndDate"]
                    ?
                      <>
                        <div style={{ marginBottom: '2rem' }}>
                          <span style={{ color: 'red' }}>{formErrors['umt.contractEndDate']}</span>
                          <DatePicker
                            id="contractEndDate"
                            value={umtContractEndDate}
                            datePickerType="single"
                            dateFormat="d/m/Y"
                            onChange={(e) => setUMTContractEndDate(e)}
                          >
                            <DatePickerInput
                              id="contractEndDateInput"
                              placeholder="dd/mm/yyyy"
                              labelText={labels['umt.contractEndDate']}
                              onChange={(e) => {
                                if (e.target.value.length === 10) {
                                  setUMTContractEndDate(new Date(e.target.value))
                                }
                              }}
                            />
                          </DatePicker>
                        </div>
                      </>
                    :
                      null
                    }
                  </>
                :
                  null
                }
 
                {attributes["umt.domain"]
                ?
                <UMTComponent
                  attr={validValues["umt.domain"]}
                  value={umtDomain}
                  func={(e) => setUMTDomain(e.target.value)}
                  errorText={formErrors['umt.domain']}
                />
                :
                null
                }

                {attributes["umt.notes"]
                ?
                <UMTComponent
                  attr={validValues["umt.notes"]}
                  value={umtNotes}
                  func={(e) => setUMTNotes(e.target.value)}
                  errorText={formErrors['umt.notes']}
                />
                : null
                }



                {attributes["custom"]
                ?
                  <>
                    <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                      <h4>Custom attributes</h4>
                    </div>
                  </>
                :
                  null
                }

                {attributes["custom.customAttribute01"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute01"]}
                    value={customAttribute01}
                    func={(e) => setCustomAttribute01(e.target.value)}
                    errorText={formErrors['customAttribute01']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute02"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute02"]}
                    value={customAttribute02}
                    func={(e) => setCustomAttribute02(e.target.value)}
                    errorText={formErrors['customAttribute02']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute03"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute03"]}
                    value={customAttribute03}
                    func={(e) => setCustomAttribute03(e.target.value)}
                    errorText={formErrors['customAttribute03']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute04"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute04"]}
                    value={customAttribute04}
                    func={(e) => setCustomAttribute04(e.target.value)}
                    errorText={formErrors['customAttribute04']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute05"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute05"]}
                    value={customAttribute05}
                    func={(e) => setCustomAttribute05(e.target.value)}
                    errorText={formErrors['customAttribute05']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute06"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute06"]}
                    value={customAttribute06}
                    func={(e) => setCustomAttribute06(e.target.value)}
                    errorText={formErrors['customAttribute06']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute07"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute07"]}
                    value={customAttribute07}
                    func={(e) => setCustomAttribute07(e.target.value)}
                    errorText={formErrors['customAttribute07']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute08"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute08"]}
                    value={customAttribute08}
                    func={(e) => setCustomAttribute08(e.target.value)}
                    errorText={formErrors['customAttribute08']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute09"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute09"]}
                    value={customAttribute09}
                    func={(e) => setCustomAttribute09(e.target.value)}
                    errorText={formErrors['customAttribute09']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute10"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute10"]}
                    value={customAttribute10}
                    func={(e) => setCustomAttribute10(e.target.value)}
                    errorText={formErrors['customAttribute10']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute11"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute11"]}
                    value={customAttribute11}
                    func={(e) => setCustomAttribute11(e.target.value)}
                    errorText={formErrors['customAttribute11']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute12"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute12"]}
                    value={customAttribute12}
                    func={(e) => setCustomAttribute12(e.target.value)}
                    errorText={formErrors['customAttribute12']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute13"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute13"]}
                    value={customAttribute13}
                    func={(e) => setCustomAttribute13(e.target.value)}
                    errorText={formErrors['customAttribute13']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute14"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute14"]}
                    value={customAttribute14}
                    func={(e) => setCustomAttribute14(e.target.value)}
                    errorText={formErrors['customAttribute14']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute15"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute15"]}
                    value={customAttribute15}
                    func={(e) => setCustomAttribute15(e.target.value)}
                    errorText={formErrors['customAttribute15']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute16"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute16"]}
                    value={customAttribute16}
                    func={(e) => setCustomAttribute16(e.target.value)}
                    errorText={formErrors['customAttribute16']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute17"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute17"]}
                    value={customAttribute17}
                    func={(e) => setCustomAttribute17(e.target.value)}
                    errorText={formErrors['customAttribute17']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute18"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute18"]}
                    value={customAttribute18}
                    func={(e) => setCustomAttribute18(e.target.value)}
                    errorText={formErrors['customAttribute18']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute19"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute19"]}
                    value={customAttribute19}
                    func={(e) => setCustomAttribute19(e.target.value)}
                    errorText={formErrors['customAttribute19']}
                  />
                :
                  null
                }

                {attributes["custom.customAttribute20"]
                ?
                  <UMTComponent
                    attr={validValues["custom.customAttribute20"]}
                    value={customAttribute20}
                    func={(e) => setCustomAttribute20(e.target.value)}
                    errorText={formErrors['customAttribute20']}
                  />
                :
                  null
                }

                <ButtonSet>
                {isAddMode
                  ?
                    <><Button kind="primary" type="submit">Create user</Button></>
                  :
					          <>
                      {managed
                      ?
                        <><Button kind="primary" type="submit">Update user</Button></>
                      :
                        null
                      }
					          </>
                  }
                  <Button kind="secondary" onClick={() => ( history.goBack() )}>
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-8">
              {!isAddMode
                ?
                  <>
                    <h3 className="umt--helper-heading">Roles</h3>
                    <DataTable
                      rows={roleRows.slice(
                        roleFirstRowIndex,
                        roleFirstRowIndex + roleCurrentPageSize
                      )}
                      headers={roleHeaders}
                      isSortable={false}
                      size="sm"
                      render={({
                        rows,
                        headers,
                        getHeaderProps,
                        getTableProps,
                        getToolbarProps
                      }) => (
                        <TableContainer className="umt--table-container">
                          <TableToolbar {...getToolbarProps()}>
                            <TableToolbarContent>
                              <Button size="small" kind="primary" renderIcon={Add16} onClick={() => setModalRoleOpen(true)}>
                                Add role
                              </Button>
                            </TableToolbarContent>
                          </TableToolbar>

                          <Table className="umt--table" {...getTableProps()}>
                            <TableHead>
                              <TableRow>
                                {headers.map((header) => (
                                  <TableHeader {...getHeaderProps({ header })}>
                                    {header.header}
                                  </TableHeader>
                                ))}
                                <TableHeader className="umt--table-overflow" />
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {rows.map((row) => (
                                <TableRow key={row.id}>
                                  {row.cells.map((cell) => (
                                    <TableCell key={cell.id}>
                                        {cell.value}
                                    </TableCell>
                                  ))}
                                  <TableCell className="umt--table-cell">
                                    <Button
                                      size="small"
                                      kind="ghost"
                                      renderIcon={TrashCan16}
                                      hasIconOnly
                                      iconDescription="Remove role from user"
                                      tooltipAlignment="end"
                                      onClick={() => { removeRole(row.id) }}
                                    ></Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    />
                    <Pagination
                      className="umt--pagination"
                      size="sm"
                      totalItems={roleTotalItems}
                      backwardText="Previous page"
                      forwardText="Next page"
                      pageSize={roleCurrentPageSize}
                      pageSizes={[5]}
                      itemsPerPageText="# per page"
                      pageInputDisabled
                      pageSizeInputDisabled
                      onChange={({ page, pageSize }) => {
                        if (pageSize !== roleCurrentPageSize) {
                          setRoleCurrentPageSize(pageSize);
                        }
                        setRoleFirstRowIndex(pageSize * (page - 1));
                      }}
                    />

                    {!fte
                    ?
                      <>
                        <br />
                        <br />
                        <h3 className="umt--helper-heading">Contract history</h3>
                        <DataTable
                          rows={rows.slice(
                            firstRowIndex,
                            firstRowIndex + currentPageSize
                          )}
                          headers={headers}
                          isSortable={false}
                          size="sm"
                          render={({
                            rows,
                            headers,
                            getHeaderProps,
                            getTableProps,
                            getToolbarProps
                          }) => (
                            <TableContainer className="umt--table-container">
                              <TableToolbar {...getToolbarProps()}>
                                <TableToolbarContent>
                                  <Button size="small" kind="primary" renderIcon={Add16} onClick={() => setModalAppOpen(true)}>
                                    Add contract
                                  </Button>
                                </TableToolbarContent>
                              </TableToolbar>

                              <Table {...getTableProps()}>
                                <TableHead>
                                  <TableRow>
                                    {headers.map((header) => (
                                      <TableHeader {...getHeaderProps({ header })}>
                                        {header.header}
                                      </TableHeader>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {rows.map((row) => (
                                    <TableRow key={row.id}>
                                      {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>
                                            {cell.value}
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        />
                        <Pagination
                          size="sm"
                          totalItems={totalItems}
                          backwardText="Previous page"
                          forwardText="Next page"
                          pageSize={currentPageSize}
                          pageSizes={[10]}
                          itemsPerPageText="# per page"
                          pageInputDisabled
                          pageSizeInputDisabled
                          onChange={({ page, pageSize }) => {
                            if (pageSize !== currentPageSize) {
                              setCurrentPageSize(pageSize);
                            }
                            setFirstRowIndex(pageSize * (page - 1));
                          }}
                        />
                      </>
                    :
                      null
                    }
                  </>
                :
                  <>
                    <h3 className="umt--helper-heading">Help</h3>
                    <p className="umt--helper-p">
                      When a user is created, its transition state will be set to one of the following:
                      <ul>
                        <li>- Unapproved, if the selected user type requires approval</li>
                        <li>- Approved (Auto), if approval is not required</li>
                      </ul>
                      <br /><br />
                      Approvals will be routed to the appropriate approvers. These can be managed from the Approvals tab.
                    </p>
                  </>
              }
            </div>
          </div>
        </div>
      </Content>

      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">User update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}

      {modalMgrOpen ? (
        <ComposedModal open={modalMgrOpen} onClose={() => setModalMgrOpen(false)} size="lg">
          <ModalHeader>
            <h3 className="umt--heading">Manager assignment</h3>
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={handleSubmit(searchForManager)}>
             <span style={{ color: 'red' }}>{formErrors['notes']}</span>
              <div style={{ marginBottom: '2rem' }}>
                <TextInput
                  key="managerSearch"
                  id="managerSearchId"
                  type="text"
                  labelText="Search details"
                  onChange={(e) => setManagerSearch(e.target.value)}
                />
              </div>
              <ButtonSet>
                <Button kind="primary"
                        type="submit"
                        onClick={() => { searchForManager(); }}
                >
                  Search
                </Button>
              </ButtonSet>
            </Form>

            <DataTable
              rows={mgrRows.slice(
                mgrFirstRowIndex,
                mgrFirstRowIndex + mgrCurrentPageSize
              )}
              headers={mgrHeaders}
              isSortable={false}
              render={({
                rows,
                headers,
               getHeaderProps,
                getTableProps
              }) => (
                <TableContainer className="umt--table-container">
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader className="umt--table-overflow" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                              {cell.value}
                            </TableCell>
                          ))}
                            <TableCell className="umt--table-overflow">
                              <Button
                                size="small"
                                kind="ghost"
                                renderIcon={Add16}
                                hasIconOnly
                                iconDescription="Assign as manager"
                                tooltipAlignment="end"
                                onClick={() => { assignManager(row.id); }}
                              ></Button>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
            <Pagination
              size="sm"
              totalItems={mgrTotalItems}
              backwardText="Previous page"
              forwardText="Next page"
              pageSize="5"
              pageSizes={[5]}
              itemsPerPageText="# per page"
              pageInputDisabled
              pageSizeInputDisabled
              onChange={({ page, pageSize }) => {
                if (pageSize !== mgrCurrentPageSize) {
                  setMgrCurrentPageSize(pageSize);
                }
                setMgrFirstRowIndex(pageSize * (page - 1));
              }}
            />

          </ModalBody>
          <ModalFooter>
            <Button kind="secondary" onClick={() => { setModalMgrOpen(false); }}>
              Cancel
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}

      <ComposedModal open={modalAppOpen} onClose={() => setModalAppOpen(false)} size="sm">
        <ModalHeader>
          <h3 className="umt--heading">Add contract</h3>
        </ModalHeader>
        <Form onSubmit={handleSubmit(handleNewContract)}>
          <ModalBody>
            <span style={{ color: 'red' }}>{formErrors['notes']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <UMTComponent
                attr={validValues["umt.purchaseOrder"]}
                func={(e) => setUMTPurchaseOrder(e.target.value)}
                errorText={formErrors['umt.purchaseOrder']}
              />

              <div style={{ marginBottom: '2rem' }}>
                <span style={{ color: 'red' }}>{formErrors['umt.contractStartDate']}</span>
                <DatePicker
                  id="contractStartDate"
                  value={umtContractStartDate}
                  datePickerType="single"
                  dateFormat="d/m/Y"
                  onChange={(e) => setUMTContractStartDate(e)}
                >
                  <DatePickerInput
                    id="contractStartDateInput"
                    placeholder="dd/mm/yyyy"
                    labelText={labels['umt.contractStartDate']}
                    onChange={(e) => {
                      if (e.target.value.length === 10) {
                        setUMTContractStartDate(new Date(e.target.value))
                      }
                    }}
                  />
                </DatePicker>
              </div>

              <div style={{ marginBottom: '2rem' }}>
                <span style={{ color: 'red' }}>{formErrors['umt.contractEndDate']}</span>
                <DatePicker
                  id="contractEndDate"
                  value={umtContractEndDate}
                  datePickerType="single"
                  dateFormat="d/m/Y"
                  onChange={(e) => setUMTContractEndDate(e)}
                >
                  <DatePickerInput
                    id="contractEndDateInput"
                    placeholder="dd/mm/yyyy"
                    labelText={labels['umt.contractEndDate']}
                    onChange={(e) => {
                      if (e.target.value.length === 10) {
                        setUMTContractEndDate(new Date(e.target.value))
                      }
                    }}
                  />
                </DatePicker>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button kind="primary" type="submit">Create</Button>
            <Button kind="secondary" onClick={() => { setModalAppOpen(false); }} >Cancel</Button>
          </ModalFooter>
        </Form>
      </ComposedModal>

      {modalRoleOpen ? (
      <>
        <ComposedModal open={modalRoleOpen} onClose={() => setModalRoleOpen(false)} isFullWidth>
          <ModalHeader>
            <h3 className="umt--heading">Role search</h3>
          </ModalHeader>
          <ModalBody>
            <DataTable
              rows={roleAllRows.slice(
                roleAllFirstRowIndex,
                roleAllFirstRowIndex + roleAllCurrentPageSize
              )}
              headers={roleAllHeaders}
              isSortable={false}
              render={({
                rows,
                headers,
                getHeaderProps,
                getTableProps
              }) => (
                <TableContainer className="umt--table-container">
                  <Table {...getTableProps()}>
                    <TableHead>
                      <TableRow>
                        {headers.map((header) => (
                          <TableHeader {...getHeaderProps({ header })}>
                            {header.header}
                          </TableHeader>
                        ))}
                        <TableHeader className="umt--table-overflow" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id}>
                          {row.cells.map((cell) => (
                            <TableCell key={cell.id}>
                              {cell.value}
                            </TableCell>
                          ))}
                            <TableCell className="umt--table-overflow">
                              <Button
                                size="small"
                                kind="ghost"
                                renderIcon={Add16}
                                hasIconOnly
                                iconDescription="Add role to user"
                                tooltipAlignment="end"
                                onClick={() => {
                                    addRole(row.id);
                                }}
                              ></Button>
                            </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            />
            <Pagination
              size="sm"
              totalItems={roleAllTotalItems}
              backwardText="Previous page"
              forwardText="Next page"
              pageSize="10"
              pageSizes={[10]}
              itemsPerPageText="# per page"
              pageInputDisabled
              pageSizeInputDisabled
              onChange={({ page, pageSize }) => {
                if (pageSize !== roleAllCurrentPageSize) {
                  setRoleAllCurrentPageSize(pageSize);
                }
                setRoleAllFirstRowIndex(pageSize * (page - 1));
              }}
            />
          </ModalBody>
          <ModalFooter>
            <Button kind="primary" onClick={() => { setModalRoleOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      </>
      )
      :
        null
      }

      {utModalOpen ? (
        <ComposedModal open={utModalOpen} onClose={() => setUtModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Info</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">
              Changing a user's user type may change the attributes available.
            </p>
          </ModalBody>
          <ModalFooter>
            <ButtonSet>
              <Button kind="primary" onClick={() => { setUt(); }}>
                Continue
              </Button>
              <Button kind="secondary" onClick={() => { setUtModalOpen(false); }}>
                Cancel
              </Button>
            </ButtonSet>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}
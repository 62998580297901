// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import elements
import {
  HeaderContainer,
  SideNav,
  SwitcherDivider,
  SideNavItems,
  SideNavLink
} from 'carbon-components-react';

const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function UserNav() {
  const [links, setLinks] = useState([]);
  const [helpdesk, setHelpdesk] = useState(false);
  const [userTypeAdmin, setUserTypeAdmin] = useState(false);

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  


  /**
   * Load data
   */
  useEffect(() => {
    // Get the JWT
    var jwt = decodeJWT(localStorage.getItem('accessToken'));
    if (jwt.permissions) {
      if (jwt.permissions.includes('user_r') || jwt.permissions.includes('user_w')) {
        setHelpdesk(true);
      }
    }
  
    var typeLinks = [];
    var myRoles = jwt.roles;
  
    // Find User Types that have administrative functions
    axios.get(apiuri + '/usertype/all', { headers: httpHeaders })
        .then((response) => {
          response.data.forEach((element) => {
            if (myRoles.includes(element.administrator)) {
              element.link = "/users/type/" + element._id;
              typeLinks.push(element);
              setUserTypeAdmin(true);
            }
          });
        })
    setLinks(typeLinks);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="User navigation"
              className="umt--sidenav"
            >
              <SideNavItems className="umt--sidenavitems">
                <SideNavLink className="umt--sidenavlink" href="/users">
                  My users
                </SideNavLink>

                {userTypeAdmin
                  ?
                    <>
                      <SwitcherDivider />
                      {links.map((element) => (
                        <SideNavLink className="umt--sidenavlink" href={element.link}>
                          {element.name} users
                        </SideNavLink>
                      ))}
                    </>
                  :
                    null
                }

                {helpdesk
                  ?
                    <>
                      <SwitcherDivider />
                      <SideNavLink className="umt--sidenavlink" href="/users/helpdesk">
                        All users (helpdesk)
                      </SideNavLink>
                    </>
                  :
                    null
                }
              </SideNavItems>
            </SideNav>
          </>
        )}
      />
    </>
  );
}
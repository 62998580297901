// Import core modules
import React from 'react';
import { Link } from 'react-router-dom';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  Tile,
} from 'carbon-components-react';
import { ChartCustom, Gear, Group, Office, InspectData, User } from '@carbon/pictograms-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

/**
 * Core function
 */
const ReportsPage = () => {
  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  var hasPerm = false;
  if (jwt.permissions.includes("report_w")) {
    hasPerm = true;
  }

  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/reports">Reports</BreadcrumbItem>
        </Breadcrumb>
        <h3 className="umt--heading">Run/schedule a report</h3>
        <div className="bs--grid">
          <div className="bx--row">
            <div className="bx--col-lg-4">
              <Tile>
                {hasPerm ?
                  <Link
                    to={{
                      pathname: '/reports/request',
                      state: {
                        name: 'organisation',
                        reportid: '000000000000000000000701'
                      }
                    }}
                  >
                    <Button>
                      <Office />
                    </Button>
                  </Link>
                :
                  <Button disabled >
                    <Office />
                  </Button>
                }

                <h3 className="umt--tile-title">Organisation report</h3>
                <p className="umt--tile-description">
                  Report of third party organisations.
                </p>
              </Tile>
            </div>
            <div className="bx--col-lg-4">
              <Tile>
                {hasPerm ?
                  <Link
                    to={{
                      pathname: '/reports/request',
                      state: {
                        name: 'users',
                        reportid: '000000000000000000000702'
                      }
                    }}
                  >
                    <Button href="/report-request">
                      <Group />
                    </Button>
                  </Link>
                :
                  <Button disabled>
                    <Group />
                  </Button>
                }

                <h3 className="umt--tile-title">Users report</h3>
                <p className="umt--tile-description">
                  Snapshot report of third party organisation users.
                </p>
              </Tile>
            </div>
            <div className="bx--col-lg-4">
              <Tile>
                {hasPerm ?
                  <Link
                    to={{
                      pathname: '/reports/request',
                      state: {
                        name: 'user',
                        reportid: '000000000000000000000703'
                      }
                    }}
                  >
                    <Button>
                      <User />
                    </Button>
                  </Link>
                :
                    <Button disabled>
                      <User />
                    </Button>
                }

                <h3 className="umt--tile-title">User's contracts report</h3>
                <p className="umt--tile-description">
                  Historical contracts report for a specific user.
                </p>
              </Tile>
            </div>
            <div className="bx--col-lg-4">
              <Tile>
                {hasPerm ?
                  <Link
                    to={{
                      pathname: '/reports/request',
                      state: {
                        name: 'activity',
                        reportid: '000000000000000000000704'
                      }
                    }}
                  >
                    <Button>
                      <InspectData />
                    </Button>
                  </Link>
                :
                <Button disabled>
                  <InspectData />
                </Button>
                }

                <h3 className="umt--tile-title">Activity report</h3>
                <p className="umt--tile-description">
                  Detailed audit history of actions undertaken.
                </p>
              </Tile>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default ReportsPage;

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from '../Approvals/_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  DataTable,
  Form,TextInput,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tag
} from 'carbon-components-react';



// Setup variables
const apiuri = process.env.REACT_APP_APIURI;
const headers = [
  { key: 'beneficiary', header: 'Beneficiary' },
  { key: 'thirdParty', header: 'Third party' },
  { key: 'requestor', header: 'Requestor' },
  { key: 'startDate', header: 'Start date' },
  { key: 'endDate', header: 'End date' },
  { key: 'approvalState', header: 'Approval state' }
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch
} = DataTable;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function ApprovalsPage({ history }) {
  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  
  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  const managerId = jwt.id;

  var breadcrumb = "My approvals";
  
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [start, setStart] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [allItems, setAllItems] = useState([]);
  const [approvalStates, setApprovalStates] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();

  const [modalAppOpen, setModalAppOpen] = useState(false);
  const [modalAppMessage, setModalAppMessage] = useState();

  const [formErrors, setFormErrors] = useState({});
  const [notes, setNotes] = useState('');
  const [userID, setUserID] = useState('');
  const [viewOnly, setViewOnly] = useState(true);

  const [displayName, setDisplayName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [actionedBy, setActionedBy] = useState('');
  const [state, setState] = useState('');

  const dOptions = { year: 'numeric', month: 'long', day: 'numeric' };


  const {
    handleSubmit
  } = useForm({});



  function UMTComponent(props) {
    if (approvalStates[props.row.id] === "Unapproved") {
      return (
        <OverflowMenu flipped>
          <OverflowMenuItem itemText="Approve/Deny" hasDivider onClick={() => { handleModal(false, props.row.id, props.row); }} />
        </OverflowMenu>
      )
    }
  
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem itemText="View details" hasDivider onClick={() => { handleModal(true, props.row.id, props.row); }} />
      </OverflowMenu>
    )
  }

  /**
   * Load data
   */
  function loadData() {
    var apiCall = apiuri + '/user/findApprovalsByUserType';

    axios
    .get(apiCall, { headers: httpHeaders })
    .then((response) => {
      var allItems = [];
      var payLoad = [];
      var aStates =[];
      response.data.forEach((element) => {
        element.id = element._id;

        if (element.displayName) {
          element.beneficiary = element.displayName;
        } else {
          if (element.name.givenName) {
            element.beneficiary = element.name.givenName + " ";
          }
          if (element.name.familyName) {
            element.beneficiary += element.name.familyName;
          }
        }
        if (element.beneficiary.length > 64) {
          element.beneficiary = element.beneficiary.substring(0,60) + "...";
        }

        if (element.thirdParty) {
          if (element.thirdParty.name) {
            element.thirdParty = element.thirdParty.name;
          } else {
            element.thirdParty = "";
          }
        } else {
          element.thirdParty = "";
        }

        if (element.createdBy) {
          if (element.createdBy.displayName) {
            element.requestor = element.createdBy.displayName;
          } else {
            if (element.name.givenName) {
              element.requestor = element.createdBy.name.givenName + " ";
            }
            if (element.name.familyName) {
              element.requestor += element.createdBy.name.familyName;
            }
          }
          if (element.requestor.length > 64) {
            element.requestor = element.requestor.substring(0,60) + "...";
          }
        }

        if (element.umt.startDate) {
          element.startDate = new Date(element.umt.startDate).toLocaleDateString(undefined, dOptions);
        } else {
          element.startDate = "n/a";
        }
        if (element.umt.endDate) {
          element.endDate = new Date(element.umt.endDate).toLocaleDateString(undefined, dOptions);
        } else {
          element.endDate = "n/a";
        }

        aStates[element.id] = "";
        if (element.umt.approvalState) {
          if (element.umt.approvalState === "Approved") {
            element.approvalState = <Tag type='green'>{element.umt.approvalState}</Tag>;
            aStates[element.id] = "Approved";
          } else {
            if (element.approvalState === "Unapproved") {
              element.approvalState = <Tag type='gray'>{element.umt.approvalState}</Tag>;
              aStates[element.id] = "Unapproved";
            } else {
              element.approvalState = <Tag type='red'>{element.umt.approvalState}</Tag>;
              aStates[element.id] = element.umt.approvalState;
            }
          }
        }

        payLoad.push(element);
        allItems[element.id] = element;
      }) // end of forEach

      setAllItems(allItems);
      setRows(payLoad);
      setAllRows(payLoad);
      setApprovalStates(aStates);
      setTotalItems(response.data.length);
    }) // end of THEN
    .catch((error) => {
      setModalMessage('An error has occurred retrieving the list of approvals.');
      if (error.response) {
        if (error.response.status === 401) {
          reauth();
        }
        if (error.response.status === 403) {
          setModalMessage('You are not permitted to perform that operation');
        } else {
          if (error.response.data.message) {
            setModalMessage('An error has occurred retrieving the list of approvals: ' + error.response.data.message);
          }
        }
      }

      setModalType("danger");
      setModalOpen(true);
    }); // end of catch
  } // end of loadData



  function handleFilter(filter) {
    if (filter === "") {
      setRows(allRows);
      setTotalItems(allRows.length);
      setStart(0);
    } else {
      var newRows = [];
      allRows.forEach((element) => {
        if (JSON.stringify(element).toLowerCase().includes(filter.toLowerCase())) {
          newRows.push(element);
        }
      });
      setStart(0);
      setRows(newRows);
      setTotalItems(newRows.length);
    }
  }



  /**
   * Handle approve/deny
   */
  function handleApproval(state) {
    let errorList = [];
    let formIsValid = true;
    if (notes === '' && (!(state))) {
      errorList.notes = 'Notes cannot be empty when rejecting an approval';
      formIsValid = false;
    }

    if (!formIsValid) {
      setFormErrors(errorList);
    } else {
      // Unset stuff
      setNotes('');
      setStartDate('');
      setEndDate('');
      setDisplayName('');
      setActionedBy('');
      setState('');

      var payLoad = {
        id: userID,
        notes: notes,
        actionedBy: managerId,
        actionedDate: new Date()
      };
      if (state) {
        payLoad.approvalState = "Approved";
      } else {
        payLoad.approvalState = "Denied";
      }
      axios.put(apiuri + '/user/approval', payLoad, { headers: httpHeaders })
      .then((response) => {
        setModalAppOpen(false);
        loadData();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalAppMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalAppMessage('An error has occurred: ' + error.response.data.message);
            }
          }
        }
        setFormErrors(errorList);
      });
    }
  }



  /**
   * Handle modal operations
   */
  function handleModal(vo, id) {
    setUserID(id);

    var obj = allItems[id];
    if (obj.umt.startDate) {
      setStartDate(new Date(obj.umt.startDate).toLocaleDateString(undefined, dOptions));
    } else {
      setStartDate("n/a");
    }
    if (obj.umt.endDate) {
      setEndDate(new Date(obj.umt.endDate).toLocaleDateString(undefined, dOptions));
    } else {
      setEndDate("n/a");
    }

    setState(obj.umt.approvalState);

    setNotes(obj.umt.notes);
    setDisplayName(obj.displayName);
    if (obj.actionedBy) {
      if (obj.actionedBy.displayName) {
        setActionedBy(obj.actionedBy.displayName);
      }
    }
    setViewOnly(vo);
    setModalAppOpen(true);
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/approvals">Approvals</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">{breadcrumb}</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows.slice(
                  firstRowIndex,
                  firstRowIndex + currentPageSize
                )}
                headers={headers}
                isSortable
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getToolbarProps,
                  onInputChange
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={(e) => { handleFilter(e.target.value)}} />
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                              <UMTComponent
                                row={row}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                        totalItems={totalItems}
                        backwardText="Previous page"
                        forwardText="Next page"
                        page={Math.floor(start / currentPageSize) +1}
                        pageSize={currentPageSize}
                        pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                        itemsPerPageText="Items per page"
                        onChange={({ page, pageSize }) => {
                            if (totalItems === 0 || start < totalItems) {
                                // we should be grand
                            } else {
                                setStart(Math.max(start - (Math.floor((page - totalItems / pageSize) + 1) * pageSize), 0));
                            }
                            if (pageSize !== currentPageSize) {
                                setCurrentPageSize(pageSize);
                            }
                            setFirstRowIndex(pageSize * (page - 1));
                            }
                        }
                    />
                  </TableContainer>
                )}
              />
            </div>
          </div>
        </div>
      </Content>

      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Approvals management</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }} >OK</Button>
          </ModalFooter>
        </ComposedModal>
      ) : null }

      <ComposedModal open={modalAppOpen} onClose={() => setModalAppOpen(false)} size="sm">
        <ModalHeader>
          <h3 className="umt--heading">Approve/Deny</h3>
        </ModalHeader>
        <Form onSubmit={handleSubmit(handleApproval)}>
          <ModalBody>
            <p>
              <strong>Name</strong>: {displayName}<br />
              <strong>Start Date</strong>: {startDate}<br />
              <strong>End Date</strong>: {endDate}<br />
              <strong></strong>
            </p>
            {modalAppMessage
            ?
              <>
                <p className=".bx--modal-content__text"><br /><strong>{modalAppMessage}</strong></p>
              </>
            :
              null
            }
            {viewOnly
            ?
              <>
                <p>
                  <strong>Approval state</strong>: {state}<br />
                  <strong>Actioned by</strong>: {actionedBy}<br />
                  <strong>Notes</strong>: {notes}
                </p>
              </>
            :
              <>
                <span style={{ color: 'red' }}>{formErrors['notes']}</span>
                <div style={{ marginBottom: '2rem' }}>
                  <TextInput
                    value={notes}
                    id="notes"
                    labelText="Notes"
                    onChange={(e) => { setFormErrors([]); setNotes(e.target.value)}}
                  />
                </div>
              </>
            }
          </ModalBody>
          <ModalFooter>
            {viewOnly
            ?
              <>
                <Button kind="secondary" onClick={() => { setModalAppOpen(false); }} >Cancel</Button>
              </>
            :
              <>
                <Button kind="primary" type="submit" onClick={() => { handleApproval(true); }} >Approve</Button>
                <Button kind="danger" onClick={() => { handleApproval(false); }} >Deny</Button>
              </>
            }
          </ModalFooter>
        </Form>
      </ComposedModal>
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Content,
  Tile,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function HomePage({ homepage }) {
  const [totalThirdParties, setTotalThirdParties] = useState('');
  const [totalUsers, setTotalUsers] = useState('');
  const [newUsers, setNewUsers] = useState('');
  const [expiringUsers, setExpiringUsers] = useState('');



  /**
   *  Load data
   */
  useEffect(() => {
    axios
      .get(apiuri + '/thirdparty/all', { headers: httpHeaders })
      .then((response) => {
        setTotalThirdParties(response.data.length);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
        }
        setTotalThirdParties('Unknown');
      });
      axios
      .get(apiuri + '/user/all?pageSize=1&nextItem=undefined&prevItem=undefined&direction=undefined', { headers: httpHeaders })
      .then((response) => {
        setTotalUsers(response.data.count);
        setNewUsers('0');
        setExpiringUsers('0');
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
        }
        setTotalUsers('Unknown');
        setNewUsers('Unknown');
        setExpiringUsers('Unknown');
      });
  }, []);


  /**
   * Return details
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content>
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
        </Breadcrumb>
        <h3 className="umt--heading">Organisation management</h3>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <Tile>
                <h3 className="umt--tile-header">Third party organisations</h3>
                <p className="umt--tile-value">{totalThirdParties}</p>
              </Tile>
            </div>
            <div className="bx--col">
              <Tile>
                <h3 className="umt--tile-header">
                  Total users
                </h3>
                <p className="umt--tile-value">{totalUsers}</p>
              </Tile>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

/**
 * Country information sourced from https://gist.github.com/tadast/8827699
 */

// Import core modules
import React from 'react';

// Import elements
import {
  SelectItem
} from 'carbon-components-react';



/**
 * Core function
 */
export default function SnipCountry() {
  return (
    <>
      <SelectItem text="N/A" value="" />
      <SelectItem text="Afghanistan" value="AF" />
      <SelectItem text="Albania" value="AL" />
      <SelectItem text="Algeria" value="DZ" />
      <SelectItem text="American Samoa" value="AS" />
      <SelectItem text="Andorra" value="AD" />
      <SelectItem text="Angola" value="AO" />
      <SelectItem text="Anguilla" value="AI" />
      <SelectItem text="Antarctica" value="AQ" />
      <SelectItem text="Antigua and Barbuda" value="AG" />
      <SelectItem text="Argentina" value="AR" />
      <SelectItem text="Armenia" value="AM" />
      <SelectItem text="Aruba" value="AW" />
      <SelectItem text="Australia" value="AU" />
      <SelectItem text="Austria" value="AT" />
      <SelectItem text="Azerbaijan" value="AZ" />
      <SelectItem text="Bahamas" value="BS" />
      <SelectItem text="Bahrain" value="BH" />
      <SelectItem text="Bangladesh" value="BD" />
      <SelectItem text="Barbados" value="BB" />
      <SelectItem text="Belarus" value="BY" />
      <SelectItem text="Belgium" value="BE" />
      <SelectItem text="Belize" value="BZ" />
      <SelectItem text="Benin" value="BJ" />
      <SelectItem text="Bermuda" value="BM" />
      <SelectItem text="Bhutan" value="BT" />
      <SelectItem text="Bolivia" value="BO" />
      <SelectItem text="Bolivia, Plurinational State of" value="BO" />
      <SelectItem text="Bosnia and Herzegovina" value="BA" />
      <SelectItem text="Botswana" value="BW" />
      <SelectItem text="Bouvet Island" value="BV" />
      <SelectItem text="Brazil" value="BR" />
      <SelectItem text="British Indian Ocean Territory" value="IO" />
      <SelectItem text="Brunei" value="BN" />
      <SelectItem text="Brunei Darussalam" value="BN" />
      <SelectItem text="Bulgaria" value="BG" />
      <SelectItem text="Burkina Faso" value="BF" />
      <SelectItem text="Burma" value="MM" />
      <SelectItem text="Burundi" value="BI" />
      <SelectItem text="CÃ´te d'Ivoire" value="CI" />
      <SelectItem text="Cambodia" value="KH" />
      <SelectItem text="Cameroon" value="CM" />
      <SelectItem text="Canada" value="CA" />
      <SelectItem text="Cape Verde" value="CV" />
      <SelectItem text="Cayman Islands" value="KY" />
      <SelectItem text="Central African Republic" value="CF" />
      <SelectItem text="Chad" value="TD" />
      <SelectItem text="Chile" value="CL" />
      <SelectItem text="China" value="CN" />
      <SelectItem text="Christmas Island" value="CX" />
      <SelectItem text="Cocos (Keeling) Islands" value="CC" />
      <SelectItem text="Colombia" value="CO" />
      <SelectItem text="Comoros" value="KM" />
      <SelectItem text="Congo" value="CG" />
      <SelectItem text="Congo, the Democratic Republic of the" value="CD" />
      <SelectItem text="Cook Islands" value="CK" />
      <SelectItem text="Costa Rica" value="CR" />
      <SelectItem text="Croatia" value="HR" />
      <SelectItem text="Cuba" value="CU" />
      <SelectItem text="Cyprus" value="CY" />
      <SelectItem text="Czech Republic" value="CZ" />
      <SelectItem text="Denmark" value="DK" />
      <SelectItem text="Djibouti" value="DJ" />
      <SelectItem text="Dominica" value="DM" />
      <SelectItem text="Dominican Republic" value="DO" />
      <SelectItem text="Ecuador" value="EC" />
      <SelectItem text="Egypt" value="EG" />
      <SelectItem text="El Salvador" value="SV" />
      <SelectItem text="Equatorial Guinea" value="GQ" />
      <SelectItem text="Eritrea" value="ER" />
      <SelectItem text="Estonia" value="EE" />
      <SelectItem text="Ethiopia" value="ET" />
      <SelectItem text="Falkland Islands (Malvinas)" value="FK" />
      <SelectItem text="Faroe Islands" value="FO" />
      <SelectItem text="Fiji" value="FJ" />
      <SelectItem text="Finland" value="FI" />
      <SelectItem text="France" value="FR" />
      <SelectItem text="French Guiana" value="GF" />
      <SelectItem text="French Polynesia" value="PF" />
      <SelectItem text="French Southern Territories" value="TF" />
      <SelectItem text="Gabon" value="GA" />
      <SelectItem text="Gambia" value="GM" />
      <SelectItem text="Georgia" value="GE" />
      <SelectItem text="Germany" value="DE" />
      <SelectItem text="Ghana" value="GH" />
      <SelectItem text="Gibraltar" value="GI" />
      <SelectItem text="Greece" value="GR" />
      <SelectItem text="Greenland" value="GL" />
      <SelectItem text="Grenada" value="GD" />
      <SelectItem text="Guadeloupe" value="GP" />
      <SelectItem text="Guam" value="GU" />
      <SelectItem text="Guatemala" value="GT" />
      <SelectItem text="Guernsey" value="GG" />
      <SelectItem text="Guinea" value="GN" />
      <SelectItem text="Guinea-Bissau" value="GW" />
      <SelectItem text="Guyana" value="GY" />
      <SelectItem text="Haiti" value="HT" />
      <SelectItem text="Heard Island and McDonald Islands" value="HM" />
      <SelectItem text="Holy See (Vatican City State)" value="VA" />
      <SelectItem text="Honduras" value="HN" />
      <SelectItem text="Hong Kong" value="HK" />
      <SelectItem text="Hungary" value="HU" />
      <SelectItem text="Iceland" value="IS" />
      <SelectItem text="India" value="IN" />
      <SelectItem text="Indonesia" value="ID" />
      <SelectItem text="Iran, Islamic Republic of" value="IR" />
      <SelectItem text="Iraq" value="IQ" />
      <SelectItem text="Ireland" value="IE" />
      <SelectItem text="Isle of Man" value="IM" />
      <SelectItem text="Israel" value="IL" />
      <SelectItem text="Italy" value="IT" />
      <SelectItem text="Ivory Coast" value="CI" />
      <SelectItem text="Jamaica" value="JM" />
      <SelectItem text="Japan" value="JP" />
      <SelectItem text="Jersey" value="JE" />
      <SelectItem text="Jordan" value="JO" />
      <SelectItem text="Kazakhstan" value="KZ" />
      <SelectItem text="Kenya" value="KE" />
      <SelectItem text="Kiribati" value="KI" />
      <SelectItem text="Korea, Democratic People's Republic of" value="KP" />
      <SelectItem text="Korea, Republic of" value="KR" />
      <SelectItem text="Kuwait" value="KW" />
      <SelectItem text="Kyrgyzstan" value="KG" />
      <SelectItem text="Lao People's Democratic Republic" value="LA" />
      <SelectItem text="Latvia" value="LV" />
      <SelectItem text="Lebanon" value="LB" />
      <SelectItem text="Lesotho" value="LS" />
      <SelectItem text="Liberia" value="LR" />
      <SelectItem text="Libya" value="LY" />
      <SelectItem text="Libyan Arab Jamahiriya" value="LY" />
      <SelectItem text="Liechtenstein" value="LI" />
      <SelectItem text="Lithuania" value="LT" />
      <SelectItem text="Luxembourg" value="LU" />
      <SelectItem text="Macao" value="MO" />
      <SelectItem text="Macedonia, the former Yugoslav Republic of" value="MK" />
      <SelectItem text="Madagascar" value="MG" />
      <SelectItem text="Malawi" value="MW" />
      <SelectItem text="Malaysia" value="MY" />
      <SelectItem text="Maldives" value="MV" />
      <SelectItem text="Mali" value="ML" />
      <SelectItem text="Malta" value="MT" />
      <SelectItem text="Marshall Islands" value="MH" />
      <SelectItem text="Martinique" value="MQ" />
      <SelectItem text="Mauritania" value="MR" />
      <SelectItem text="Mauritius" value="MU" />
      <SelectItem text="Mayotte" value="YT" />
      <SelectItem text="Mexico" value="MX" />
      <SelectItem text="Micronesia, Federated States of" value="FM" />
      <SelectItem text="Moldova, Republic of" value="MD" />
      <SelectItem text="Monaco" value="MC" />
      <SelectItem text="Mongolia" value="MN" />
      <SelectItem text="Montenegro" value="ME" />
      <SelectItem text="Montserrat" value="MS" />
      <SelectItem text="Morocco" value="MA" />
      <SelectItem text="Mozambique" value="MZ" />
      <SelectItem text="Myanmar" value="MM" />
      <SelectItem text="Namibia" value="NA" />
      <SelectItem text="Nauru" value="NR" />
      <SelectItem text="Nepal" value="NP" />
      <SelectItem text="Netherlands" value="NL" />
      <SelectItem text="Netherlands Antilles" value="AN" />
      <SelectItem text="New Caledonia" value="NC" />
      <SelectItem text="New Zealand" value="NZ" />
      <SelectItem text="Nicaragua" value="NI" />
      <SelectItem text="Niger" value="NE" />
      <SelectItem text="Nigeria" value="NG" />
      <SelectItem text="Niue" value="NU" />
      <SelectItem text="Norfolk Island" value="NF" />
      <SelectItem text="Northern Mariana Islands" value="MP" />
      <SelectItem text="Norway" value="NO" />
      <SelectItem text="Oman" value="OM" />
      <SelectItem text="Pakistan" value="PK" />
      <SelectItem text="Palau" value="PW" />
      <SelectItem text="Palestinian Territory, Occupied" value="PS" />
      <SelectItem text="Panama" value="PA" />
      <SelectItem text="Papua New Guinea" value="PG" />
      <SelectItem text="Paraguay" value="PY" />
      <SelectItem text="Peru" value="PE" />
      <SelectItem text="Philippines" value="PH" />
      <SelectItem text="Pitcairn" value="PN" />
      <SelectItem text="Poland" value="PL" />
      <SelectItem text="Portugal" value="PT" />
      <SelectItem text="Puerto Rico" value="PR" />
      <SelectItem text="Qatar" value="QA" />
      <SelectItem text="RÃ©union" value="RE" />
      <SelectItem text="Romania" value="RO" />
      <SelectItem text="Russia" value="RU" />
      <SelectItem text="Russian Federation" value="RU" />
      <SelectItem text="Rwanda" value="RW" />
      <SelectItem text="Saint Helena, Ascension and Tristan da Cunha" value="SH" />
      <SelectItem text="Saint Kitts and Nevis" value="KN" />
      <SelectItem text="Saint Lucia" value="LC" />
      <SelectItem text="Saint Pierre and Miquelon" value="PM" />
      <SelectItem text="Saint Vincent & the Grenadines" value="VC" />
      <SelectItem text="Saint Vincent and the Grenadines" value="VC" />
      <SelectItem text="Samoa" value="WS" />
      <SelectItem text="San Marino" value="SM" />
      <SelectItem text="Sao Tome and Principe" value="ST" />
      <SelectItem text="Saudi Arabia" value="SA" />
      <SelectItem text="Senegal" value="SN" />
      <SelectItem text="Serbia" value="RS" />
      <SelectItem text="Seychelles" value="SC" />
      <SelectItem text="Sierra Leone" value="SL" />
      <SelectItem text="Singapore" value="SG" />
      <SelectItem text="Slovakia" value="SK" />
      <SelectItem text="Slovenia" value="SI" />
      <SelectItem text="Solomon Islands" value="SB" />
      <SelectItem text="Somalia" value="SO" />
      <SelectItem text="South Africa" value="ZA" />
      <SelectItem text="South Georgia and the South Sandwich Islands" value="GS" />
      <SelectItem text="South Korea" value="KR" />
      <SelectItem text="South Sudan" value="SS" />
      <SelectItem text="Spain" value="ES" />
      <SelectItem text="Sri Lanka" value="LK" />
      <SelectItem text="St. Vincent and the Grenadines" value="VC" />
      <SelectItem text="Sudan" value="SD" />
      <SelectItem text="Suriname" value="SR" />
      <SelectItem text="Svalbard and Jan Mayen" value="SJ" />
      <SelectItem text="Swaziland" value="SZ" />
      <SelectItem text="Sweden" value="SE" />
      <SelectItem text="Switzerland" value="CH" />
      <SelectItem text="Syrian Arab Republic" value="SY" />
      <SelectItem text="Taiwan" value="TW" />
      <SelectItem text="Taiwan, Province of China" value="TW" />
      <SelectItem text="Tajikistan" value="TJ" />
      <SelectItem text="Tanzania, United Republic of" value="TZ" />
      <SelectItem text="Thailand" value="TH" />
      <SelectItem text="Timor-Leste" value="TL" />
      <SelectItem text="Togo" value="TG" />
      <SelectItem text="Tokelau" value="TK" />
      <SelectItem text="Tonga" value="TO" />
      <SelectItem text="Trinidad and Tobago" value="TT" />
      <SelectItem text="Tunisia" value="TN" />
      <SelectItem text="Turkey" value="TR" />
      <SelectItem text="Turkmenistan" value="TM" />
      <SelectItem text="Turks and Caicos Islands" value="TC" />
      <SelectItem text="Tuvalu" value="TV" />
      <SelectItem text="Uganda" value="UG" />
      <SelectItem text="Ukraine" value="UA" />
      <SelectItem text="United Arab Emirates" value="AE" />
      <SelectItem text="United Kingdom" value="GB" />
      <SelectItem text="United States" value="US" />
      <SelectItem text="United States Minor Outlying Islands" value="UM" />
      <SelectItem text="Uruguay" value="UY" />
      <SelectItem text="Uzbekistan" value="UZ" />
      <SelectItem text="Vanuatu" value="VU" />
      <SelectItem text="Venezuela" value="VE" />
      <SelectItem text="Venezuela, Bolivarian Republic of" value="VE" />
      <SelectItem text="Viet Nam" value="VN" />
      <SelectItem text="Vietnam" value="VN" />
      <SelectItem text="Virgin Islands, British" value="VG" />
      <SelectItem text="Virgin Islands, U.S." value="VI" />
      <SelectItem text="Wallis and Futuna" value="WF" />
      <SelectItem text="Western Sahara" value="EH" />
      <SelectItem text="Yemen" value="YE" />
      <SelectItem text="Zambia" value="ZM" />
      <SelectItem text="Zimbabwe" value="ZW" />
    </>
  );
}

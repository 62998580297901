// Import core modules
import { useContext, createContext } from 'react';

/**
 * Export AppContext
 */
export const AppContext = createContext(null);

/**
 * AppContext core function
 */
export function useAppContext() {
  return useContext(AppContext);
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  DataTable,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
} from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

const headers = [
  {
    key: 'report',
    header: 'Report name'
  },
  {
    key: 'startDate',
    header: 'Report date'
  },
  {
    key: 'frequency',
    header: 'Frequency'
  },
  {
    key: 'nextExecutionDate',
    header: 'Next execution date'
  },
  {
    key: 'generationTime',
    header: 'Next execution time'
  },
  {
    key: 'format',
    header: 'Report format'
  }
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions,
} = DataTable;



/**
 * Core function
 */
export default function Download({ history }) {
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const dOptions = { year: 'numeric', month: 'long', day: 'numeric' };



  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }



  /**
   * Load data
   */
  function loadData() {
    axios.get(apiuri + '/report/all', { headers: httpHeaders })
      .then((response) => {
        var reports = [];
        response.data.forEach((element) => {
          reports[element.id.toString()] = element.name;
        });

        var jwt = decodeJWT(localStorage.getItem('accessToken'));
        var creator = jwt.id;
        axios.get(apiuri + '/reportschedule/findByCreator?creator=' + creator, { headers: httpHeaders })
          .then((response) => {
            // Transform the report ID into a report name - for readability purposes
            var payLoad = [];
            response.data.forEach((element) => {
              if (element.report) {
                element.reportID = element.report;
                element.report = reports[element.report.toString()];
              }
              if (element.startDate) {
                element.startDate = new Date(element.startDate).toLocaleDateString(undefined, dOptions);
              }
              if (element.nextExecutionDate) {
                element.nextExecutionDate = new Date(element.nextExecutionDate).toLocaleDateString(undefined, dOptions);
              }
              if (element.frequency) {
                switch (element.frequency.toString()) {
                  case "1": element.frequency = "One off"; break;
                  case "2": element.frequency = "Daily"; break;
                  case "3": element.frequency = "Weekly"; break;
                  case "4": element.frequency = "Monthly"; break;
                  case "5": element.frequency = "Quarterly"; break;
                  case "6": element.frequency = "Bi-annually"; break;
                  case "7": element.frequency = "Annually"; break;
                  default: break;
                }
              }
              payLoad.push(element);
            });
            setRows(payLoad);
            setTotalItems(response.data.length);
          });
      });
  }



  /**
   * Handle delete
   */
  function handleDelete(id) {
    axios
      .delete(apiuri + '/reportschedule/' + id, { headers: httpHeaders })
      .then((response) => { loadData(); })
      .catch((error) => {
        setModalMessage('An error has occurred deleting the report schedule.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred deleting the report schedule: ' + error.response.data.message);
            }
          }
        }
        setModalOpen(true);
      })
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Return rendered content
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/reports">Reports</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">My scheduled reports</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows.slice(
                  firstRowIndex,
                  firstRowIndex + currentPageSize
                )}
                headers={headers}
                isSortable
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getSelectionProps,
                  getToolbarProps,
                  getBatchActionProps,
                  onInputChange,
                  selectedRows,
                  getTableContainerProps,
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableBatchActions {...getBatchActionProps()}>
                        <TableBatchAction renderIcon={TrashCan16}>
                          Delete
                        </TableBatchAction>
                      </TableBatchActions>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={onInputChange} />
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll {...getSelectionProps()} />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableSelectRow {...getSelectionProps({ row })} />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                              <OverflowMenu flipped>
                                <OverflowMenuItem itemText="Delete" isDelete onClick={() => { handleDelete(row.id) }} />
                              </OverflowMenu>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                itemsPerPageText="Items per page"
                onChange={({ page, pageSize }) => {
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                  }
                  setFirstRowIndex(pageSize * (page - 1));
                }}
              />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          size="sm"
        >
          <ModalHeader>
            <h3 className="umt--heading">My scheduled reports</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind="danger" onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Content,
  DataTable,
  Pagination,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
} = DataTable;

const headers = [
  { key: 'eventDate', header: 'Date/time' },
  { key: 'eventType', header: 'Event type' },
  { key: 'requestor', header: 'Requestor' },
];

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function AuditLogPage({ history }) {
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [start, setStart] = useState(0);
  const [prevItem, setPrevItem] = useState();
  const [nextItem, setNextItem] = useState();
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [direction, setDirection] = useState();
  const [rowData, setRowData] = useState([]);
  const [allRows, setAllRows] = useState([]);

  const dOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };



  function handleFilter(filter) {
    if (filter === "") {
      setRowData(allRows);
      setTotalItems(allRows.length);
    } else {
      var newRows = [];
      allRows.forEach((element) => {
        if (JSON.stringify(element).toLowerCase().includes(filter.toLowerCase())) {
          newRows.push(element);
        }
      });
      setRowData(newRows);
      setTotalItems(newRows.length);
    }
  }



  /**
   * Load Data
   */
  function loadData() {
    var apiCall = apiuri + '/auditlog/all?expanded=true&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
    axios
    .get(apiCall, { headers: httpHeaders })
    .then((response) => {
      setPrevItem(response.data.prevItem);
      setNextItem(response.data.nextItem);
      setPrevPage(page);

      var payLoad = [];

      response.data.items.forEach((element) => {
        element.id = element._id;
        if (element.requestor != null) {
          if (element.requestorDetails) {
            element.requestor = element.requestorDetails.displayName;
          } else {
            element.requestor = "SYSTEM";
          }
        } else {
          element.requestor = "SYSTEM";
        }

        // Transform the date to something consumable
        if (element.eventDate) {
          element.eventDate = new Date(element.eventDate).toLocaleDateString(
            undefined,
            dOptions
          );
        }

        payLoad.push(element);
      })

      setRowData(payLoad);
      setAllRows(payLoad);
      setTotalItems(response.data.count);
    })
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, page]);



  /**
   * Return details
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">Audit log</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rowData}
                headers={headers}
                isSortable
                render={({ rows, headers, getHeaderProps, getTableProps, getRowProps }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar>
                      <TableToolbarContent>
                        <TableToolbarSearch defaultExpanded persistent="true" placeHolderText="Search audit items" onChange={(e) => { handleFilter(e.target.value)}} />
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <TableExpandHeader />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => {
                          const rowValues = rowData.find(item => item.id === row.id);
                          return (
                            <React.Fragment key={row.id}>
                              <TableExpandRow {...getRowProps({ row })}>
                                {row.cells.map((cell) => (
                                  <TableCell key={cell.id}>{cell.value}</TableCell>
                                ))}
                              </TableExpandRow>
                              {row.isExpanded && (
                                <TableExpandedRow colSpan={headers.length + 1}>
                                  {rowValues.payload}
                                </TableExpandedRow>
                              )}
                            </React.Fragment>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                page={Math.floor(start / currentPageSize) + 1}
                pageSize={currentPageSize}
                pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                itemsPerPageText="Items per page"
                // pageInputDisabled={true}
                onChange={({ page, pageSize }) => {
                  if (totalItems === 0 || start < totalItems) {
                    // we should be grand
                  } else {
                    setStart(Math.max(start - (Math.floor((page - totalItems / pageSize) + 1) * pageSize), 0));
                  }
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                    setDirection(undefined);
                  } else if (page > prevPage) {
                    setDirection('up');
                  } else if (page < prevPage) {
                    setDirection('down');
                  }
                  setFirstRowIndex(pageSize * (page - 1));
                  setPage(page);
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import fileDownload from 'js-file-download';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Content,
  DataTable,
  Pagination,
} from 'carbon-components-react';
import { Save16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

const headers = [
  {
    key: 'report',
    header: 'Report name',
  },
  {
    key: 'createdAt',
    header: 'Report date',
  },
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
} = DataTable;

/**
 * Core function
 */
export default function Download({ history }) {
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const dOptions = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };



  function handleDownload(id) {
    var fileName = "";
    rows.forEach((element => {
      if (element.id === id) {
        fileName = element.fileName;
      }
    }))
    var url = process.env.REACT_APP_S3BUCKET + fileName;
    axios.get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, fileName, "text/csv; charset=utf-8");
    })
  }
  


  useEffect(() => {
    axios.get(apiuri + '/report/all', { headers: httpHeaders })
      .then((response) => {
        var reports = [];
        response.data.forEach((element) => {
            reports[element.id.toString()] = element.name;
        });

        axios.get(apiuri + '/reportdownload/all', { headers: httpHeaders })
          .then((response) => {
            // Transform the report ID into a report name - for readability purposes
            var payLoad = [];
            var keys = [];
            response.data.forEach((element) => {
              if (element.report) {
                element.report = reports[element.report.toString()];
              }
              if (element.createdAt) {
                element.createdAt = new Date(element.createdAt).toLocaleDateString(
                  undefined,
                  dOptions
                );
              }
              
              payLoad.push(element);
            });

            setRows(payLoad);
            setTotalItems(response.data.length);
          });
      });
  }, []);



  /**
   * Return rendered content
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/reports">Reports</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">Download a report</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
            <DataTable
                rows={rows.slice(
                  firstRowIndex,
                  firstRowIndex + currentPageSize
                )}
                headers={headers}
                isSortable
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getToolbarProps,
                  onInputChange,
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={onInputChange} />
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table className="umt--table" {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-cell">
                              <Button
                                size="small"
                                kind="ghost"
                                renderIcon={Save16}
                                hasIconOnly
                                tooltipAlignment="end"
                                iconDescription="Download"
                                onClick={() => {
                                    handleDownload(row.id);
                                  }
                                }
                              ></Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                className="umt--pagination"
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                itemsPerPageText="Items per page"
                onChange={({ page, pageSize }) => {
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                  }
                  setFirstRowIndex(pageSize * (page - 1));
                }}
              />
            </div>
          </div>
        </div>
      </Content>
    </>
  );
}

// Import core modules
import React from 'react';

// Import elements
import {
  HeaderContainer,
  SideNav,
  SideNavItems,
  SideNavLink
} from 'carbon-components-react';

/**
 * Core function
 */
export default function UserNav() {
  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <SideNav
              isFixedNav
              expanded={true}
              isChildOfHeader={false}
              aria-label="User navigation"
              className="umt--sidenav"
            >
              <SideNavItems className="umt--sidenavitems">
                <SideNavLink className="umt--sidenavlink" href="/approvals">
                  My approvals
                </SideNavLink>
              </SideNavItems>
            </SideNav>
          </>
        )}
      />
    </>
  );
}
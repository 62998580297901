import { TokenRenewMode } from '@axa-fr/react-oidc';
import axios from 'axios';

let clientId;
let clientUrl;
let configId;
let oidcEnabled;
let oidcConfigured = false;

async function getSettings() {
  const apiuri = process.env.REACT_APP_APIURI;
  const httpHeaders = {
    'Content-Type': 'application/json',
  };

  var settings = await axios.get(apiuri + '/settings/oidc', { headers: httpHeaders });
  clientId = settings.data[0].oidc.clientID;
  clientUrl = settings.data[0].oidc.clientURL;
  oidcEnabled = settings.data[0].oidc.enabled;
}

try {
  await getSettings();

  if (oidcEnabled === true && typeof clientId !== 'undefined' && typeof clientUrl !== 'undefined') {
    oidcConfigured = true;
    configId = {
      client_id: clientId,
      redirect_uri: window.location.origin + '/authentication/callback',
      silent_redirect_uri: window.location.origin + '/authentication/silent-callback',
      scope: 'openid profile email',
      authority: clientUrl,
      refresh_time_before_tokens_expiration_in_second: 40,
      service_worker_relative_url: '/OidcServiceWorker.js',
      service_worker_only: false,
      extras: { youhou_demo: 'youhou' },
      token_renew_mode: TokenRenewMode.access_token_invalid,
      demonstrating_proof_of_possession: false,
    };
  } else {
    configId = {  };
  }
} catch (err) {
  console.log(err);
  configId = { error: true };
}

export const configurationIdentityServer = configId;
export const oidcConfig = oidcConfigured;

// Import core modules
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Import libraries
import { useAppContext } from '../../lib/context';

// Import components
import UMTHeader from '../../components/Header/index.js';

// Import elements
import {
  Button,
  Content,
  Form,
  TextInput,
  ButtonSet,
  Link,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

/**
 * Core function
 */
export default function Landing({ history }) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { isAuthenticated, setIsAuthenticated } = useAppContext();
  const [otp, setOtp] = useState('');
  const [isPasswordLogin, setIsPasswordLogin] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({});

  function handleLogin() {
    console.log("Logging in...");
    let errorList = [];
    let formIsValid = true;
    if (userName === '') {
      errorList.username = 'Username cannot be empty';
      formIsValid = false;
    }
    if (password === '') {
      errorList.password = 'Password cannot be empty';
      formIsValid = false;
    }

    if (!formIsValid) {
      console.log("form is invalid!");
      setFormErrors(errorList);
    } else {
      var payLoad = {
        userName: userName,
        password: password
      };
      axios.post(apiuri + '/auth', payLoad).then((response) => {
        console.log("Logged in");
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem('accessToken', response.data.accessToken);
        setIsAuthenticated(true);

        window.location.assign("/users");
      })
      .catch((error) => {
        console.log("That didn't work");
        console.log(JSON.stringify(error, null, 2));
        setIsPasswordLogin(true);
        errorList.username = 'Invalid username/password combination';
        setFormErrors(errorList);
      });
    }
  }

  function handleEmailLogin() {
    console.log('Logging in with email...');
    let errorList = [];
    let formIsValid = true;
    if (email === '' || !email.includes('@')) {
      errorList.email = 'Enter a valid email';
      formIsValid = false;
    }

    if (!formIsValid) {
      console.log('form is invalid!');
      setFormErrors(errorList);
    } else {
      var payLoad = {
        email: email
      };
      axios.post(apiuri + '/otp', payLoad).then((response) => {
        setSentOtp(true);
      })
      .catch((error) => {
        console.log('error: ' + JSON.stringify(error, null, 2));
        errorList.email = 'Invalid email';
        setFormErrors(errorList);
      });
    }
  }

  function checkOtp() {
    console.log('We are checking the OTP!');
    var errorList = [];
    
    if (otp === '') {
      errorList.otp = 'Enter the one-time passcode'
      setFormErrors(errorList);
    } else {
      var payLoad = {
        code: otp
      };
      
      axios.put(apiuri + '/otp/login', payLoad).then((response) => {
        if (response.data.authenticated === true) {
          console.log('we are authenticated!');
          localStorage.setItem('isAuthenticated', true);
          localStorage.setItem('accessToken', response.data.accessToken);
          setIsAuthenticated(true);
          console.log('isAuth: ' + isAuthenticated);
          window.location.assign("/users");
        } else {
          errorList.otp = 'Code invalid';
          setFormErrors(errorList);
        }
      }).catch((error) => {
        console.log('error: ' + error.message);
        errorList.otp = 'Code is invalid';
        setFormErrors(errorList);
      });
    }
  }


  return (
    <>
      <UMTHeader />
      <Content className="landing-page__content">
        <div className="bx--grid bx--grid--full-width landing-page__grid">
          <div className="bx--row">
            <div className="bx--col-lg-6" />
            <div className="bx--col-lg-4">
              <img
                src={`${process.env.PUBLIC_URL}/logoname.png`}
                className="landing-page__image"
              />
              <br /> <br />
                {(!isPasswordLogin && !sentOtp)
                ?
                <>
                  <Form onSubmit={handleSubmit(handleEmailLogin)}>
                    <br />
                    <div>
                      <span style={{ color: 'red' }}>{formErrors['email']}</span>
                      <TextInput
                        data-modal-primary-focus
                        value={email}
                        id="email"
                        type="text"
                        labelText="Email"
                        onChange={(e) => { setFormErrors([]); setEmail(e.target.value)}}
                      />
                    </div>
                  </Form>
                  <ButtonSet className="landing-page__button-set" stacked>
                    <Button
                      className="landing-page__button"
                      kind="primary"
                      type="submit"
                    >
                      Continue with email
                    </Button>
                  </ButtonSet>
                  <br />
                  <hr />
                  <br />
                  <div>
                    <p className="landing-page__p">
                      Alternative logins
                    </p>
                    <ButtonSet className="landing-page__button-set" stacked>
                      <Button
                        className="landing-page__button"
                        kind="tertiary"
                        onClick={() => {setIsPasswordLogin(true)}}
                      >
                        Continue with password
                      </Button>
                    </ButtonSet>
                  </div>
                </>
                : (isPasswordLogin)
                ?
                <>
                  <Form onSubmit={handleSubmit(handleLogin)}>
                      <span style={{ color: 'red' }}>{formErrors['username']}</span>
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput
                          data-modal-primary-focus
                          value={userName}
                          id="username"
                          labelText="Username"
                          onChange={(e) => { setFormErrors([]); setUserName(e.target.value)}}
                        />
                      </div>
                      <span style={{ color: 'red' }}>{formErrors['password']}</span>
                      <div style={{ marginBottom: '2rem' }}>
                        <TextInput.PasswordInput
                          value={password}
                          id="password"
                          labelText="Password"
                          onChange={(e) => { setFormErrors([]); setPassword(e.target.value)}}
                        />
                      </div>
                      <div style={{ marginBottom: '2rem' }}>
                        <ButtonSet className="landing-page__button-set">
                          <Button
                            className="landing-page__button-login"
                            kind="secondary"
                            onClick={() => {
                              setFormErrors([]);
                              setUserName('');
                              setPassword('');
                              setIsPasswordLogin(false);
                            }}
                          >
                            Back
                          </Button>
                          <Button
                            className="landing-page__button-login"
                            kind="primary"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </ButtonSet>
                      </div>
                    </Form>
                </>
                : (sentOtp)
                ?
                <>
                  <Form onSubmit={handleSubmit(checkOtp)}>
                    <br />
                    <div>
                      An email has been sent to: <br /><br /> <strong>{email}</strong>
                    </div>
                    <br />
                    <span style={{ color: 'red' }}>{formErrors['otp']}</span>
                    <div style={{ marginBottom: '2rem' }}>
                      <TextInput.PasswordInput
                        data-modal-primary-focus
                        value={otp}
                        id="otp"
                        labelText="One-time passcode"
                        onChange={(e) => { setFormErrors([]); setOtp(e.target.value)}}
                      />
                    </div>
                    <div style={{ marginBottom: '2rem' }}>
                      <ButtonSet className="landing-page__button-set">
                        <Button
                          className="landing-page__button-login"
                          kind="secondary"
                          onClick={() => {
                            setFormErrors([]);
                            setSentOtp(false);
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          className="landing-page__button-login"
                          kind="primary"
                          type="submit"
                        >
                          Log in
                        </Button>

                      </ButtonSet>
                    </div>
                  </Form>
                </>
                :
                <></>
                }
            </div>
            <div className="bx--col-lg-6" />
          </div>
        </div>
      </Content>
      
    </>
  );
}

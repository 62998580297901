// Import core modules
import React from 'react';

// Import libraries
import { useAppContext } from '../../lib/context';

// Import components
import UMTHeaderLoggedIn from './HeaderLoggedIn';
import UMTHeaderNotLoggedIn from './HeaderNotLoggedIn';

/**
 * Core function
 */
const UMTHeader = () => {
  const { isAuthenticated } = useAppContext();

  if (isAuthenticated.toString() === 'true') {
    return (
      <>
        <UMTHeaderLoggedIn />
      </>
    );
  } else {
    return (
      <>
        <UMTHeaderNotLoggedIn />
      </>
    )
  }
};

export default UMTHeader;
// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  TextArea,
  TextInput,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function NVP({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [type, setType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;

    if (name === '') {
      errorList.name = 'Name cannot be empty';
      formIsValid = false;
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function buildPayload() {
    var payload = {};
    payload.name = name;
    payload.value = value;
    payload.type = type;
    return payload;
  }

  function onSubmit(data) {
    if (validateForm()) {
      if (isAddMode) {
        createNvp();
      } else {
        updateNvp();
      }
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function createNvp() {
    var payload = buildPayload();
    axios
      .post(apiuri + '/namevaluepair', payload, { headers: httpHeaders })
      .then((response) => {
        history.push('/admin/nvp');
      })
      .catch((error) => {
        var msg = 'An error has occurred creating the name-value pair.';
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            msg = 'You are not permitted to perform that operation';
          } else {
            if (error.response.data.message) {
              console.log(JSON.stringify(error.response), null, 2);
              msg = 'An error has occurred creating the name-value pair: ' + error.response.data.message;
            }
            if (error.response.data.reason) {
              msg += ': ' + JSON.stringify(error.response.data.reason);
            }
          }
        }
        setModalMessage(msg);
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateNvp() {
    var payload = buildPayload();
    axios
      .put(apiuri + '/namevaluepair/' + id, payload, { headers: httpHeaders })
      .then((response) => {
        history.push('/admin/nvp');
      })
      .catch((error) => {
        var msg = 'An error has occurred updating the name-value pair.';
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            msg = 'You are not permitted to perform that operation';
          } else {
            if (error.response.data.message) {
              console.log(JSON.stringify(error.response), null, 2);
              msg = 'An error has occurred updating the name-value pair: ' + error.response.data.message;
            }
            if (error.response.data.reason) {
              msg += ': ' + JSON.stringify(error.response.data.reason);
            }
          }
        }
        setModalMessage(msg);
        setModalType('danger');
        setModalOpen(true);
      });
  }

  /**
   * Load data
   */
  useEffect(() => {
    if (!isAddMode) {
      // pre-populate the form
      axios
        .get(apiuri + '/namevaluepair/' + id, { headers: httpHeaders })
        .then((response) => {
          setName(response.data.name);
          setValue(response.data.value);
          setType(response.data.type);
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the name-value pair.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the name-value pair: ' + error.response.data.message);
              }
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });
    }
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-10">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/schema">
                  Schema management
                </BreadcrumbItem>
              </Breadcrumb>

              {isAddMode
              ?
                <h3 className="umt--heading">Add name-value pair</h3>
              :
                <h3 className="umt--heading">Edit name-value pair</h3>
              }

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <TextInput
                    value={type}
                    id="type"
                    type="text"
                    labelText="Type"
                    onChange={(e) => setType(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['name']}
                  </span>
                  <TextInput
                    value={name}
                    id="name"
                    type="text"
                    labelText="Name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <TextInput
                    value={value}
                    id="value"
                    type="text"
                    labelText="Value"
                    onChange={(e) => setValue(e.target.value)}
                  />
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    Submit
                  </Button>
                  <Button kind="secondary" onClick={() => (window.location.href = '/admin/nvp/')}>
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>

            {/* <div className="bx--col-lg-6">
              <h3 className="umt--helper-heading">Help</h3>
              <p className="umt--helper-p">
                Information goes here.
              </p>
            </div> */}
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Name-value pair update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  TextInput,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function LifecycleRule({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [lcrDescription, setLCRDescription] = useState('');
  const [lcrTargetObjectType, setLCRTargetObjectType] = useState();
  const [lcrTargetObject, setLCRTargetObject] = useState();
  const [lcrTrigger, setLCRTrigger] = useState('');
  const [lcrMailRecipient, setLCRMailRecipient] = useState('N/A');
  const [lcrMailTemplate, setLCRMailTemplate] = useState('N/A');
  const [lcrAction, setLCRAction] = useState('Mail only');
  const [lcrPayload, setLCRPayload] = useState('');
  const [lcrEnabled, setLCREnabled] = useState('false');
  const [lcrDaily, setLCRDaily] = useState(false);
  const [lcrExecTime, setLCRExecTime] = useState('');
  const [recipients, setRecipients] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [userTypes, setUserTypes] = useState([]);
  const [thirdParties, setThirdParties] = useState([]);
  const [targetDropDown, setTargetDropDown] = useState([]);
  const [showTP, setShowTP] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();
  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;

    // Validate lcrDescription
    if (lcrDescription === '') {
      errorList.lcrDescription = 'Lifecycle rule description cannot be empty';
      formIsValid = false;
    } else if (lcrDescription.length > 64) {
      errorList.lcrDescription =
        'Lifecycle rule description cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate lcrTrigger
    if (lcrTargetObjectType === '') {
      errorList.lcrTargetObjectType = 'Lifecycle rule target object type cannot be empty';
      formIsValid = false;
    }

    // Validate lcrTrigger
    if (lcrTrigger === '') {
      errorList.lcrTrigger = 'Lifecycle rule trigger cannot be empty';
      formIsValid = false;
    }

    // If daily, there must be an execution time
    if (lcrDaily === true && lcrExecTime === '') {
      errorList.lcrDaily = 'Execution time cannot be empty for a daily rule'
      formIsValid = false;
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    if (validateForm()) {
      return isAddMode ? createLCR() : updateLCR();
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function buildPayload() {
    var payLoad = { description: lcrDescription };
    if (lcrEnabled === 'true' || lcrEnabled === true) {
      payLoad.enabled = true;
    } else {
      payLoad.enabled = false;
    }
    if (lcrTargetObjectType) {
      payLoad.targetObjectType = lcrTargetObjectType;
    }
    if (lcrTargetObject) {
      payLoad.targetObject = lcrTargetObject;
    }
    if (lcrTrigger) {
      payLoad.trigger = lcrTrigger;
    }
    if (lcrMailRecipient) {
      if (lcrMailRecipient === "N/A") {
        payLoad.mailRecipient = null;
      } else {
        payLoad.mailRecipient = lcrMailRecipient;
      }
    }
    if (lcrMailTemplate) {
      if (lcrMailTemplate === "N/A") {
        payLoad.mailRecipient = null;
      } else {
        payLoad.mailTemplate = lcrMailTemplate;
      }
    }
    if (lcrAction) {
      payLoad.action = lcrAction;
    }
    if (lcrPayload) {
      payLoad.payload = lcrPayload;
    }
    if (lcrDaily) {
      payLoad.daily = lcrDaily;
    }
    if (lcrExecTime) {
      payLoad.executionTime = lcrExecTime;
    }

    console.log("Payload to be sent to API is: " + JSON.stringify(payLoad,null, 2));
    return payLoad;
  }

  function createLCR() {
    var payLoad = buildPayload();
    axios
      .post(apiuri + '/lifecyclerule', payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/lcr'); })
      .catch((error) => {
        setModalMessage('An error has occurred creating the lifecycle rule.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred creating the lifecycle rule: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateLCR() {
    var payLoad = buildPayload();
    axios
      .put(apiuri + '/lifecyclerule/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/lcr'); })
      .catch((error) => {
        setModalMessage('An error has occurred updating the lifecycle rule.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            var errMsg = "";
            if (error.response.data.message) {
              errMsg = error.response.data.message;
            }
            if (error.response.data.reason[0]) {
              errMsg = error.response.data.reason[0];
            }
            setModalMessage('An error has occurred updating the lifecycle rule: ' + errMsg);
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  /**
   * Load data
   */
  useEffect(() => {
    var getRoles = axios.get(apiuri + '/role/all', { headers: httpHeaders });
    var getTemplates = axios.get(apiuri + '/mailtemplate/all', { headers: httpHeaders });
    var getUserTypes = axios.get(apiuri + '/usertype/all', { headers: httpHeaders });
    var getThirdParties = axios.get(apiuri + '/thirdparty/all', { headers: httpHeaders });

    axios.all([getRoles, getTemplates, getUserTypes, getThirdParties]).then(
      axios.spread((...responses) => {
        // Configure list of roles
        var tmpRoles = [
          { id: 'N/A', name: 'N/A' },
          { id: 'User', name: 'User (user operations only)' },
          { id: 'Manager', name: 'Manager (user operations only)' },
          { id: 'Requestor', name: 'Requestor (approval operations only)' },
          { id: 'Approver', name: 'Approver (approval operations only)' }
        ];
        responses[0].data.forEach((element) => {
          tmpRoles.push({ id: element.id, name: element.name });
        });
        setRecipients(tmpRoles);

        // Configure list of mail templates
        var tmpTemps = [{ id: 'N/A', name: 'N/A' }];
        responses[1].data.forEach((element) => {
          tmpTemps.push({ id: element.id, name: element.name });
        });
        setTemplates(tmpTemps);

        // Configure list of user types
        var tmpTypes = [{ id: 'All', name: 'All' }];
        responses[2].data.forEach((element) => {
          tmpTypes.push({ id: element.id, name: element.name });
        });
        setUserTypes(tmpTypes);
        setTargetDropDown(tmpTypes);

        // Configure list of third parties
        var tmpTPs = [{ id: 'All', name: 'All' }];
        responses[3].data.forEach((element) => {
          tmpTPs.push({ id: element.id, name: element.name });
        });
        setThirdParties(tmpTPs);



        if (!isAddMode) {
          // pre-populate the form
          axios
            .get(apiuri + '/lifecyclerule/' + id, { headers: httpHeaders })
            .then((response) => {
              setLCRDescription(response.data.description);
              setLCREnabled(response.data.enabled);
              setLCRTargetObjectType(response.data.targetObjectType);
              // if (response.data.targetObjectType === "User") { setTargetDropDown(userTypes); }
              // if (response.data.targetObjectType === "Third party") { setTargetDropDown(thirdParties); }
              if (response.data.targetObjectType === "Third party") { setShowTP(true); }
              setLCRTargetObject(response.data.targetObject);
              setLCRTrigger(response.data.trigger);
              setLCRMailRecipient(response.data.mailRecipient);
              setLCRMailTemplate(response.data.mailTemplate);
              setLCRAction(response.data.action);
              setLCRPayload(response.data.payload);
              setLCRDaily(response.data.daily);
              setLCRExecTime(response.data.executionTime);
            })
            .catch((error) => {
              setModalMessage('An error has occurred retrieving the lifecycle rule.');
              if (error.response) {
                if (error.response.status === 401) {
                  reauth();
                }
                if (error.response.status === 403) {
                  setModalMessage('You are not permitted to perform that operation');
                } else {
                  if (error.response.data.message) {
                    setModalMessage('An error has occurred retrieving the lifecycle rule: ' + error.response.data.message);
                  }
                }
              }
              setModalType('danger');
              setModalOpen(true);
            });
        }
      }) // end of axios spread
    );
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-10">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/lcr">
                  Lifecycle rules
                </BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">
                {isAddMode ? 'Add lifecycle rule' : 'Edit lifecycle rule'}
              </h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['lcrDescription']}
                  </span>
                  <TextInput
                    value={lcrDescription}
                    id="description"
                    type="text"
                    labelText="Rule description"
                    onChange={(e) => setLCRDescription(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrEnabled}
                    id="enabled"
                    invalidText="This is an invalid error message."
                    labelText="Status"
                    onChange={(e) => setLCREnabled(e.target.value)}
                  >
                    <SelectItem text="Disabled" value="false" />
                    <SelectItem text="Enabled" value="true" />
                  </Select>
                </div>



                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Rule trigger details</h4>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrTargetObjectType}
                    id="targettype"
                    invalidText="This is an invalid error message."
                    labelText="Target object type"
                    onChange={(e) => {
                        setLCRTargetObjectType(e.target.value);
                        if (e.target.value === "User") { setTargetDropDown(userTypes); }
                        if (e.target.value === "Third party") { setTargetDropDown(thirdParties); }
                        if (e.target.value === "OTP") { setTargetDropDown([{ id: 'All', name: 'All' }]) }
                      }
                    }
                    >
                    <SelectItem text="" value="" />
                    <SelectItem text="Third party organisation" value="Third party" />
                    <SelectItem text="User" value="User" />
                    <SelectItem text="OTP" value="OTP" />
                  </Select>
                </div>

                {!showTP
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        value={lcrTargetObject}
                        id="target"
                        invalidText="This is an invalid error message."
                        labelText="Target object"
                        onChange={(e) => {
                          console.log("Setting targetObject to " + e.target.value);
                          setLCRTargetObject(e.target.value)}
                        }
                      >
                        {targetDropDown.map((element) => (
                          <SelectItem text={element.name} value={element.id} />
                        ))}
                      </Select>
                    </div>
                  </>
                :
                  null
                }

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['lcrTrigger']}
                  </span>
                  <TextInput
                    value={lcrTrigger}
                    id="trigger"
                    type="text"
                    labelText="Rule trigger"
                    placeholder='e.g., {"umt.endDate": {"$lte": "NOW-90"}}'
                    onChange={(e) => setLCRTrigger(e.target.value)}
                  />
                </div>



                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Rule action details</h4>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrAction}
                    id="action"
                    invalidText="This is an invalid error message."
                    labelText="Action"
                    onChange={(e) => setLCRAction(e.target.value)}
                  >
                    <SelectItem text="Mail only" value="Mail only" />
                    <SelectItem text="Update" value="Update" />
                    <SelectItem text="Delete" value="Delete" />
                    <SelectItem text="Auto-Increment" value="Auto-Increment" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['lcrPayload']}
                  </span>
                  <TextInput
                    value={lcrPayload}
                    id="payload"
                    type="text"
                    labelText="Action payload"
                    placeholder='e.g., {"umt.state": "Archived"}'
                    onChange={(e) => setLCRPayload(e.target.value)}
                  />
                </div>



                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Notification details</h4>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrMailRecipient}
                    id="recipient"
                    invalidText="This is an invalid error message."
                    labelText="Mail recipient"
                    onChange={(e) => setLCRMailRecipient(e.target.value)}
                  >
                    {recipients.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrMailTemplate}
                    id="lcrmailtemplate"
                    invalidText="This is an invalid error message."
                    labelText="Mail template"
                    onChange={(e) => setLCRMailTemplate(e.target.value)}
                  >
                    {templates.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginTop: '4rem', borderBottom: '2px' }}>
                  <h4>Schedule details</h4>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={lcrDaily}
                    id="daily"
                    invalidText="This is an invalid error message."
                    labelText="Daily"
                    helperText="When this is true, the rule will run once per day at the execution time specified"
                    onChange={(e) => setLCRDaily(e.target.value)}
                  >
                    <SelectItem text="False" value="false" />
                    <SelectItem text="True" value="true" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['lcrExecTime']}
                  </span>
                  <TextInput
                    value={lcrExecTime}
                    id="executionTime"
                    type="text"
                    labelText="Execution time"
                    placeholder='09:47/21:47'
                    onChange={(e) => setLCRExecTime(e.target.value)}
                  />
                </div>


                <ButtonSet>
                  <Button kind="primary" type="submit">
                    {isAddMode ? 'Create rule' : 'Update rule'}
                  </Button>
                  <Button
                    kind="secondary"
                    onClick={() => (window.location.href = '/admin/lcr/')}
                  >
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-6">
              <h3 className="umt--helper-heading">Help</h3>
              <p className="umt--helper-p">
                Lifecycle rules are continually evaluated and for each object
                that matches the trigger, an action will be taken:
                <br />- Mail Only: a notification will be sent to the specified recipient
                <br />- Update: the action payload will be run (see below)
                <br />- Delete: the object will be deleted
                <br />- Auto-Increment: a custom attribute will be added to the object, and incremented as more objects are targeted
              </p>
              <h3 className="umt--helper-heading">Triggers</h3>
              <p className="umt--helper-p">
                Triggers are formatted as a JSON object, as such:
                <br />
                <br />
                <code ng-non-bindable>&#123;"attribute": "value"&#125;</code>
                <br />
                <br />
                For example:
                <br /><code ng-non-bindable>&#123;"active": true&#125;</code>
                <br /><code ng-non-bindable>&#123;"$and": [&#123;"umt.state": "Approved"&#125;, &#123;"umt.startDate": &#123;"$lt": "2023-04-21"&#125;&#125;]&#125;</code>
                <br /><code ng-non-bindable>&#123;"$and": [&#123;"givenName": "Dave"&#125;, &#123;"umt.endDate": &#123;"$eq": "NOW+30"&#125;&#125;]&#125;</code>
                <br />
                <br />
                Multiple triggers can be chained together in AND/OR operations as such:
                <br />
                <br /><code ng-non-bindable>&#123;"$and": [&#123;"attribute1": "value1"&#125;, &#123;"attribute2": "value2"&#125;]&#125;</code>
                <br />
                <br />
                As well as triggering on specific values, the following operators are valid:
                <br /><code ng-non-bindable>$lt</code> (less than)
                <br /><code ng-non-bindable>$lte</code> (less than or equal to)
                <br /><code ng-non-bindable>$gt</code> (greater than)
                <br /><code ng-non-bindable>$gte</code> (greater than or equal to)
                <br />
                <br />For example:
                <br /><code ng-non-bindable>&#123;"umt.endDate": &#123;"$lte": "NOW"&#125;&#125;</code>
                <br />
                <br />
                Attributes are the full attribute names as defined in the schema.
                <br />
                <br />
                An object can be referred to by its ObjectId. For example:
                <br />
                <code ng-non-bindable>
                  &#123;"userType": ObjectId(642157b367a27d9c624b4d07)&#125;
                </code>
              </p>
              <h3 className="umt--helper-heading">Actions</h3>
              <p className="umt--helper-p">
                For an Update action, the format is similar to that of the trigger. For example, the following will set the Active attribute to false and umt.state to "Suspended".
                <br />
                <br /><code ng-non-bindable>&#123;"active": false, "umt.state": "Suspended"&#125;</code>
                <br />
              </p>
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Lifecycle rule update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

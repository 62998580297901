// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  DataTable,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tag
} from 'carbon-components-react';
import { Add16, TrashCan16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

// Import Data
const headers = [
  { key: 'description', header: 'Description' },
  { key: 'targetObjectType', header: 'Target object' },
  { key: 'trigger', header: 'Trigger' },
  { key: 'action', header: 'Action' },
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableSelectAll,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions,
} = DataTable;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function LifecycleRule({ history }) {
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();
  const [statuses, setStatuses] = useState([]);



  /**
   * Load data
   */
  function loadData() {
    axios
      .get(apiuri + '/lifecyclerule/all', { headers: httpHeaders })
      .then((response) => {
        // Transform mailRecipient into something we understand!
        var payLoad = [];
        var tmpArray = [];
        response.data.forEach((element) => {
          tmpArray[element.id] = element.enabled;
          if (element.visible === true) {
            if (element.enabled) {
              element.description = <Link href={'/admin/lcr/edit/' + element.id}>{element.description}</Link>;
              element.enabled = <Tag type='green'>Enabled</Tag>;
            } else {
              element.description = <Link disabled>{element.description}</Link>;
              element.targetObjectType = <Link disabled>{element.targetObjectType}</Link>;
              element.trigger = <Link disabled>{element.trigger}</Link>;
              element.action = <Link disabled>{element.action}</Link>;
              element.enabled = <Tag type='red'>Disabled</Tag>;
            }
            payLoad.push(element);
          }
        });
        setRows(payLoad);
        setTotalItems(payLoad.length);
        setStatuses(tmpArray);
      })
      .catch((error) => {
        setModalMessage('An error has occurred retrieving the list of lifecycle rules.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred retrieving the list of lifecycle rules: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * Handle enable/disable operations
   */
   function setStatus(id, state) {
    var payLoad = { enabled: state }
    axios
      .put(apiuri + '/lifecyclerule/' + id, payLoad, { headers: httpHeaders })
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setModalMessage('An error has occurred setting the status of the lifecycle rule.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred setting the status of the life cycle: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * Handle delete
   */
  function handleDelete(id) {
    axios
      .delete(apiuri + '/lifecyclerule/' + id, { headers: httpHeaders })
      .then((response) => { loadData() })
      .catch((error) => {
        setModalMessage('An error has occurred deleting the lifecycle rules.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred deleting the lifecycle rules: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      })
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Return details
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">Lifecycle rules management</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows.slice(
                  firstRowIndex,
                  firstRowIndex + currentPageSize
                )}
                headers={headers}
                isSortable
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getSelectionProps,
                  getToolbarProps,
                  getBatchActionProps,
                  selectedRows,
                  getTableContainerProps,
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableBatchActions {...getBatchActionProps()}>
                        <TableBatchAction renderIcon={TrashCan16}
                          onClick={()=> {
                            selectedRows.forEach((element) => { handleDelete(element.id) })
                            window.location.href = '/admin/lcr';
                          }}
                        >
                          Delete
                        </TableBatchAction>
                      </TableBatchActions>
                      <TableToolbarContent>
                        <Button size="small" kind="primary" renderIcon={Add16} href="/admin/lcr/add">
                          Add rule
                        </Button>
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll {...getSelectionProps()} />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt-table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableSelectRow {...getSelectionProps({ row })} />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                             {statuses[row.id]
                              ?
                                <>
                                  <OverflowMenu flipped>
                                    <OverflowMenuItem itemText="Edit" onClick={() => {window.location.href = '/admin/lcr/edit/' + row.id}} />
                                    <OverflowMenuItem itemText="Delete" isDelete onClick={() => handleDelete(row.id) } />
                                    <OverflowMenuItem itemText="Enable" hasDivider disabled />
                                    <OverflowMenuItem itemText="Disable" isDelete onClick={() => { setStatus(row.id, false); }} />
                                  </OverflowMenu>
                                </>
                              :
                                <>
                                  <OverflowMenu flipped>
                                    <OverflowMenuItem itemText="Edit" onClick={() => {window.location.href = '/admin/lcr/edit/' + row.id}} />
                                    <OverflowMenuItem itemText="Delete" isDelete onClick={() => handleDelete(row.id) } />
                                    <OverflowMenuItem itemText="Enable" hasDivider onClick={() => { setStatus(row.id, true); }} />
                                    <OverflowMenuItem itemText="Disable" disabled />
                                  </OverflowMenu>
                                </>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                itemsPerPageText="Items per page"
                onChange={({ page, pageSize }) => {
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                  }
                  setFirstRowIndex(pageSize * (page - 1));
                }}
              />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Role management</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Select,
  SelectItem,
  TextArea,
  TextInput,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function Schema({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [attributeName, setAttributeName] = useState('');
  const [attributeType, setAttributeType] = useState('');
  const [atEnabled, setATEnabled] = useState(false);
  const [attributeLabelDE, setAttributeLabelDE] = useState('');
  const [attributeLabelEN, setAttributeLabelEN] = useState('');
  const [attributeLabelES, setAttributeLabelES] = useState('');
  const [attributeLabelFR, setAttributeLabelFR] = useState('');
  const [attributeLabelIT, setAttributeLabelIT] = useState('');
  const [attributeLabelPT, setAttributeLabelPT] = useState('');
  const [attributeValidValues, setAttributeValidValues] = useState('');
  const [attributeEnabled, setAttributeEnabled] = useState(true);
  const [attributeProtected, setAttributeProtected] = useState(false);
  const [attributeInheritable, setAttributeInheritable] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;

    // Validate attributeName
    if (attributeName === '') {
      errorList.attributeName = 'Attribute name cannot be empty';
      formIsValid = false;
    } else if (attributeName.length > 64) {
      errorList.attributeName = 'Attribute name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    if (validateForm()) {
      var payLoad = {
        name: attributeName,
        type: attributeType,
        labelen: attributeLabelEN,
        labelde: attributeLabelDE,
        labeles: attributeLabelES,
        labelfr: attributeLabelFR,
        labelit: attributeLabelIT,
        labelpt: attributeLabelPT,
        validValues: attributeValidValues,
        enabled: attributeEnabled,
        inheritable: attributeInheritable
      };
      axios
        .put(apiuri + '/attribute/' + id, payLoad, { headers: httpHeaders })
        .then((response) => {
          history.push('/admin/schema');
        })
        .catch((error) => {
          var msg = 'An error has occurred updating the attribute.';
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              msg = 'You are not permitted to perform that operation';
            } else {
              if (error.response.data.message) {
                console.log(JSON.stringify(error.response), null, 2);
                msg = 'An error has occurred updating the attribute: ' + error.response.data.message;
              }
              if (error.response.data.reason) {
                msg += ': ' + JSON.stringify(error.response.data.reason);
              }
            }
          }
          setModalMessage(msg);
          setModalType('danger');
          setModalOpen(true);
        });
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  /**
   * Load data
   */
  useEffect(() => {
    if (!isAddMode) {
      // pre-populate the form
      axios
        .get(apiuri + '/attribute/' + id, { headers: httpHeaders })
        .then((response) => {
          setAttributeName(response.data.name);
          setAttributeType(response.data.type);
          setAttributeLabelDE(response.data.labelde);
          setAttributeLabelEN(response.data.labelen);
          setAttributeLabelES(response.data.labeles);
          setAttributeLabelFR(response.data.labelfr);
          setAttributeLabelIT(response.data.labelit);
          setAttributeLabelPT(response.data.labelpt);
          setAttributeValidValues(response.data.validValues);
          setAttributeEnabled(response.data.enabled);
          setAttributeProtected(response.data.protected);
          setAttributeInheritable(response.data.inheritable);
          setATEnabled(true);
          if (response.data.protected) {
            setATEnabled(false);
          }
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the attribute.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the attribute: ' + error.response.data.message);
              }
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });
    }
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-10">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/schema">
                  Schema management
                </BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">Edit attribute</h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['attributeName']}
                  </span>
                  <TextInput
                    value={attributeName}
                    id="attrname"
                    disabled
                    type="text"
                    labelText="Attribute name"
                  />
                </div>

                {atEnabled
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <Select
                        value={attributeType}
                        id="attrtype"
                        invalidText="This is an invalid error message."
                        labelText="Attribute type"
                        onChange={(e) => setAttributeType(e.target.value)}
                      >
                        <SelectItem text="String" value="String" />
                        <SelectItem text="Long Text" value="Long Text" />
                        <SelectItem text="Integer" value="Integer" />
                        <SelectItem text="Date" value="Date" />
                        <SelectItem text="Datetime" value="Datetime" />
                        <SelectItem text="Boolean" value="Boolean" />
                        <SelectItem text="Select" value="Select" />
                        <SelectItem text="Object" value="Object" />
                      </Select>
                    </div>
                  </>
                :
                  null
                }

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['attributeLabelEN']}
                  </span>
                  <TextInput
                    value={attributeLabelEN}
                    id="labelen"
                    type="text"
                    labelText="Label"
                    onChange={(e) => setAttributeLabelEN(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['attributeValidValues']}</span>
                  <TextArea
                    value={attributeValidValues}
                    cols={50}
                    id="validValues"
                    labelText="Valid values (one item per line)"
                    rows={15}
                    onChange={(e) => setAttributeValidValues(e.target.value)}
                  />
                </div>

                {!attributeProtected ?
                  <div style={{ marginBottom: '2rem' }}>
                    <Select
                      value={attributeEnabled}
                      id="attrEnabled"
                      labelText="Enabled"
                      onChange={(e) => setAttributeEnabled(e.target.value)}
                    >
                      <SelectItem text="TRUE" value="true" />
                      <SelectItem text="FALSE" value="false" />
                    </Select>
                  </div>
                : null
                }

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={attributeInheritable}
                    id="attrInheritable"
                    labelText="Inheritable"
                    onChange={(e) => setAttributeInheritable(e.target.value)}
                  >
                    <SelectItem text="TRUE" value="true" />
                    <SelectItem text="FALSE" value="false" />
                  </Select>
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    Submit
                  </Button>
                  <Button kind="secondary" onClick={() => (window.location.href = '/admin/schema/')}>
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>

            <div className="bx--col-lg-6">
              <h3 className="umt--helper-heading">Help</h3>
              <p className="umt--helper-p">
                All attribute descriptions and labels can be updated. Attributes names, however,
                cannot be modified.
                <br />
                <br />
                Attributes can be enabled/disabled globally and also assigned to specific
                user types.
                <br />
                <br />
                Custom attributes can be defined as required. The type of the custom attribute 
                is selected from a dropdown menu, and valid values for the attribute may be defined below.
                <br />
                <br />
                Some user attribute values can be inherited from the user's manager. To allow 
                an attribute to be inherited, set Inheritable to TRUE.
              </p>
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Schema update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

import React from 'react';

// Import routes
import { Route, Switch } from 'react-router-dom';
import LandingPage from '../pages/LandingPage';
import AdminHome from '../pages/Administration/Home';
import AdminAudit from '../pages/Administration/AuditLog';
import AdminLCR from '../pages/Administration/LifeCycleRule';
import AdminLCRForm from '../pages/Administration/LifeCycleRuleForm';
import AdminMailTemplate from '../pages/Administration/MailTemplate';
import AdminMailTemplateForm from '../pages/Administration/MailTemplateForm';
import AdminNVP from '../pages/Administration/NameValuePairs';
import AdminNVPForm from '../pages/Administration/NameValuePairForm';
import AdminRole from '../pages/Administration/Role';
import AdminRoleForm from '../pages/Administration/RoleForm';
import AdminSchema from '../pages/Administration/Schema';
import AdminSchemaForm from '../pages/Administration/SchemaForm';
import AdminSettings from '../pages/Administration/Settings';
import AdminThirdParty from '../pages/Administration/ThirdParty';
import AdminThirdPartyForm from '../pages/Administration/ThirdPartyForm';
import AdminUserType from '../pages/Administration/UserType';
import AdminUserTypeForm from '../pages/Administration/UserTypeForm';
import Approvals from '../pages/Approvals/Approvals';
import Users from '../pages/Users/Users';
import UserForm from '../pages/Users/UserForm';
import ReportsHome from '../pages/Reports/Home';
import ReportRequest from '../pages/Reports/Request';
import ReportDownload from '../pages/Reports/Download';
import ReportMySchedule from '../pages/Reports/MySchedule';
import ReportAllSchedules from '../pages/Reports/AllSchedules';
import Callback from '../pages/OIDC/Callback.tsx';
import Adfs from '../pages/OIDC/Adfs.js';
//import OIDCInitiate from '../pages/OIDC/Initiate';
//<Route path="/oidc/callback" component={OIDCCallback} />
//<Route path="/oidc/initiate" component={OIDCInitiate} />

import ProtectedRoute from '../lib/ProtectedRoute';

export default function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/health"><p>Application is healthy</p></Route>

        <ProtectedRoute path="/admin/audit" component={AdminAudit} />

        <ProtectedRoute path="/admin/lcr/add" component={AdminLCRForm} />
        <ProtectedRoute path="/admin/lcr/edit/:id" component={AdminLCRForm} />
        <ProtectedRoute path="/admin/lcr" component={AdminLCR} />

        <ProtectedRoute path="/admin/mailtemplate/add" component={AdminMailTemplateForm} />
        <ProtectedRoute path="/admin/mailtemplate/edit/:id" component={AdminMailTemplateForm} />
        <ProtectedRoute path="/admin/mailtemplate" component={AdminMailTemplate} />

        <ProtectedRoute path="/admin/nvp/add" component={AdminNVPForm} />
        <ProtectedRoute path="/admin/nvp/edit/:id" component={AdminNVPForm} />
        <ProtectedRoute path="/admin/nvp" component={AdminNVP} />

        <ProtectedRoute path="/admin/role/add" component={AdminRoleForm} />
        <ProtectedRoute path="/admin/role/edit/:id" component={AdminRoleForm} />
        <ProtectedRoute path="/admin/role" component={AdminRole} />

        <ProtectedRoute path="/admin/schema/edit/:id" component={AdminSchemaForm} />
        <ProtectedRoute path="/admin/schema" component={AdminSchema} />

        <ProtectedRoute path="/admin/settings" component={AdminSettings} />

        <ProtectedRoute path="/admin/thirdparty/add" component={AdminThirdPartyForm} />
        <ProtectedRoute path="/admin/thirdparty/edit/:id" component={AdminThirdPartyForm} />
        <ProtectedRoute path="/admin/thirdparty" component={AdminThirdParty} />

        <ProtectedRoute path="/admin/usertype/add" component={AdminUserTypeForm} />
        <ProtectedRoute path="/admin/usertype/edit/:id" component={AdminUserTypeForm} />
        <ProtectedRoute path="/admin/usertype" component={AdminUserType} />

        <ProtectedRoute path="/admin" component={AdminHome} />

        <ProtectedRoute path="/approvals" component={Approvals} />

        <Route path="/authentication/callback" component={Callback} />
        <Route path="/authentication/adfs" component={Adfs} />

        <ProtectedRoute path="/reports/request" component={(props) => <ReportRequest {...props} />} />
        <ProtectedRoute path="/reports/download" component={ReportDownload} />
        <ProtectedRoute path="/reports/myschedule" component={ReportMySchedule} />
        <ProtectedRoute path="/reports/allschedules" component={ReportAllSchedules} />
        <ProtectedRoute path="/reports" component={ReportsHome} />

        <ProtectedRoute path="/users/add" component={UserForm} />
        <ProtectedRoute path="/users/add/bulk" component={UserForm} />
        <ProtectedRoute path="/users/edit/:id" component={UserForm} />
        <ProtectedRoute path="/users/helpdesk" component={Users} />
        <ProtectedRoute path="/users/type/:id" component={Users} />
        <ProtectedRoute path="/users" component={Users} />
      </Switch>
    </>
  );
}

// Import core modules
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// Import libraries
import { useAppContext } from '../../lib/context';

// Import elements
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderGlobalBar,
  HeaderGlobalAction,
} from 'carbon-components-react';
import {
  Help20,
} from '@carbon/icons-react';

import './HeaderNotLoggedIn.css';

/**
 * Core function
 */
export default function UMTHeaderNotLoggedIn({ history, match }) {
  const { isAuthenticated, setIsAuthenticated } = useAppContext();


  const goToHelp = () => {
    window.location.href = 'https://docs.madiganid.com/';
  };

  useEffect(() => {
    console.log("HeaderNotLoggedIn: " + isAuthenticated);
  }, [isAuthenticated]);

  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan UMT">
              <HeaderName element={Link} to="/" prefix="Madigan">
                UMT
              </HeaderName>
              <HeaderGlobalBar>
                <HeaderGlobalAction
                  aria-label="Help"
                  onClick={goToHelp}
                >
                  <Help20 />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
            </Header>
          </>
        )}
      />
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  DataTable,
  Form,TextInput,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tag,
  Tile
} from 'carbon-components-react';
import { Add16, Locked16, Unlocked16 } from '@carbon/icons-react';
import { ConditionBuilder } from '@carbon/pictograms-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;
const headers = [
  { key: 'displayName', header: 'Display name' },
  { key: 'userType', header: 'User type' },
  { key: 'emailAddress', header: 'Email address' },
  { key: 'startDate', header: 'Start date' },
  { key: 'endDate', header: 'End date' },
  { key: 'status', header: 'Status' }
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions,
} = DataTable;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};




/**
 * Core function
 */
export default function UsersPage({ history, match }) {
  const { id } = match.params;

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }
  
  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  const managerId = jwt.id;
  
  var bulkPerm = false;
  if (jwt.permissions.includes("bulk_w")) {
    bulkPerm = true;
  }

  var isManagerMode = true;
  var isTypeMode = false;
  var isHelpDeskMode = false;

  var breadcrumb = "My users";
  if (window.location.href.indexOf("helpdesk") > -1) {
    isManagerMode = false;
    isHelpDeskMode = true;
    breadcrumb = "All users";
  }
  if (window.location.href.indexOf("type") > -1) {
    isManagerMode = false;
    isTypeMode = true;
    // breadcrumb = "All users";
    breadcrumb = "User type users";
  }
  
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [start, setStart] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [prevItem, setPrevItem] = useState();
  const [nextItem, setNextItem] = useState();
  const [page, setPage] = useState(1);
  const [prevPage, setPrevPage] = useState();
  const [direction, setDirection] = useState();
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();
  const [searchFilter, setSearchFilter] = useState('');

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [modalCPOpen, setModalCPOpen] = useState(false);
  const [modalCPMessage, setModalCPMessage] = useState();
  const [formErrors, setFormErrors] = useState({});
  const [cpUser, setCPUser] = useState();
  const [cpUserName, setCPUserName] = useState('');
  const [cpSelf, setCPSelf] = useState(false);

  const dOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const [statuses, setStatuses] = useState([]);


  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    errors,
    formState,
  } = useForm({});



  function UMTComponent(props) {
    var element1 = null;
    var element2 = null;
    
    if (statuses[props.row] === "Suspendable") {
      element1 = <OverflowMenuItem itemText="Suspend" hasDivider onClick={() => { setStatus(props.row, false); }} />
      element2 = <OverflowMenuItem itemText="Activate" disabled />
    }
  
    if (statuses[props.row] === "Activatable") {
      element1 = <OverflowMenuItem itemText="Suspend" hasDivider disabled />
      element2 = <OverflowMenuItem itemText="Activate" onClick={() => { setStatus(props.row, true); }} />
    }
  
    return (
      <OverflowMenu flipped>
        <OverflowMenuItem itemText="View/Edit" onClick={() => { window.location.href = '/users/edit/' + props.row }} />
        <OverflowMenuItem
          itemText="Change password"
          hasDivider
          onClick={() => {
            setCPUser(props.row);
            setModalCPOpen(true);
            getCPUserDetails(props.row);
          }}
        />
        {element1}
        {element2}
      </OverflowMenu>
    )
  
    /*
     * Deletion can be re-enabled by adding this. Seems like a bad idea though!
     *         <OverflowMenuItem itemText="Delete" isDelete  onClick={() => { handleDelete(props.row)}} />
     */
  }
  


  /**
   * Load data
   */
  function loadData(filter, clear) {
    var apiCall;
    if (isManagerMode) {
      apiCall = apiuri + '/user/findByManager?expanded=true&manager=' + managerId + '&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
    }
    
    if (isTypeMode) {
      if (filter) {
        apiCall = apiuri + '/user/findByNameAndType?expanded=true&name=' + filter + '&type=' + id + '&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
      } else if (clear) {
        apiCall = apiuri + '/user/findByType?expanded=true&type=' + id + '&prevItem=undefined&nextItem=undefined&direction=undefined' + '&pageSize=' + currentPageSize;
      } else {
        apiCall = apiuri + '/user/findByType?expanded=true&type=' + id + '&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
      }
    }

    if (isHelpDeskMode) {
      if (filter) {
        apiCall = apiuri + '/user/findByName?expanded=true&name=' + filter + '&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
      } else if (clear) {
        apiCall = apiuri + '/user/all?expanded=true&prevItem=undefined&nextItem=undefined&direction=undefined&pageSize=' + currentPageSize;
      } else {
        apiCall = apiuri + '/user/all?expanded=true&prevItem=' + prevItem + '&nextItem=' + nextItem + '&direction=' + direction + '&pageSize=' + currentPageSize;
      }
    }

    var encodedApiCall = encodeURI(apiCall);

    axios
      .get(apiuri + '/usertype/all', { headers: httpHeaders })
      .then((response) => {
        var userTypes = [];
        response.data.forEach((element) => {
          userTypes[element.id.toString()] = element.name;
        });

        axios
          .get(encodedApiCall, { headers: httpHeaders })
          .then((response) => {
            setPrevItem(response.data.prevItem);
            setNextItem(response.data.nextItem);
            setPrevPage(page);

            var payLoad = [];
            var userStatuses = [];

            if (response.data.items) {
              response.data.items.forEach((element) => {
                var newElement = {};
                newElement.search = "";
                newElement._id = element._id;
                newElement.id = element._id;
                newElement.status = element.status;
  
                if (!element.displayName) {
                  newElement.displayName = element.name.givenName + " " + element.name.familyName;
                } else {
                  newElement.displayName = element.displayName;
                }
                newElement.search = newElement.search + "|" + newElement.displayName;
  
                if (newElement.displayName.length > 64) {
                  newElement.displayName = newElement.displayName.substring(0,60) + "...";
                }
  
                if (element.emails) {
                  if (element.emails.length > -1) {
                    if (element.emails[0]) {
                      if (element.emails[0].value) {
                        newElement.emailAddress = element.emails[0].value;
                        newElement.search = newElement.search + "|" + newElement.emailAddress;
                      }
                    }
                  }
                }
  
                var permitUpdate = false;
  
                // If userType.contractBased is true, we shall work out start/end dates based on CONTRACTS
                var contractBased = false;
                if (element.userTypeDetails && element.userTypeDetails.contractBased) {
                  contractBased = element.userTypeDetails.contractBased;
                }
                if (!contractBased) {
                  if (element.umt) {
                    var today = new Date();
                    if (element.umt.startDate) {
                      newElement.startDate =  new Date(element.umt.startDate).toLocaleDateString(undefined, dOptions);
                      newElement.search = newElement.search + "|" + newElement.startDate;
                      var sd = new Date(element.umt.startDate);
                      if (sd <= today) {
                        permitUpdate = true;
                      } else {
                        permitUpdate = false;
                      }
                    }
                    if (element.umt.endDate) {
                      newElement.endDate = new Date(element.umt.endDate).toLocaleDateString(undefined, dOptions);
                      newElement.search = newElement.search + "|" + newElement.endDate;
                      var ed = new Date(element.umt.endDate);
                      if (ed < today) {
                        permitUpdate = false;
                      }
                    }
                    if (element.umt.approvalState) {
                      newElement.approvalState = element.umt.approvalState;
                    }
                  }
                } else {
                  newElement.startDate = "";
                  newElement.endDate = "";
                  if (element.currentContract) {
                    var cc = element.currentContract;
                    if (cc.startDate) {
                      newElement.startDate = new Date(cc.startDate).toLocaleDateString(undefined, dOptions);
                      newElement.search = newElement.search + "|" + newElement.startDate;
                    }
                    if (cc.endDate) {
                      newElement.endDate = new Date(cc.endDate).toLocaleDateString(undefined, dOptions);
                      newElement.search = newElement.search + "|" + newElement.endDate;
                    }
                    if (cc.state) {
                      newElement.approvalState = cc.state;
                      if (cc.state === "Approved" || cc.state === "Approved (Auto)") {
                        permitUpdate = true;
                      }
                    }
                  } else {
                    var gotcha = false;
                    var ch = element.allContracts;
                    var now = new Date();
                    ch.forEach((chel) => {
                      if (new Date(chel.startDate) > now && gotcha === false) {
                        gotcha = true;
                        newElement.startDate = new Date(chel.startDate).toLocaleDateString(undefined, dOptions);
                        newElement.search = newElement.search + "|" + newElement.startDate;
                        newElement.endDate = new Date(chel.endDate).toLocaleDateString(undefined, dOptions);
                        newElement.search = newElement.search + "|" + newElement.endDate;
                        newElement.approvalState = chel.state;
                      }
                    });
                  }
                }
  
                if (element.userTypeDetails) {
                  if (element.userTypeDetails.managed === false) {
                    permitUpdate = false;
                  }
                }
  
                userStatuses[element._id] = "";
                if (permitUpdate) {
                  if (element.status === "Active") {
                    userStatuses[element._id] = "Suspendable";
                  } else {
                    userStatuses[element._id] = "Activatable";
                  }
                }
                newElement.search = newElement.search + "|" + element.status;
  
                if (element.userType) {
                  newElement.userType = userTypes[element.userType];
                  newElement.search = newElement.search + "|" + newElement.userType;
                }
  
                if (newElement.status === "Active") {
                  newElement.displayName = <Link href={'/users/edit/' + element._id}>{newElement.displayName}</Link>;
                  newElement.status = <Tag type='green'>{newElement.status}</Tag>;
                } else {
                  newElement.displayName = <Link disabled>{newElement.displayName}</Link>;
                  newElement.userType = <Link disabled>{newElement.userType}</Link>;
                  newElement.emailAddress = <Link disabled>{newElement.emailAddress}</Link>;
                  newElement.startDate = <Link disabled>{newElement.startDate}</Link>;
                  newElement.endDate = <Link disabled>{newElement.endDate}</Link>;
                  if (newElement.approvalState) {
                    if (newElement.approvalState === "Approved" || newElement.approvalState === "N/A") {
                      newElement.status = <Tag type='red'>{newElement.status}</Tag>;
                    } else {
                      newElement.status = <Tag type='red'>{newElement.approvalState}</Tag>;
                    }
                  } else {
                    newElement.status = <Tag type='red'>{newElement.status}</Tag>;
                  }
                }
  
                payLoad.push(newElement);
              });

              setTotalItems(response.data.count);
            } else {
              setTotalItems(0);
            }
            setRows(payLoad);
            setAllRows(payLoad);
            setStatuses(userStatuses);
          })
          .catch((error) => {
            setModalMessage('An error has occurred retrieving the list of users.');
            if (error.response) {
              if (error.response.status === 401) {
                reauth();
              }
              if (error.response.status === 403) {
                setModalMessage('You are not permitted to perform that operation');
              } else {
                if (error.response.data.message) {
                  setModalMessage('An error has occurred retrieving the list of users: ' + error.response.data.message);
                }
              }
            }
            setModalType("danger");
            setModalOpen(true);
          });
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the list of user types.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the list of user types: ' + error.response.data.message);
              }
            }
          }
          setModalType("danger");
          setModalOpen(true);
        });
  }


  /**
   * Handle enable/disable operations
   */
  function setStatus(id, state) {
    var payLoad = {}
    if (state) {
      payLoad = { state: "Active" }
    } else {
      payLoad = { state: "Inactive" }
    }

    axios
      .put(apiuri + '/user/' + id, payLoad, { headers: httpHeaders })
      .then((response) => {
        loadData();
      })
      .catch((error) => {
        setModalMessage('An error has occurred setting the status of the user.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred setting the status of the user: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }



  /**
   * Handle delete
   */
  function handleDelete(id) {
    axios.delete(apiuri + '/user/' + id, { headers: httpHeaders, })
    .then((response) => {
      loadData();
    })
    .catch((error) => {
      setModalMessage('An error has occurred deleting the user.');
      if (error.response) {
        if (error.response.status === 401) {
          reauth();
        }
        if (error.response.status === 403) {
          setModalMessage('You are not permitted to perform that operation');
        } else {
          if (error.response.data.message) {
            setModalMessage('An error has occurred deleting the user: ' + error.response.data.message);
          }
        }
      }
      setModalType('danger');
      setModalOpen(true);
    });
  }



  function getCPUserDetails(user) {
    let errorList = [];
    axios.get(apiuri + '/user/' + user, { headers: httpHeaders })
    .then((response) => {
      setCPUserName(response.data.displayName);
      if (response.data._id === jwt.id) {
        // Trying to change own password
        setCPSelf(true);
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          reauth();
        }
        if (error.response.status === 403) {
          if (error.response.data.message === 'Current password is invalid') {
            setModalCPMessage('Old password is incorrect');
          } else {
            setModalCPMessage('You are not permitted to perform that operation');
          }
        } else {
          if (error.response.data.message) {
            setModalCPMessage('An error has occurred changing the password: ' + error.response.data.message);
          }
        }
      }

      // password change failed
      errorList.username = 'Oops';
      setFormErrors(errorList);
    });
  }



  function handlePasswordChange() {
    let errorList = [];
    let formIsValid = true;
    if (cpSelf === true && oldPassword === '') {
      errorList.oldPassword = 'Old password cannot be empty';
      formIsValid = false;
    }
    if (newPassword === '') {
      errorList.newPassword = 'New password cannot be empty';
      formIsValid = false;
    }
    if (confirmNewPassword === '') {
      errorList.confirmNewPassword = 'Confirm new password cannot be empty';
      formIsValid = false;
    }
    if (newPassword !== confirmNewPassword) {
      errorList.confirmNewPassword = 'Passwords do not match';
      formIsValid = false;
    }

    if (!formIsValid) {
      setFormErrors(errorList);
    } else {
      var payLoad = {
        newPassword: newPassword,
        password: oldPassword,
      };
      axios.put(apiuri + '/user/changepassword/' + cpUser, payLoad, { headers: httpHeaders })
      .then((response) => {
        window.location.href = '/users';
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            if (error.response.data.message === 'Current password is invalid') {
              setModalCPMessage('Old password is incorrect');
            } else {
              setModalCPMessage('You are not permitted to perform that operation');
            }
          } else {
            if (error.response.data.message) {
              setModalCPMessage('An error has occurred changing the password: ' + error.response.data.message);
            }
          }
        }

        // password change failed
        errorList.username = 'Oops';
        setFormErrors(errorList);
      });
    }
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData(searchFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSize, page]);




  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/users">Users</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">{breadcrumb}</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <Tile>
                <h3 className="umt--tile-header">Users</h3>
                <p className="umt--tile-value">{totalItems}</p>
              </Tile>
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
            <div className="bx--col">
            </div>
          </div>
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows}
                headers={headers}
                isSortable={false}
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getSelectionProps,
                  getToolbarProps,
                  getBatchActionProps,
                  onInputChange,
                  selectedRows,
                  getTableContainerProps,
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableBatchActions {...getBatchActionProps()}>
                        <TableBatchAction renderIcon={Locked16}>
                          Suspend
                        </TableBatchAction>
                        <TableBatchAction renderIcon={Unlocked16}>
                          Activate
                        </TableBatchAction>
                      </TableBatchActions>
                      <TableToolbarContent>
                        {
                          isManagerMode ?
                            <></>
                          :
                            <>
                              <TableToolbarSearch
                                defaultExpanded
                                expanded
                                placeholder="Search users"
                                onChange={(e) => {
                                  if (searchFilter === '' || searchFilter.length <= 1) {
                                    loadData(null, true);
                                    setPrevItem('undefined');
                                    setNextItem('undefined');
                                  }
                                  setSearchFilter(e.target.value.replace(/[^\w\s]/gi, ''));
                                }}
                                onClear={(e) => {
                                  loadData(null, true);
                                  setPrevItem('undefined');
                                  setNextItem('undefined');
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    loadData(searchFilter)
                                  }
                                  if (searchFilter === '') {
                                    loadData(null, true);
                                    setPrevItem('undefined');
                                    setNextItem('undefined');
                                  }
                                }}
                              />
                          </>
                        }
                        <Button
                          size="small"
                          kind="primary"
                          renderIcon={Add16}
                          href="/users/add"
                        >
                          Add user
                        </Button>
                        {bulkPerm
                        ?
                        <Button
                          size="small"
                          kind="tertiary"
                          renderIcon={Add16}
                          href="/users/add/bulk"
                        >
                          Bulk add
                        </Button>
                        :
                        null
                        }
                      </TableToolbarContent>
                    </TableToolbar>

                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll {...getSelectionProps()} />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableSelectRow {...getSelectionProps({ row })} />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                              <UMTComponent
                                row={row.id}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Pagination
                      totalItems={totalItems}
                      backwardText="Previous page"
                      forwardText="Next page"
                      page={Math.floor(start / currentPageSize) + 1}
                      pageSize={currentPageSize}
                      pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                      itemsPerPageText="Items per page"
                      pageInputDisabled={true}
                      onChange={({ page, pageSize }) => {
                        if (totalItems === 0 || start < totalItems) {
                          // we should be grand
                        } else {
                          setStart(Math.max(start - (Math.floor((page - totalItems / pageSize) + 1) * pageSize), 0));
                        }
                        if (pageSize !== currentPageSize) {
                          setCurrentPageSize(pageSize);
                          setDirection(undefined);
                        }
                        setFirstRowIndex(pageSize * (page - 1));
                        setPage(page);

                        if (page > prevPage) {
                          setDirection('up');
                        } else if (page < prevPage) {
                          setDirection('down');
                        }
                      }}
                    />

                  </TableContainer>
                )}
              />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">User management</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}

      <ComposedModal
        open={modalCPOpen}
        onClose={() => {
          setModalCPOpen(false);
          setOldPassword('');
          setNewPassword('');
          setConfirmNewPassword('');
          setCPSelf(false);
        }}
        size="sm"
        preventCloseOnClickOutside
      >
        <ModalHeader>
          <h3 className="umt--heading">Change <strong>{cpUserName}</strong>'s password</h3>
        </ModalHeader>
        <Form onSubmit={handleSubmit(handlePasswordChange)}>
          <ModalBody>
            <p style={{ color: 'red' }} className=".bx--modal-content__text">{modalCPMessage}</p>

            {(cpSelf)
            ?
            <>
              <span style={{ color: 'red' }}>{formErrors['oldPassword']}</span>
              <div style={{ marginBottom: '2rem' }}>
                <TextInput.PasswordInput
                  value={oldPassword}
                  id="oldPassword"
                  labelText="Old password"
                  onChange={(e) => { setFormErrors([]); setOldPassword(e.target.value)}}
                />
              </div>
            </>
            :
            null
            }            

            <span style={{ color: 'red' }}>{formErrors['newPassword']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <TextInput.PasswordInput
                value={newPassword}
                id="newPassword"
                labelText="New password"
                onChange={(e) => { setFormErrors([]); setNewPassword(e.target.value)}}
              />
            </div>

            <span style={{ color: 'red' }}>{formErrors['confirmNewPassword']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <TextInput.PasswordInput
                value={confirmNewPassword}
                id="confirmNewPassword"
                labelText="Confirm new password"
                onChange={(e) => { setFormErrors([]); setConfirmNewPassword(e.target.value)}}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              kind="primary"
              type="submit"
              onClick={() => {
                handlePasswordChange();
              }}
            >
              Submit
            </Button>
            <Button
              kind="secondary"
              onClick={() => {
                setFormErrors([]);
                setNewPassword('');
                setConfirmNewPassword('');
                setModalCPOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ComposedModal>
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  DataTable,
  DatePicker,
  DatePickerInput,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  Select,
  SelectItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TextArea,
  TextInput,
  Tile
} from 'carbon-components-react';
import { TrashCan16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const headers = [
  { key: 'displayName', header: 'Display name' }
];

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function ThirdParty({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [tpName, setTPName] = useState('');
  const [tpNameInit, setTPNameInit] = useState('');
  const [tpDescription, setTPDescription] = useState('');
  const [tpEnabled, setTPEnabled] = useState('');
  const [tpAdmins, setTPAdmins] = useState('');
  const [tpContactName, setTPContactName] = useState('');
  const [tpContactEmail, setTPContactEmail] = useState('');
  const [tpContactPhone, setTPContactPhone] = useState('');
  const [tpStartDate, setTPStartDate] = useState('');
  const [tpEndDate, setTPEndDate] = useState('');
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [admins, setAdmins] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const userLocale = navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;
    let regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    // Validate tpName
    if (tpName === '') {
      errorList.tpName = 'Third party name cannot be empty';
      formIsValid = false;
    } else if (tpName.length > 64) {
      errorList.tpName = 'Third party name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate tpContactName
    if (tpContactName) {
      if (tpContactName > '') {
        if (tpContactName.length > 64) {
          errorList.tpContactName = 'Third party contact name cannot be greater than 64 characters in length';
          formIsValid = false;
        }
      }
    }

    // Validate tpContactEmail
    if (tpContactEmail > '') {
      if (!regexEmail.test(tpContactEmail)) {
        errorList.tpContactEmail = 'Invalid email address format';
        formIsValid = false;
      }
    }

    // Validate start/end dates
    if (tpStartDate !== '') {
      if (tpEndDate !== '') {
        var a = new Date(tpEndDate);
        var b = new Date(tpStartDate);
        if (a < b) {
          errorList.tpEndDate = 'End date is BEFORE the start date';
          formIsValid = false;
        }
      }
    }
    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function buildPayload() {
    var payLoad = {}
    payLoad.name =  tpName;
    payLoad.description = tpDescription;
    if (tpEnabled === 'true' || tpEnabled === true) {
      payLoad.enabled = true;
    } else {
      payLoad.enabled = false;
    }
    payLoad.contactName = tpContactName;
    payLoad.contactEmail = tpContactEmail;
    payLoad.contactPhone = tpContactPhone;
    payLoad.startDate = tpStartDate;
    payLoad.endDate = tpEndDate;
    if (tpAdmins === "N/A") {
      payLoad.administrators = null;
    } else {
      payLoad.administrators = tpAdmins;
    }
    return payLoad;
  }

  function onSubmit(data) {
    if (validateForm()) {
      return isAddMode ? createThirdParty() : updateThirdParty();
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function createThirdParty() {
    var payLoad = buildPayload();
    axios
      .post(apiuri + '/thirdparty', payLoad, { headers: httpHeaders })
      .then((response) => {
        history.push('/admin/thirdparty');
      })
      .catch((error) => {
        setModalMessage('An error has occurred creating the third party.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred creating the third party: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateThirdParty() {
    var payLoad = buildPayload();
    axios
      .put(apiuri + '/thirdparty/' + id, payLoad, { headers: httpHeaders })
      .then((response) => {
        history.push('/admin/thirdparty');
      })
      .catch((error) => {
        setModalMessage('An error has occurred updating the third party.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred updating the third party: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  /**
   * Load data
   */
  useEffect(() => {
    axios
      .get(apiuri + '/role/all', { headers: httpHeaders })
      .then((response) => {
        var tmpArray = [{ id: 'N/A', name: 'N/A' }];
        response.data.forEach((element) => {
          tmpArray.push({ id: element.id, name: element.name });
        });
        setAdmins(tmpArray);

        if (!isAddMode) {
          axios
            .get(apiuri + '/user/findByThirdParty?thirdparty=' + id, { headers: httpHeaders })
            .then((response) => {
              var payLoad = [];
              response.data.forEach((element) => {
                if (!element.displayName) {
                  element.displayName = element.name.givenName + " " + element.name.familyName;
                }
                element.displayName = <Link href={'/users/edit/' + element.id}>{element.displayName}</Link>;
                payLoad.push(element);
              })
              setRows(payLoad);
              setTotalItems(response.data.length);

              axios
                .get(apiuri + '/thirdparty/' + id, { headers: httpHeaders })
                .then((response) => {
                  if (response.data && response.data[0]) {
                    var tp = response.data[0];
                    setTPName(tp.name);
                    setTPNameInit(tp.name);
                    setTPDescription(tp.description);
                    setTPContactName(tp.contactName);
                    setTPContactEmail(tp.contactEmail);
                    setTPContactPhone(tp.contactPhone);
                    setTPAdmins(tp.administrators);
                    if (tp.startDate) {
                      setTPStartDate(tp.startDate);
                    }
                    if (tp.endDate) {
                      setTPEndDate(tp.endDate);
                    }
                    setTPEnabled(tp.enabled);

                    if (tp.members) {
                      var payLoad = [];
                      var aUsers = 0;
                      var iUsers = 0;
                      tp.members.forEach((element) => {
                        var obj = {
                          id: element._id
                        }
      
                        if (!element.displayName) {
                          if ((!element.name) || ((!element.name.givenName) && (!element.name.familyName))) {
                            // This should never happen!
                          } else {
                            element.displayName = element.name.givenName + " " + element.name.familyName;
                          }
                        }

                        if (element.displayName) {
                          obj.displayName = <Link href={'/users/edit/' + element._id}>{element.displayName}</Link>;
                          payLoad.push(obj);
                          if (element.active) {
                            aUsers++;
                          } else {
                            iUsers++;
                          }
                        }
                      })
                      setRows(payLoad);
                      setTotalItems(payLoad.length);
                      setTotalUsers(payLoad.length);
                      setTotalActiveUsers(aUsers);
                      setTotalInactiveUsers(iUsers);
                    }
                  }
                })
                .catch((error) => {
                  setModalMessage('An error has occurred retrieving the third party.');
                  if (error.response) {
                    if (error.response.status === 401) {
                      reauth();
                    }
                    if (error.response.status === 403) {
                      setModalMessage('You are not permitted to perform that operation');
                    } else {
                      if (error.response.data.message) {
                        setModalMessage('An error has occurred retrieving the third party: ' + error.response.data.message);
                      }
                    }
                  }
                  setModalType('danger');
                  setModalOpen(true);
                }); // end of get of third party
            })
            .catch((error) => {
              setModalMessage('An error has occurred retrieving the list of users.');
              if (error.response) {
                if (error.response.status === 401) {
                  reauth();
                }
                if (error.response.status === 403) {
                  setModalMessage('You are not permitted to perform that operation');
                } else {
                  if (error.response.data.message) {
                    setModalMessage('An error has occurred retrieving the list of users: ' + error.response.data.message);
                  }
                }
              }
              setModalType('danger');
              setModalOpen(true);
            }); // end of findUserByThirdParty
        } else {
          // Add mode only
          setTPAdmins('N/A');
          setTPEnabled('true');
        }
      }, []);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/thirdparty">
                  Third parties
                </BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">
                {isAddMode ? 'Add third party' : 'Edit third party'}
              </h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['tpName']}</span>
                  <TextInput
                    value={tpName}
                    id="name"
                    type="text"
                    labelText="Third party name"
                    onChange={(e) => setTPName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['tpDescription']}</span>
                  <TextArea
                    value={tpDescription}
                    cols={50}
                    id="description"
                    invalidText="Invalid error message."
                    labelText="Third party description"
                    rows={4}
                    onChange={(e) => setTPDescription(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={tpEnabled}
                    id="enabled"
                    invalidText="This is an invalid error message."
                    labelText="Status"
                    onChange={(e) => setTPEnabled(e.target.value)}
                  >
                    <SelectItem text="Enabled" value="true" />
                    <SelectItem text="Disabled" value="false" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['tpContactName']}</span>
                  <TextInput
                    value={tpContactName}
                    id="contactname"
                    type="text"
                    labelText="Contact name"
                    onChange={(e) => setTPContactName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['tpContactEmail']}</span>
                  <TextInput
                    value={tpContactEmail}
                    id="contactemail"
                    type="text"
                    labelText="Contact email"
                    onChange={(e) => setTPContactEmail(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['tpContactPhone']}</span>
                  <TextInput
                    value={tpContactPhone}
                    id="contactphone"
                    type="text"
                    labelText="Contact phone number"
                    onChange={(e) => setTPContactPhone(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={tpAdmins}
                    helperText="If an administrative group is added, only those administrators can add, modify &amp; delete users associated with this third party"
                    id="admins"
                    invalidText="This is an invalid error message."
                    labelText="Third party type administrators"
                    onChange={(e) => setTPAdmins(e.target.value)}
                  >
                    {admins.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <div class="bx--label">Third party start date</div>
                  <span style={{ color: 'red' }}>{formErrors['tpStartDate']}</span>
                  <DatePicker
                    id="startDate"
                    value={tpStartDate}
                    datePickerType="single"
                    dateFormat="d/m/Y"
                    onChange={(e) => setTPStartDate(e)}
                  >
                    <DatePickerInput
                      id="startDateInput"
                      placeholder="dd/mm/yyyy"
                      onChange={(e) => {
                        if (e.target.value.length === 10) {
                          if (userLocale === "en-GB") {
                            var bits = e.target.value.split("/");
                            setTPStartDate(new Date(bits[2] + "-" + bits[1] + "-" + bits[0]));
                          } else {
                            setTPStartDate(new Date(e.target.value));
                          }
                        }
                      }}
                    />
                    <Button
                      size="small"
                      kind="ghost"
                      renderIcon={TrashCan16}
                      hasIconOnly
                      iconDescription="Clear"
                      onClick={() => {
                        setTPStartDate('');
                      }}
                    />
                  </DatePicker>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <div class="bx--label">Third party end date</div>
                  <span style={{ color: 'red' }}>{formErrors['tpEndDate']}</span>
                  <DatePicker
                    id="endDate"
                    value={tpEndDate}
                    datePickerType="single"
                    dateFormat="d/m/Y"
                    onChange={(e) => setTPEndDate(e)}
                  >
                    <DatePickerInput
                      id="endDateInput"
                      placeholder="dd/mm/yyyy"
                      onChange={(e) => {
                        if (e.target.value.length === 10) {
                          if (userLocale === "en-GB") {
                            var bits = e.target.value.split("/");
                            setTPEndDate(new Date(bits[2] + "-" + bits[1] + "-" + bits[0]));
                          } else {
                            setTPEndDate(new Date(e.target.value));
                          }
                        }
                      }}
                    />
                    <Button
                      size="small"
                      kind="ghost"
                      renderIcon={TrashCan16}
                      hasIconOnly
                      iconDescription="Clear"
                      onClick={() => {
                        setTPEndDate('');
                      }}
                    />
                  </DatePicker>
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    {isAddMode ? 'Create third party' : 'Update third party'}
                  </Button>
                  <Button kind="secondary" onClick={() => (window.location.href = '/admin/thirdparty/') }>
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>

            <div className="bx--col-lg-8">
              {!isAddMode
                ?
                <>
                  <h3 className="umt--helper-heading">{tpNameInit} statistics</h3>
                  <div className="bx--row">
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Total users
                        </h3>
                        <p className="umt--tile-value">{totalUsers}</p>
                      </Tile>
                    </div>
                    <div className="bx--col-lg-3" />
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Active Users
                        </h3>
                        <p className="umt--tile-value">{totalActiveUsers}</p>
                      </Tile>
                    </div>
                    <div className="bx--col-lg-3" />
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Inactive Users
                        </h3>
                        <p className="umt--tile-value">{totalInactiveUsers}</p>
                      </Tile>
                    </div>
                  </div>
                  <p className="umt--helper-p"></p>

                  <h3 className="umt--helper-heading">{tpNameInit} users</h3>
                  <DataTable
                    rows={rows.slice(
                      firstRowIndex,
                      firstRowIndex + currentPageSize
                    )}
                    headers={headers}
                    isSortable
                    render={({
                      rows,
                      headers,
                      getHeaderProps,
                      getTableProps,
                      getSelectionProps
                    }) => (
                      <TableContainer className="umt--table-container">
                        <Table {...getTableProps()}>
                          <TableHead>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.id}>
                                {row.cells.map((cell) => (
                                  <TableCell key={cell.id}>
                                      {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  />
                  <Pagination
                    size="sm"
                    totalItems={totalItems}
                    backwardText="Previous page"
                    forwardText="Next page"
                    pageSize={currentPageSize}
                    pageSizes={[10]}
                    itemsPerPageText="# per page"
                    pageInputDisabled
                    pageSizeInputDisabled
                    onChange={({ page, pageSize }) => {
                      if (pageSize !== currentPageSize) {
                        setCurrentPageSize(pageSize);
                      }
                      setFirstRowIndex(pageSize * (page - 1));
                    }}
                  />
                </>
              :
                <>
                  <h3 className="umt--helper-heading">Help</h3>
                  <p className="umt--helper-p">
                    Third party organisations represent a supplier of users. The
                    type of organisation will define how lifecycle operations are
                    applied.
                    <br /><br />
                    NOTE: Third party names must be unique and should ideally be the
                    legal name of that organisation, i.e., Madigan Solutions UK Ltd
                    rather than Madigan.
                  </p>
                </>
              }
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Third party update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

/**
 * Locale information has been source from cldr.unicode.org
 *   -  CLDR41
 */

// Import core modules
import React from 'react';

// Import elements
import {
  SelectItem
} from 'carbon-components-react';



/**
 * Core function
 */
export default function SnipLocale() {
  return (
    <>
      <SelectItem text="N/A" value="" />
      <SelectItem text="af_NA" value="af_NA" />
      <SelectItem text="af" value="af" />
      <SelectItem text="af_ZA" value="af_ZA" />
      <SelectItem text="agq_CM" value="agq_CM" />
      <SelectItem text="agq" value="agq" />
      <SelectItem text="ak_GH" value="ak_GH" />
      <SelectItem text="ak" value="ak" />
      <SelectItem text="am_ET" value="am_ET" />
      <SelectItem text="am" value="am" />
      <SelectItem text="ar_001" value="ar_001" />
      <SelectItem text="ar_AE" value="ar_AE" />
      <SelectItem text="ar_BH" value="ar_BH" />
      <SelectItem text="ar_DJ" value="ar_DJ" />
      <SelectItem text="ar_DZ" value="ar_DZ" />
      <SelectItem text="ar_EG" value="ar_EG" />
      <SelectItem text="ar_EH" value="ar_EH" />
      <SelectItem text="ar_ER" value="ar_ER" />
      <SelectItem text="ar_IL" value="ar_IL" />
      <SelectItem text="ar_IQ" value="ar_IQ" />
      <SelectItem text="ar_JO" value="ar_JO" />
      <SelectItem text="ar_KM" value="ar_KM" />
      <SelectItem text="ar_KW" value="ar_KW" />
      <SelectItem text="ar_LB" value="ar_LB" />
      <SelectItem text="ar_LY" value="ar_LY" />
      <SelectItem text="ar_MA" value="ar_MA" />
      <SelectItem text="ar_MR" value="ar_MR" />
      <SelectItem text="ar_OM" value="ar_OM" />
      <SelectItem text="ar_PS" value="ar_PS" />
      <SelectItem text="ar_QA" value="ar_QA" />
      <SelectItem text="ar_SA" value="ar_SA" />
      <SelectItem text="ar_SD" value="ar_SD" />
      <SelectItem text="ar_SO" value="ar_SO" />
      <SelectItem text="ar_SS" value="ar_SS" />
      <SelectItem text="ar_SY" value="ar_SY" />
      <SelectItem text="ar_TD" value="ar_TD" />
      <SelectItem text="ar_TN" value="ar_TN" />
      <SelectItem text="ar" value="ar" />
      <SelectItem text="ar_YE" value="ar_YE" />
      <SelectItem text="asa_TZ" value="asa_TZ" />
      <SelectItem text="asa" value="asa" />
      <SelectItem text="as_IN" value="as_IN" />
      <SelectItem text="ast_ES" value="ast_ES" />
      <SelectItem text="ast" value="ast" />
      <SelectItem text="as" value="as" />
      <SelectItem text="az_Cyrl_AZ" value="az_Cyrl_AZ" />
      <SelectItem text="az_Cyrl" value="az_Cyrl" />
      <SelectItem text="az_Latn_AZ" value="az_Latn_AZ" />
      <SelectItem text="az_Latn" value="az_Latn" />
      <SelectItem text="az" value="az" />
      <SelectItem text="bas_CM" value="bas_CM" />
      <SelectItem text="bas" value="bas" />
      <SelectItem text="be_BY" value="be_BY" />
      <SelectItem text="bem" value="bem" />
      <SelectItem text="bem_ZM" value="bem_ZM" />
      <SelectItem text="be_TARASK" value="be_TARASK" />
      <SelectItem text="be" value="be" />
      <SelectItem text="bez_TZ" value="bez_TZ" />
      <SelectItem text="bez" value="bez" />
      <SelectItem text="bg_BG" value="bg_BG" />
      <SelectItem text="bg" value="bg" />
      <SelectItem text="bm_ML" value="bm_ML" />
      <SelectItem text="bm" value="bm" />
      <SelectItem text="bn_BD" value="bn_BD" />
      <SelectItem text="bn_IN" value="bn_IN" />
      <SelectItem text="bn" value="bn" />
      <SelectItem text="bo_CN" value="bo_CN" />
      <SelectItem text="bo_IN" value="bo_IN" />
      <SelectItem text="bo" value="bo" />
      <SelectItem text="br_FR" value="br_FR" />
      <SelectItem text="brx_IN" value="brx_IN" />
      <SelectItem text="br" value="br" />
      <SelectItem text="brx" value="brx" />
      <SelectItem text="bs_Cyrl_BA" value="bs_Cyrl_BA" />
      <SelectItem text="bs_Cyrl" value="bs_Cyrl" />
      <SelectItem text="bs_Latn_BA" value="bs_Latn_BA" />
      <SelectItem text="bs_Latn" value="bs_Latn" />
      <SelectItem text="bs" value="bs" />
      <SelectItem text="ca_AD" value="ca_AD" />
      <SelectItem text="ca_ES_VALENCIA" value="ca_ES_VALENCIA" />
      <SelectItem text="ca_ES" value="ca_ES" />
      <SelectItem text="ca_FR" value="ca_FR" />
      <SelectItem text="ca_IT" value="ca_IT" />
      <SelectItem text="ca" value="ca" />
      <SelectItem text="ccp_BD" value="ccp_BD" />
      <SelectItem text="ccp_IN" value="ccp_IN" />
      <SelectItem text="ccp" value="ccp" />
      <SelectItem text="ceb_PH" value="ceb_PH" />
      <SelectItem text="ceb" value="ceb" />
      <SelectItem text="ce_RU" value="ce_RU" />
      <SelectItem text="ce" value="ce" />
      <SelectItem text="cgg_UG" value="cgg_UG" />
      <SelectItem text="cgg" value="cgg" />
      <SelectItem text="chr_US" value="chr_US" />
      <SelectItem text="chr" value="chr" />
      <SelectItem text="ckb_IQ" value="ckb_IQ" />
      <SelectItem text="ckb_IR" value="ckb_IR" />
      <SelectItem text="ckb" value="ckb" />
      <SelectItem text="cs_CZ" value="cs_CZ" />
      <SelectItem text="cs" value="cs" />
      <SelectItem text="cy_GB" value="cy_GB" />
      <SelectItem text="cy" value="cy" />
      <SelectItem text="da_DK" value="da_DK" />
      <SelectItem text="da_GL" value="da_GL" />
      <SelectItem text="dav_KE" value="dav_KE" />
      <SelectItem text="dav" value="dav" />
      <SelectItem text="da" value="da" />
      <SelectItem text="de_AT" value="de_AT" />
      <SelectItem text="de_BE" value="de_BE" />
      <SelectItem text="de_CH" value="de_CH" />
      <SelectItem text="de_DE" value="de_DE" />
      <SelectItem text="de_IT" value="de_IT" />
      <SelectItem text="de_LI" value="de_LI" />
      <SelectItem text="de_LU" value="de_LU" />
      <SelectItem text="de" value="de" />
      <SelectItem text="dje_NE" value="dje_NE" />
      <SelectItem text="dje" value="dje" />
      <SelectItem text="doi_IN" value="doi_IN" />
      <SelectItem text="doi" value="doi" />
      <SelectItem text="dsb_DE" value="dsb_DE" />
      <SelectItem text="dsb" value="dsb" />
      <SelectItem text="dua_CM" value="dua_CM" />
      <SelectItem text="dua" value="dua" />
      <SelectItem text="dyo_SN" value="dyo_SN" />
      <SelectItem text="dyo" value="dyo" />
      <SelectItem text="dz_BT" value="dz_BT" />
      <SelectItem text="dz" value="dz" />
      <SelectItem text="ebu_KE" value="ebu_KE" />
      <SelectItem text="ebu" value="ebu" />
      <SelectItem text="ee_GH" value="ee_GH" />
      <SelectItem text="ee_TG" value="ee_TG" />
      <SelectItem text="ee" value="ee" />
      <SelectItem text="el_CY" value="el_CY" />
      <SelectItem text="el_GR" value="el_GR" />
      <SelectItem text="el" value="el" />
      <SelectItem text="en_001" value="en_001" />
      <SelectItem text="en_150" value="en_150" />
      <SelectItem text="en_AE" value="en_AE" />
      <SelectItem text="en_AG" value="en_AG" />
      <SelectItem text="en_AI" value="en_AI" />
      <SelectItem text="en_AS" value="en_AS" />
      <SelectItem text="en_AT" value="en_AT" />
      <SelectItem text="en_AU" value="en_AU" />
      <SelectItem text="en_BB" value="en_BB" />
      <SelectItem text="en_BE" value="en_BE" />
      <SelectItem text="en_BI" value="en_BI" />
      <SelectItem text="en_BM" value="en_BM" />
      <SelectItem text="en_BS" value="en_BS" />
      <SelectItem text="en_BW" value="en_BW" />
      <SelectItem text="en_BZ" value="en_BZ" />
      <SelectItem text="en_CA" value="en_CA" />
      <SelectItem text="en_CC" value="en_CC" />
      <SelectItem text="en_CH" value="en_CH" />
      <SelectItem text="en_CK" value="en_CK" />
      <SelectItem text="en_CM" value="en_CM" />
      <SelectItem text="en_CX" value="en_CX" />
      <SelectItem text="en_CY" value="en_CY" />
      <SelectItem text="en_DE" value="en_DE" />
      <SelectItem text="en_DG" value="en_DG" />
      <SelectItem text="en_DK" value="en_DK" />
      <SelectItem text="en_DM" value="en_DM" />
      <SelectItem text="en_ER" value="en_ER" />
      <SelectItem text="en_FI" value="en_FI" />
      <SelectItem text="en_FJ" value="en_FJ" />
      <SelectItem text="en_FK" value="en_FK" />
      <SelectItem text="en_FM" value="en_FM" />
      <SelectItem text="en_GB" value="en_GB" />
      <SelectItem text="en_GD" value="en_GD" />
      <SelectItem text="en_GG" value="en_GG" />
      <SelectItem text="en_GH" value="en_GH" />
      <SelectItem text="en_GI" value="en_GI" />
      <SelectItem text="en_GM" value="en_GM" />
      <SelectItem text="en_GU" value="en_GU" />
      <SelectItem text="en_GY" value="en_GY" />
      <SelectItem text="en_HK" value="en_HK" />
      <SelectItem text="en_IE" value="en_IE" />
      <SelectItem text="en_IL" value="en_IL" />
      <SelectItem text="en_IM" value="en_IM" />
      <SelectItem text="en_IN" value="en_IN" />
      <SelectItem text="en_IO" value="en_IO" />
      <SelectItem text="en_JE" value="en_JE" />
      <SelectItem text="en_JM" value="en_JM" />
      <SelectItem text="en_KE" value="en_KE" />
      <SelectItem text="en_KI" value="en_KI" />
      <SelectItem text="en_KN" value="en_KN" />
      <SelectItem text="en_KY" value="en_KY" />
      <SelectItem text="en_LC" value="en_LC" />
      <SelectItem text="en_LR" value="en_LR" />
      <SelectItem text="en_LS" value="en_LS" />
      <SelectItem text="en_MG" value="en_MG" />
      <SelectItem text="en_MH" value="en_MH" />
      <SelectItem text="en_MO" value="en_MO" />
      <SelectItem text="en_MP" value="en_MP" />
      <SelectItem text="en_MS" value="en_MS" />
      <SelectItem text="en_MT" value="en_MT" />
      <SelectItem text="en_MU" value="en_MU" />
      <SelectItem text="en_MV" value="en_MV" />
      <SelectItem text="en_MW" value="en_MW" />
      <SelectItem text="en_MY" value="en_MY" />
      <SelectItem text="en_NA" value="en_NA" />
      <SelectItem text="en_NF" value="en_NF" />
      <SelectItem text="en_NG" value="en_NG" />
      <SelectItem text="en_NL" value="en_NL" />
      <SelectItem text="en_NR" value="en_NR" />
      <SelectItem text="en_NU" value="en_NU" />
      <SelectItem text="en_NZ" value="en_NZ" />
      <SelectItem text="en_PG" value="en_PG" />
      <SelectItem text="en_PH" value="en_PH" />
      <SelectItem text="en_PK" value="en_PK" />
      <SelectItem text="en_PN" value="en_PN" />
      <SelectItem text="en_PR" value="en_PR" />
      <SelectItem text="en_PW" value="en_PW" />
      <SelectItem text="en_RW" value="en_RW" />
      <SelectItem text="en_SB" value="en_SB" />
      <SelectItem text="en_SC" value="en_SC" />
      <SelectItem text="en_SD" value="en_SD" />
      <SelectItem text="en_SE" value="en_SE" />
      <SelectItem text="en_SG" value="en_SG" />
      <SelectItem text="en_SH" value="en_SH" />
      <SelectItem text="en_SI" value="en_SI" />
      <SelectItem text="en_SL" value="en_SL" />
      <SelectItem text="en_SS" value="en_SS" />
      <SelectItem text="en_SX" value="en_SX" />
      <SelectItem text="en_SZ" value="en_SZ" />
      <SelectItem text="en_TC" value="en_TC" />
      <SelectItem text="en_TK" value="en_TK" />
      <SelectItem text="en_TO" value="en_TO" />
      <SelectItem text="en_TT" value="en_TT" />
      <SelectItem text="en_TV" value="en_TV" />
      <SelectItem text="en_TZ" value="en_TZ" />
      <SelectItem text="en_UG" value="en_UG" />
      <SelectItem text="en_UM" value="en_UM" />
      <SelectItem text="en_US_POSIX" value="en_US_POSIX" />
      <SelectItem text="en_US" value="en_US" />
      <SelectItem text="en_VC" value="en_VC" />
      <SelectItem text="en_VG" value="en_VG" />
      <SelectItem text="en_VI" value="en_VI" />
      <SelectItem text="en_VU" value="en_VU" />
      <SelectItem text="en_WS" value="en_WS" />
      <SelectItem text="en" value="en" />
      <SelectItem text="en_ZA" value="en_ZA" />
      <SelectItem text="en_ZM" value="en_ZM" />
      <SelectItem text="en_ZW" value="en_ZW" />
      <SelectItem text="eo_001" value="eo_001" />
      <SelectItem text="eo" value="eo" />
      <SelectItem text="es_419" value="es_419" />
      <SelectItem text="es_AR" value="es_AR" />
      <SelectItem text="es_BO" value="es_BO" />
      <SelectItem text="es_BR" value="es_BR" />
      <SelectItem text="es_BZ" value="es_BZ" />
      <SelectItem text="es_CL" value="es_CL" />
      <SelectItem text="es_CO" value="es_CO" />
      <SelectItem text="es_CR" value="es_CR" />
      <SelectItem text="es_CU" value="es_CU" />
      <SelectItem text="es_DO" value="es_DO" />
      <SelectItem text="es_EA" value="es_EA" />
      <SelectItem text="es_EC" value="es_EC" />
      <SelectItem text="es_ES" value="es_ES" />
      <SelectItem text="es_GQ" value="es_GQ" />
      <SelectItem text="es_GT" value="es_GT" />
      <SelectItem text="es_HN" value="es_HN" />
      <SelectItem text="es_IC" value="es_IC" />
      <SelectItem text="es_MX" value="es_MX" />
      <SelectItem text="es_NI" value="es_NI" />
      <SelectItem text="es_PA" value="es_PA" />
      <SelectItem text="es_PE" value="es_PE" />
      <SelectItem text="es_PH" value="es_PH" />
      <SelectItem text="es_PR" value="es_PR" />
      <SelectItem text="es_PY" value="es_PY" />
      <SelectItem text="es_SV" value="es_SV" />
      <SelectItem text="es_US" value="es_US" />
      <SelectItem text="es_UY" value="es_UY" />
      <SelectItem text="es_VE" value="es_VE" />
      <SelectItem text="es" value="es" />
      <SelectItem text="et_EE" value="et_EE" />
      <SelectItem text="et" value="et" />
      <SelectItem text="eu_ES" value="eu_ES" />
      <SelectItem text="eu" value="eu" />
      <SelectItem text="ewo_CM" value="ewo_CM" />
      <SelectItem text="ewo" value="ewo" />
      <SelectItem text="fa_AF" value="fa_AF" />
      <SelectItem text="fa_IR" value="fa_IR" />
      <SelectItem text="fa" value="fa" />
      <SelectItem text="ff_Adlm_BF" value="ff_Adlm_BF" />
      <SelectItem text="ff_Adlm_CM" value="ff_Adlm_CM" />
      <SelectItem text="ff_Adlm_GH" value="ff_Adlm_GH" />
      <SelectItem text="ff_Adlm_GM" value="ff_Adlm_GM" />
      <SelectItem text="ff_Adlm_GN" value="ff_Adlm_GN" />
      <SelectItem text="ff_Adlm_GW" value="ff_Adlm_GW" />
      <SelectItem text="ff_Adlm_LR" value="ff_Adlm_LR" />
      <SelectItem text="ff_Adlm_MR" value="ff_Adlm_MR" />
      <SelectItem text="ff_Adlm_NE" value="ff_Adlm_NE" />
      <SelectItem text="ff_Adlm_NG" value="ff_Adlm_NG" />
      <SelectItem text="ff_Adlm_SL" value="ff_Adlm_SL" />
      <SelectItem text="ff_Adlm_SN" value="ff_Adlm_SN" />
      <SelectItem text="ff_Adlm" value="ff_Adlm" />
      <SelectItem text="ff_Latn_BF" value="ff_Latn_BF" />
      <SelectItem text="ff_Latn_CM" value="ff_Latn_CM" />
      <SelectItem text="ff_Latn_GH" value="ff_Latn_GH" />
      <SelectItem text="ff_Latn_GM" value="ff_Latn_GM" />
      <SelectItem text="ff_Latn_GN" value="ff_Latn_GN" />
      <SelectItem text="ff_Latn_GW" value="ff_Latn_GW" />
      <SelectItem text="ff_Latn_LR" value="ff_Latn_LR" />
      <SelectItem text="ff_Latn_MR" value="ff_Latn_MR" />
      <SelectItem text="ff_Latn_NE" value="ff_Latn_NE" />
      <SelectItem text="ff_Latn_NG" value="ff_Latn_NG" />
      <SelectItem text="ff_Latn_SL" value="ff_Latn_SL" />
      <SelectItem text="ff_Latn_SN" value="ff_Latn_SN" />
      <SelectItem text="ff_Latn" value="ff_Latn" />
      <SelectItem text="ff" value="ff" />
      <SelectItem text="fi_FI" value="fi_FI" />
      <SelectItem text="fil_PH" value="fil_PH" />
      <SelectItem text="fil" value="fil" />
      <SelectItem text="fi" value="fi" />
      <SelectItem text="fo_DK" value="fo_DK" />
      <SelectItem text="fo_FO" value="fo_FO" />
      <SelectItem text="fo" value="fo" />
      <SelectItem text="fr_BE" value="fr_BE" />
      <SelectItem text="fr_BF" value="fr_BF" />
      <SelectItem text="fr_BI" value="fr_BI" />
      <SelectItem text="fr_BJ" value="fr_BJ" />
      <SelectItem text="fr_BL" value="fr_BL" />
      <SelectItem text="fr_CA" value="fr_CA" />
      <SelectItem text="fr_CD" value="fr_CD" />
      <SelectItem text="fr_CF" value="fr_CF" />
      <SelectItem text="fr_CG" value="fr_CG" />
      <SelectItem text="fr_CH" value="fr_CH" />
      <SelectItem text="fr_CI" value="fr_CI" />
      <SelectItem text="fr_CM" value="fr_CM" />
      <SelectItem text="fr_DJ" value="fr_DJ" />
      <SelectItem text="fr_DZ" value="fr_DZ" />
      <SelectItem text="fr_FR" value="fr_FR" />
      <SelectItem text="fr_GA" value="fr_GA" />
      <SelectItem text="fr_GF" value="fr_GF" />
      <SelectItem text="fr_GN" value="fr_GN" />
      <SelectItem text="fr_GP" value="fr_GP" />
      <SelectItem text="fr_GQ" value="fr_GQ" />
      <SelectItem text="fr_HT" value="fr_HT" />
      <SelectItem text="fr_KM" value="fr_KM" />
      <SelectItem text="fr_LU" value="fr_LU" />
      <SelectItem text="fr_MA" value="fr_MA" />
      <SelectItem text="fr_MC" value="fr_MC" />
      <SelectItem text="fr_MF" value="fr_MF" />
      <SelectItem text="fr_MG" value="fr_MG" />
      <SelectItem text="fr_ML" value="fr_ML" />
      <SelectItem text="fr_MQ" value="fr_MQ" />
      <SelectItem text="fr_MR" value="fr_MR" />
      <SelectItem text="fr_MU" value="fr_MU" />
      <SelectItem text="fr_NC" value="fr_NC" />
      <SelectItem text="fr_NE" value="fr_NE" />
      <SelectItem text="fr_PF" value="fr_PF" />
      <SelectItem text="fr_PM" value="fr_PM" />
      <SelectItem text="fr_RE" value="fr_RE" />
      <SelectItem text="fr_RW" value="fr_RW" />
      <SelectItem text="fr_SC" value="fr_SC" />
      <SelectItem text="fr_SN" value="fr_SN" />
      <SelectItem text="fr_SY" value="fr_SY" />
      <SelectItem text="fr_TD" value="fr_TD" />
      <SelectItem text="fr_TG" value="fr_TG" />
      <SelectItem text="fr_TN" value="fr_TN" />
      <SelectItem text="fr_VU" value="fr_VU" />
      <SelectItem text="fr_WF" value="fr_WF" />
      <SelectItem text="fr" value="fr" />
      <SelectItem text="fr_YT" value="fr_YT" />
      <SelectItem text="fur_IT" value="fur_IT" />
      <SelectItem text="fur" value="fur" />
      <SelectItem text="fy_NL" value="fy_NL" />
      <SelectItem text="fy" value="fy" />
      <SelectItem text="ga_GB" value="ga_GB" />
      <SelectItem text="ga_IE" value="ga_IE" />
      <SelectItem text="ga" value="ga" />
      <SelectItem text="gd_GB" value="gd_GB" />
      <SelectItem text="gd" value="gd" />
      <SelectItem text="gl_ES" value="gl_ES" />
      <SelectItem text="gl" value="gl" />
      <SelectItem text="gsw_CH" value="gsw_CH" />
      <SelectItem text="gsw_FR" value="gsw_FR" />
      <SelectItem text="gsw_LI" value="gsw_LI" />
      <SelectItem text="gsw" value="gsw" />
      <SelectItem text="gu_IN" value="gu_IN" />
      <SelectItem text="gu" value="gu" />
      <SelectItem text="guz_KE" value="guz_KE" />
      <SelectItem text="guz" value="guz" />
      <SelectItem text="gv_IM" value="gv_IM" />
      <SelectItem text="gv" value="gv" />
      <SelectItem text="ha_GH" value="ha_GH" />
      <SelectItem text="ha_NE" value="ha_NE" />
      <SelectItem text="ha_NG" value="ha_NG" />
      <SelectItem text="haw_US" value="haw_US" />
      <SelectItem text="haw" value="haw" />
      <SelectItem text="ha" value="ha" />
      <SelectItem text="he_IL" value="he_IL" />
      <SelectItem text="he" value="he" />
      <SelectItem text="hi_IN" value="hi_IN" />
      <SelectItem text="hi_Latn_IN" value="hi_Latn_IN" />
      <SelectItem text="hi_Latn" value="hi_Latn" />
      <SelectItem text="hi" value="hi" />
      <SelectItem text="hr_BA" value="hr_BA" />
      <SelectItem text="hr_HR" value="hr_HR" />
      <SelectItem text="hr" value="hr" />
      <SelectItem text="hsb_DE" value="hsb_DE" />
      <SelectItem text="hsb" value="hsb" />
      <SelectItem text="hu_HU" value="hu_HU" />
      <SelectItem text="hu" value="hu" />
      <SelectItem text="hy_AM" value="hy_AM" />
      <SelectItem text="hy" value="hy" />
      <SelectItem text="ia_001" value="ia_001" />
      <SelectItem text="ia" value="ia" />
      <SelectItem text="id_ID" value="id_ID" />
      <SelectItem text="id" value="id" />
      <SelectItem text="ig_NG" value="ig_NG" />
      <SelectItem text="ig" value="ig" />
      <SelectItem text="ii_CN" value="ii_CN" />
      <SelectItem text="ii" value="ii" />
      <SelectItem text="is_IS" value="is_IS" />
      <SelectItem text="is" value="is" />
      <SelectItem text="it_CH" value="it_CH" />
      <SelectItem text="it_IT" value="it_IT" />
      <SelectItem text="it_SM" value="it_SM" />
      <SelectItem text="it_VA" value="it_VA" />
      <SelectItem text="it" value="it" />
      <SelectItem text="ja_JP" value="ja_JP" />
      <SelectItem text="ja" value="ja" />
      <SelectItem text="jgo_CM" value="jgo_CM" />
      <SelectItem text="jgo" value="jgo" />
      <SelectItem text="jmc_TZ" value="jmc_TZ" />
      <SelectItem text="jmc" value="jmc" />
      <SelectItem text="jv_ID" value="jv_ID" />
      <SelectItem text="jv" value="jv" />
      <SelectItem text="kab_DZ" value="kab_DZ" />
      <SelectItem text="kab" value="kab" />
      <SelectItem text="ka_GE" value="ka_GE" />
      <SelectItem text="kam_KE" value="kam_KE" />
      <SelectItem text="kam" value="kam" />
      <SelectItem text="ka" value="ka" />
      <SelectItem text="kde_TZ" value="kde_TZ" />
      <SelectItem text="kde" value="kde" />
      <SelectItem text="kea_CV" value="kea_CV" />
      <SelectItem text="kea" value="kea" />
      <SelectItem text="kgp_BR" value="kgp_BR" />
      <SelectItem text="kgp" value="kgp" />
      <SelectItem text="khq_ML" value="khq_ML" />
      <SelectItem text="khq" value="khq" />
      <SelectItem text="ki_KE" value="ki_KE" />
      <SelectItem text="ki" value="ki" />
      <SelectItem text="kkj_CM" value="kkj_CM" />
      <SelectItem text="kkj" value="kkj" />
      <SelectItem text="kk_KZ" value="kk_KZ" />
      <SelectItem text="kk" value="kk" />
      <SelectItem text="kl_GL" value="kl_GL" />
      <SelectItem text="kln_KE" value="kln_KE" />
      <SelectItem text="kln" value="kln" />
      <SelectItem text="kl" value="kl" />
      <SelectItem text="km_KH" value="km_KH" />
      <SelectItem text="km" value="km" />
      <SelectItem text="kn_IN" value="kn_IN" />
      <SelectItem text="kn" value="kn" />
      <SelectItem text="kok_IN" value="kok_IN" />
      <SelectItem text="ko_KP" value="ko_KP" />
      <SelectItem text="ko_KR" value="ko_KR" />
      <SelectItem text="kok" value="kok" />
      <SelectItem text="ko" value="ko" />
      <SelectItem text="ks_Arab_IN" value="ks_Arab_IN" />
      <SelectItem text="ks_Arab" value="ks_Arab" />
      <SelectItem text="ksb_TZ" value="ksb_TZ" />
      <SelectItem text="ksb" value="ksb" />
      <SelectItem text="ks_Deva_IN" value="ks_Deva_IN" />
      <SelectItem text="ks_Deva" value="ks_Deva" />
      <SelectItem text="ksf_CM" value="ksf_CM" />
      <SelectItem text="ksf" value="ksf" />
      <SelectItem text="ksh_DE" value="ksh_DE" />
      <SelectItem text="ksh" value="ksh" />
      <SelectItem text="ks" value="ks" />
      <SelectItem text="ku_TR" value="ku_TR" />
      <SelectItem text="ku" value="ku" />
      <SelectItem text="kw_GB" value="kw_GB" />
      <SelectItem text="kw" value="kw" />
      <SelectItem text="ky_KG" value="ky_KG" />
      <SelectItem text="ky" value="ky" />
      <SelectItem text="lag_TZ" value="lag_TZ" />
      <SelectItem text="lag" value="lag" />
      <SelectItem text="lb_LU" value="lb_LU" />
      <SelectItem text="lb" value="lb" />
      <SelectItem text="lg_UG" value="lg_UG" />
      <SelectItem text="lg" value="lg" />
      <SelectItem text="lkt_US" value="lkt_US" />
      <SelectItem text="lkt" value="lkt" />
      <SelectItem text="ln_AO" value="ln_AO" />
      <SelectItem text="ln_CD" value="ln_CD" />
      <SelectItem text="ln_CF" value="ln_CF" />
      <SelectItem text="ln_CG" value="ln_CG" />
      <SelectItem text="ln" value="ln" />
      <SelectItem text="lo_LA" value="lo_LA" />
      <SelectItem text="lo" value="lo" />
      <SelectItem text="lrc_IQ" value="lrc_IQ" />
      <SelectItem text="lrc_IR" value="lrc_IR" />
      <SelectItem text="lrc" value="lrc" />
      <SelectItem text="lt_LT" value="lt_LT" />
      <SelectItem text="lt" value="lt" />
      <SelectItem text="lu_CD" value="lu_CD" />
      <SelectItem text="luo_KE" value="luo_KE" />
      <SelectItem text="luo" value="luo" />
      <SelectItem text="lu" value="lu" />
      <SelectItem text="luy_KE" value="luy_KE" />
      <SelectItem text="luy" value="luy" />
      <SelectItem text="lv_LV" value="lv_LV" />
      <SelectItem text="lv" value="lv" />
      <SelectItem text="mai_IN" value="mai_IN" />
      <SelectItem text="mai" value="mai" />
      <SelectItem text="mas_KE" value="mas_KE" />
      <SelectItem text="mas_TZ" value="mas_TZ" />
      <SelectItem text="mas" value="mas" />
      <SelectItem text="mer_KE" value="mer_KE" />
      <SelectItem text="mer" value="mer" />
      <SelectItem text="mfe_MU" value="mfe_MU" />
      <SelectItem text="mfe" value="mfe" />
      <SelectItem text="mgh_MZ" value="mgh_MZ" />
      <SelectItem text="mgh" value="mgh" />
      <SelectItem text="mg_MG" value="mg_MG" />
      <SelectItem text="mgo_CM" value="mgo_CM" />
      <SelectItem text="mgo" value="mgo" />
      <SelectItem text="mg" value="mg" />
      <SelectItem text="mi_NZ" value="mi_NZ" />
      <SelectItem text="mi" value="mi" />
      <SelectItem text="mk_MK" value="mk_MK" />
      <SelectItem text="mk" value="mk" />
      <SelectItem text="ml_IN" value="ml_IN" />
      <SelectItem text="ml" value="ml" />
      <SelectItem text="mni_Beng_IN" value="mni_Beng_IN" />
      <SelectItem text="mni_Beng" value="mni_Beng" />
      <SelectItem text="mni" value="mni" />
      <SelectItem text="mn_MN" value="mn_MN" />
      <SelectItem text="mn" value="mn" />
      <SelectItem text="mr_IN" value="mr_IN" />
      <SelectItem text="mr" value="mr" />
      <SelectItem text="ms_BN" value="ms_BN" />
      <SelectItem text="ms_ID" value="ms_ID" />
      <SelectItem text="ms_MY" value="ms_MY" />
      <SelectItem text="ms_SG" value="ms_SG" />
      <SelectItem text="ms" value="ms" />
      <SelectItem text="mt_MT" value="mt_MT" />
      <SelectItem text="mt" value="mt" />
      <SelectItem text="mua_CM" value="mua_CM" />
      <SelectItem text="mua" value="mua" />
      <SelectItem text="my_MM" value="my_MM" />
      <SelectItem text="my" value="my" />
      <SelectItem text="mzn_IR" value="mzn_IR" />
      <SelectItem text="mzn" value="mzn" />
      <SelectItem text="naq_NA" value="naq_NA" />
      <SelectItem text="naq" value="naq" />
      <SelectItem text="nb_NO" value="nb_NO" />
      <SelectItem text="nb_SJ" value="nb_SJ" />
      <SelectItem text="nb" value="nb" />
      <SelectItem text="nds_DE" value="nds_DE" />
      <SelectItem text="nds_NL" value="nds_NL" />
      <SelectItem text="nds" value="nds" />
      <SelectItem text="nd" value="nd" />
      <SelectItem text="nd_ZW" value="nd_ZW" />
      <SelectItem text="ne_IN" value="ne_IN" />
      <SelectItem text="ne_NP" value="ne_NP" />
      <SelectItem text="ne" value="ne" />
      <SelectItem text="nl_AW" value="nl_AW" />
      <SelectItem text="nl_BE" value="nl_BE" />
      <SelectItem text="nl_BQ" value="nl_BQ" />
      <SelectItem text="nl_CW" value="nl_CW" />
      <SelectItem text="nl_NL" value="nl_NL" />
      <SelectItem text="nl_SR" value="nl_SR" />
      <SelectItem text="nl_SX" value="nl_SX" />
      <SelectItem text="nl" value="nl" />
      <SelectItem text="nmg_CM" value="nmg_CM" />
      <SelectItem text="nmg" value="nmg" />
      <SelectItem text="nnh_CM" value="nnh_CM" />
      <SelectItem text="nnh" value="nnh" />
      <SelectItem text="nn_NO" value="nn_NO" />
      <SelectItem text="nn" value="nn" />
      <SelectItem text="no" value="no" />
      <SelectItem text="nus_SS" value="nus_SS" />
      <SelectItem text="nus" value="nus" />
      <SelectItem text="nyn_UG" value="nyn_UG" />
      <SelectItem text="nyn" value="nyn" />
      <SelectItem text="om_ET" value="om_ET" />
      <SelectItem text="om_KE" value="om_KE" />
      <SelectItem text="om" value="om" />
      <SelectItem text="or_IN" value="or_IN" />
      <SelectItem text="or" value="or" />
      <SelectItem text="os_GE" value="os_GE" />
      <SelectItem text="os_RU" value="os_RU" />
      <SelectItem text="os" value="os" />
      <SelectItem text="pa_Arab_PK" value="pa_Arab_PK" />
      <SelectItem text="pa_Arab" value="pa_Arab" />
      <SelectItem text="pa_Guru_IN" value="pa_Guru_IN" />
      <SelectItem text="pa_Guru" value="pa_Guru" />
      <SelectItem text="pa" value="pa" />
      <SelectItem text="pcm_NG" value="pcm_NG" />
      <SelectItem text="pcm" value="pcm" />
      <SelectItem text="pl_PL" value="pl_PL" />
      <SelectItem text="pl" value="pl" />
      <SelectItem text="ps_AF" value="ps_AF" />
      <SelectItem text="ps_PK" value="ps_PK" />
      <SelectItem text="ps" value="ps" />
      <SelectItem text="pt_AO" value="pt_AO" />
      <SelectItem text="pt_BR" value="pt_BR" />
      <SelectItem text="pt_CH" value="pt_CH" />
      <SelectItem text="pt_CV" value="pt_CV" />
      <SelectItem text="pt_GQ" value="pt_GQ" />
      <SelectItem text="pt_GW" value="pt_GW" />
      <SelectItem text="pt_LU" value="pt_LU" />
      <SelectItem text="pt_MO" value="pt_MO" />
      <SelectItem text="pt_MZ" value="pt_MZ" />
      <SelectItem text="pt_PT" value="pt_PT" />
      <SelectItem text="pt_ST" value="pt_ST" />
      <SelectItem text="pt_TL" value="pt_TL" />
      <SelectItem text="pt" value="pt" />
      <SelectItem text="qu_BO" value="qu_BO" />
      <SelectItem text="qu_EC" value="qu_EC" />
      <SelectItem text="qu_PE" value="qu_PE" />
      <SelectItem text="qu" value="qu" />
      <SelectItem text="rm_CH" value="rm_CH" />
      <SelectItem text="rm" value="rm" />
      <SelectItem text="rn_BI" value="rn_BI" />
      <SelectItem text="rn" value="rn" />
      <SelectItem text="rof_TZ" value="rof_TZ" />
      <SelectItem text="rof" value="rof" />
      <SelectItem text="ro_MD" value="ro_MD" />
      <SelectItem text="root" value="root" />
      <SelectItem text="ro_RO" value="ro_RO" />
      <SelectItem text="ro" value="ro" />
      <SelectItem text="ru_BY" value="ru_BY" />
      <SelectItem text="ru_KG" value="ru_KG" />
      <SelectItem text="ru_KZ" value="ru_KZ" />
      <SelectItem text="ru_MD" value="ru_MD" />
      <SelectItem text="ru_RU" value="ru_RU" />
      <SelectItem text="ru_UA" value="ru_UA" />
      <SelectItem text="ru" value="ru" />
      <SelectItem text="rwk_TZ" value="rwk_TZ" />
      <SelectItem text="rwk" value="rwk" />
      <SelectItem text="rw_RW" value="rw_RW" />
      <SelectItem text="rw" value="rw" />
      <SelectItem text="sah_RU" value="sah_RU" />
      <SelectItem text="sah" value="sah" />
      <SelectItem text="sa_IN" value="sa_IN" />
      <SelectItem text="saq_KE" value="saq_KE" />
      <SelectItem text="saq" value="saq" />
      <SelectItem text="sat_Olck_IN" value="sat_Olck_IN" />
      <SelectItem text="sat_Olck" value="sat_Olck" />
      <SelectItem text="sat" value="sat" />
      <SelectItem text="sa" value="sa" />
      <SelectItem text="sbp_TZ" value="sbp_TZ" />
      <SelectItem text="sbp" value="sbp" />
      <SelectItem text="sc_IT" value="sc_IT" />
      <SelectItem text="sc" value="sc" />
      <SelectItem text="sd_Arab_PK" value="sd_Arab_PK" />
      <SelectItem text="sd_Arab" value="sd_Arab" />
      <SelectItem text="sd_Deva_IN" value="sd_Deva_IN" />
      <SelectItem text="sd_Deva" value="sd_Deva" />
      <SelectItem text="sd" value="sd" />
      <SelectItem text="se_FI" value="se_FI" />
      <SelectItem text="seh_MZ" value="seh_MZ" />
      <SelectItem text="seh" value="seh" />
      <SelectItem text="se_NO" value="se_NO" />
      <SelectItem text="se_SE" value="se_SE" />
      <SelectItem text="ses_ML" value="ses_ML" />
      <SelectItem text="ses" value="ses" />
      <SelectItem text="se" value="se" />
      <SelectItem text="sg_CF" value="sg_CF" />
      <SelectItem text="sg" value="sg" />
      <SelectItem text="shi_Latn_MA" value="shi_Latn_MA" />
      <SelectItem text="shi_Latn" value="shi_Latn" />
      <SelectItem text="shi_Tfng_MA" value="shi_Tfng_MA" />
      <SelectItem text="shi_Tfng" value="shi_Tfng" />
      <SelectItem text="shi" value="shi" />
      <SelectItem text="si_LK" value="si_LK" />
      <SelectItem text="si" value="si" />
      <SelectItem text="sk_SK" value="sk_SK" />
      <SelectItem text="sk" value="sk" />
      <SelectItem text="sl_SI" value="sl_SI" />
      <SelectItem text="sl" value="sl" />
      <SelectItem text="smn_FI" value="smn_FI" />
      <SelectItem text="smn" value="smn" />
      <SelectItem text="sn" value="sn" />
      <SelectItem text="sn_ZW" value="sn_ZW" />
      <SelectItem text="so_DJ" value="so_DJ" />
      <SelectItem text="so_ET" value="so_ET" />
      <SelectItem text="so_KE" value="so_KE" />
      <SelectItem text="so_SO" value="so_SO" />
      <SelectItem text="so" value="so" />
      <SelectItem text="sq_AL" value="sq_AL" />
      <SelectItem text="sq_MK" value="sq_MK" />
      <SelectItem text="sq_XK" value="sq_XK" />
      <SelectItem text="sq" value="sq" />
      <SelectItem text="sr_Cyrl_BA" value="sr_Cyrl_BA" />
      <SelectItem text="sr_Cyrl_ME" value="sr_Cyrl_ME" />
      <SelectItem text="sr_Cyrl_RS" value="sr_Cyrl_RS" />
      <SelectItem text="sr_Cyrl_XK" value="sr_Cyrl_XK" />
      <SelectItem text="sr_Cyrl" value="sr_Cyrl" />
      <SelectItem text="sr_Latn_BA" value="sr_Latn_BA" />
      <SelectItem text="sr_Latn_ME" value="sr_Latn_ME" />
      <SelectItem text="sr_Latn_RS" value="sr_Latn_RS" />
      <SelectItem text="sr_Latn_XK" value="sr_Latn_XK" />
      <SelectItem text="sr_Latn" value="sr_Latn" />
      <SelectItem text="sr" value="sr" />
      <SelectItem text="su_Latn_ID" value="su_Latn_ID" />
      <SelectItem text="su_Latn" value="su_Latn" />
      <SelectItem text="su" value="su" />
      <SelectItem text="sv_AX" value="sv_AX" />
      <SelectItem text="sv_FI" value="sv_FI" />
      <SelectItem text="sv_SE" value="sv_SE" />
      <SelectItem text="sv" value="sv" />
      <SelectItem text="sw_CD" value="sw_CD" />
      <SelectItem text="sw_KE" value="sw_KE" />
      <SelectItem text="sw_TZ" value="sw_TZ" />
      <SelectItem text="sw_UG" value="sw_UG" />
      <SelectItem text="sw" value="sw" />
      <SelectItem text="ta_IN" value="ta_IN" />
      <SelectItem text="ta_LK" value="ta_LK" />
      <SelectItem text="ta_MY" value="ta_MY" />
      <SelectItem text="ta_SG" value="ta_SG" />
      <SelectItem text="ta" value="ta" />
      <SelectItem text="te_IN" value="te_IN" />
      <SelectItem text="teo_KE" value="teo_KE" />
      <SelectItem text="teo_UG" value="teo_UG" />
      <SelectItem text="teo" value="teo" />
      <SelectItem text="te" value="te" />
      <SelectItem text="tg_TJ" value="tg_TJ" />
      <SelectItem text="tg" value="tg" />
      <SelectItem text="th_TH" value="th_TH" />
      <SelectItem text="th" value="th" />
      <SelectItem text="ti_ER" value="ti_ER" />
      <SelectItem text="ti_ET" value="ti_ET" />
      <SelectItem text="ti" value="ti" />
      <SelectItem text="tk_TM" value="tk_TM" />
      <SelectItem text="tk" value="tk" />
      <SelectItem text="to_TO" value="to_TO" />
      <SelectItem text="to" value="to" />
      <SelectItem text="tr_CY" value="tr_CY" />
      <SelectItem text="tr_TR" value="tr_TR" />
      <SelectItem text="tr" value="tr" />
      <SelectItem text="tt_RU" value="tt_RU" />
      <SelectItem text="tt" value="tt" />
      <SelectItem text="twq_NE" value="twq_NE" />
      <SelectItem text="twq" value="twq" />
      <SelectItem text="tzm_MA" value="tzm_MA" />
      <SelectItem text="tzm" value="tzm" />
      <SelectItem text="ug_CN" value="ug_CN" />
      <SelectItem text="ug" value="ug" />
      <SelectItem text="uk_UA" value="uk_UA" />
      <SelectItem text="uk" value="uk" />
      <SelectItem text="ur_IN" value="ur_IN" />
      <SelectItem text="ur_PK" value="ur_PK" />
      <SelectItem text="ur" value="ur" />
      <SelectItem text="uz_Arab_AF" value="uz_Arab_AF" />
      <SelectItem text="uz_Arab" value="uz_Arab" />
      <SelectItem text="uz_Cyrl_UZ" value="uz_Cyrl_UZ" />
      <SelectItem text="uz_Cyrl" value="uz_Cyrl" />
      <SelectItem text="uz_Latn_UZ" value="uz_Latn_UZ" />
      <SelectItem text="uz_Latn" value="uz_Latn" />
      <SelectItem text="uz" value="uz" />
      <SelectItem text="vai_Latn_LR" value="vai_Latn_LR" />
      <SelectItem text="vai_Latn" value="vai_Latn" />
      <SelectItem text="vai_Vaii_LR" value="vai_Vaii_LR" />
      <SelectItem text="vai_Vaii" value="vai_Vaii" />
      <SelectItem text="vai" value="vai" />
      <SelectItem text="vi_VN" value="vi_VN" />
      <SelectItem text="vi" value="vi" />
      <SelectItem text="vun_TZ" value="vun_TZ" />
      <SelectItem text="vun" value="vun" />
      <SelectItem text="wae_CH" value="wae_CH" />
      <SelectItem text="wae" value="wae" />
      <SelectItem text="wo_SN" value="wo_SN" />
      <SelectItem text="wo" value="wo" />
      <SelectItem text="xh" value="xh" />
      <SelectItem text="xh_ZA" value="xh_ZA" />
      <SelectItem text="xog_UG" value="xog_UG" />
      <SelectItem text="xog" value="xog" />
      <SelectItem text="yav_CM" value="yav_CM" />
      <SelectItem text="yav" value="yav" />
      <SelectItem text="yi_001" value="yi_001" />
      <SelectItem text="yi" value="yi" />
      <SelectItem text="yo_BJ" value="yo_BJ" />
      <SelectItem text="yo_NG" value="yo_NG" />
      <SelectItem text="yo" value="yo" />
      <SelectItem text="yrl_BR" value="yrl_BR" />
      <SelectItem text="yrl_CO" value="yrl_CO" />
      <SelectItem text="yrl_VE" value="yrl_VE" />
      <SelectItem text="yrl" value="yrl" />
      <SelectItem text="yue_Hans_CN" value="yue_Hans_CN" />
      <SelectItem text="yue_Hans" value="yue_Hans" />
      <SelectItem text="yue_Hant_HK" value="yue_Hant_HK" />
      <SelectItem text="yue_Hant" value="yue_Hant" />
      <SelectItem text="yue" value="yue" />
      <SelectItem text="zgh_MA" value="zgh_MA" />
      <SelectItem text="zgh" value="zgh" />
      <SelectItem text="zh_Hans_CN" value="zh_Hans_CN" />
      <SelectItem text="zh_Hans_HK" value="zh_Hans_HK" />
      <SelectItem text="zh_Hans_MO" value="zh_Hans_MO" />
      <SelectItem text="zh_Hans_SG" value="zh_Hans_SG" />
      <SelectItem text="zh_Hans" value="zh_Hans" />
      <SelectItem text="zh_Hant_HK" value="zh_Hant_HK" />
      <SelectItem text="zh_Hant_MO" value="zh_Hant_MO" />
      <SelectItem text="zh_Hant_TW" value="zh_Hant_TW" />
      <SelectItem text="zh_Hant" value="zh_Hant" />
      <SelectItem text="zh" value="zh" />
      <SelectItem text="zu" value="zu" />
      <SelectItem text="zu_ZA" value="zu_ZA" />
      <SelectItem text="" value="zu_ZA" />
    </>
  );
}
/**
 * Timezone information sourced from https://en.wikipedia.org/wiki/List_of_time_zone_abbreviations
 */

// Import core modules
import React from 'react';

// Import elements
import {
  SelectItem
} from 'carbon-components-react';



/**
 * Core function
 */
export default function SnipTimezone() {
  return (
    <>
      <SelectItem text="N/A" value="" />
      <SelectItem text="UTC-12:00" value="UTC-12:00" />
      <SelectItem text="UTC-11:00" value="UTC-11:00" />
      <SelectItem text="UTC-10:00" value="UTC-10:00" />
      <SelectItem text="UTC-09:30" value="UTC-09:30" />
      <SelectItem text="UTC-09:00" value="UTC-09:00" />
      <SelectItem text="UTC-08:00" value="UTC-08:00" />
      <SelectItem text="UTC-07:00" value="UTC-07:00" />
      <SelectItem text="UTC-06:00" value="UTC-06:00" />
      <SelectItem text="UTC-05:00" value="UTC-05:00" />
      <SelectItem text="UTC-04:00" value="UTC-04:00" />
      <SelectItem text="UTC-03:30" value="UTC-03:30" />
      <SelectItem text="UTC-03:00" value="UTC-03:00" />
      <SelectItem text="UTC-02:00" value="UTC-02:00" />
      <SelectItem text="UTC-01:00" value="UTC-01:00" />
      <SelectItem text="UTC±00:00" value="UTC±00:00" />
      <SelectItem text="UTC+01:00" value="UTC+01:00" />
      <SelectItem text="UTC+02:00" value="UTC+02:00" />
      <SelectItem text="UTC+03:00" value="UTC+03:00" />
      <SelectItem text="UTC+03:30" value="UTC+03:30" />
      <SelectItem text="UTC+04:00" value="UTC+04:00" />
      <SelectItem text="UTC+04:30" value="UTC+04:30" />
      <SelectItem text="UTC+05:00" value="UTC+05:00" />
      <SelectItem text="UTC+05:30" value="UTC+05:30" />
      <SelectItem text="UTC+05:45" value="UTC+05:45" />
      <SelectItem text="UTC+06:00" value="UTC+06:00" />
      <SelectItem text="UTC+06:30" value="UTC+06:30" />
      <SelectItem text="UTC+07:00" value="UTC+07:00" />
      <SelectItem text="UTC+08:00" value="UTC+08:00" />
      <SelectItem text="UTC+08:45" value="UTC+08:45" />
      <SelectItem text="UTC+09:00" value="UTC+09:00" />
      <SelectItem text="UTC+09:30" value="UTC+09:30" />
      <SelectItem text="UTC+10:00" value="UTC+10:00" />
      <SelectItem text="UTC+10:30" value="UTC+10:30" />
      <SelectItem text="UTC+11:00" value="UTC+11:00" />
      <SelectItem text="UTC+12:00" value="UTC+12:00" />
      <SelectItem text="UTC+12:45" value="UTC+12:45" />
      <SelectItem text="UTC+13:00" value="UTC+13:00" />
      <SelectItem text="UTC+14:00" value="UTC+14:00" />
    </>
  );
}

// Import core modules
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

// Import libraries
import { useAppContext } from '../../lib/context';
import { reauth } from '../../lib/reauth';
import { useIdleTimer } from 'react-idle-timer';

// Import elements
import {
  Button,
  ComposedModal,ModalHeader,ModalBody,ModalFooter,
  Form,TextInput,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  HeaderPanel,
  Switcher,
  SwitcherDivider,
  SwitcherItem
} from 'carbon-components-react';

import { Help20, UserAvatar20 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function UMTHeaderLoggedIn({ history, match }) {
  const [userExpanded, setUserExpanded] = useState(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { isAuthenticated, setIsAuthenticated } = useAppContext();
  const [modalOpen, setModalOpen] = useState(false);
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');
  const [formErrors, setFormErrors] = useState({});

  const toggleUserMenu = () => {
    setUserExpanded(!userExpanded);
  };

  const goToHelp = () => {
    window.location.href = 'https://docs.madiganid.com/';
  };

  const {
    handleSubmit,
  } = useForm({});

  function decodeJWT(token) {
    var base64Url = token.split('.')[1];
    return JSON.parse(window.atob(base64Url));
  }

  function handleLogout() {
    setTimeoutModalOpen(false);
    localStorage.setItem('isAuthenticated', false);
    localStorage.setItem('accessToken', '');
    setIsAuthenticated(false);
    window.location.assign("/");
  }

  const handleIdle = () => {
    setTimeoutModalOpen(true);
  }

  const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
    const idleTimeout = 600000 * idleTime;
    const [isIdle, setIdle] = useState(false);
    const handleIdle = () => {
      setIdle(true);
      handleLogout();
    }
    const idleTimer = useIdleTimer({
      timeout: idleTimeout,
      promptTimeout: 60000,
      onPrompt: onIdle,
      onIdle: handleIdle,
      debounce: 500
    })
    return { isIdle, setIdle, idleTimer }
  }

  const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, idleTime: 5 });

  const stay = () => {
    setTimeoutModalOpen(false);
    idleTimer.reset();
  }



  function handlePasswordChange() {
    let errorList = [];
    let formIsValid = true;
    if (password === '') {
      errorList.password = 'Password cannot be empty';
      formIsValid = false;
    }
    if (newPassword === '') {
      errorList.newPassword = 'New password cannot be empty';
      formIsValid = false;
    }
    if (confirmNewPassword === '') {
      errorList.confirmNewPassword = 'Confirm new password cannot be empty';
      formIsValid = false;
    }
    if (newPassword !== confirmNewPassword) {
      errorList.confirmNewPassword = 'Passwords do not match';
      formIsValid = false;
    }

    if (!formIsValid) {
      setFormErrors(errorList);
    } else {
      var payLoad = {
        password: password,
        newPassword: newPassword
      };
      var jwt = decodeJWT(localStorage.getItem('accessToken'));
      axios.put(apiuri + '/user/changepassword/' + jwt.id, payLoad, { headers: httpHeaders }).then((response) => {
        // password updated
        window.location.href = '/users';
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred changing the password: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        //setModalOpen(true);

        // password change failed
        errorList.username = 'Oops';
        setFormErrors(errorList);
      });
    }
  }

  var isAdmin = false;
  var userName = '';
  var fullName;

  var jwt = decodeJWT(localStorage.getItem('accessToken'));
  if (jwt.permissions) {
    if(jwt.permissions.includes('attribute_r') ||
      jwt.permissions.includes('attribute_w') ||
      jwt.permissions.includes('audit_r') ||
      jwt.permissions.includes('lcr_r') ||
      jwt.permissions.includes('lcr_w') ||
      jwt.permissions.includes('mailtemplate_r') ||
      jwt.permissions.includes('mailtemplate_w') ||
      jwt.permissions.includes('role_r') ||
      jwt.permissions.includes('role_w') ||
      jwt.permissions.includes('settings_r') ||
      jwt.permissions.includes('settings_w') ||
      jwt.permissions.includes('thirdparty_r') ||
      jwt.permissions.includes('thirdparty_w') ||
      jwt.permissions.includes('usertype_r') ||
      jwt.permissions.includes('usertype_w')) {
      isAdmin = true;
    }
  }
  fullName = jwt.givenName + " " + jwt.familyName;
  userName = jwt.userName;

  useEffect(() => {
  }, [isAuthenticated]);

  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan UMT">
              <HeaderName element={Link} to="/users" prefix="Madigan">
                UMT
              </HeaderName>
              <HeaderNavigation aria-label="Madigan UMT">
                {isAdmin
                  ?
                    <HeaderMenuItem element={Link} to="/admin">Administration</HeaderMenuItem>
                  :
                    null
                }
                <HeaderMenuItem element={Link} to="/users">Users</HeaderMenuItem>
                <HeaderMenuItem element={Link} to="/approvals">Approvals</HeaderMenuItem>
                <HeaderMenuItem element={Link} to="/reports">Reports</HeaderMenuItem>
              </HeaderNavigation>
              <HeaderGlobalBar>
                <HeaderGlobalAction aria-label="Help" onClick={goToHelp}>
                  <Help20 />
                </HeaderGlobalAction>
                <HeaderGlobalAction aria-label="User Avatar" onClick={toggleUserMenu}>
                  <UserAvatar20 />
                </HeaderGlobalAction>
              </HeaderGlobalBar>
              <HeaderPanel aria-label="User navigation" expanded={userExpanded}>
                <Switcher aria-label="Logged in side panel">
                  <SwitcherItem aria-label="Logged in">Logged in as {fullName}</SwitcherItem>
                  <SwitcherItem aria-label="Change password" onClick={() => { setModalOpen(true) }}>Change password</SwitcherItem>
                  <SwitcherDivider />
                  <SwitcherItem aria-label="Logout" onClick={handleLogout}>Logout</SwitcherItem>
                </Switcher>
              </HeaderPanel>
            </Header>
          </>
        )}
      />

      <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
        <ModalHeader>
          <h3 className="umt--heading">Change password</h3>
        </ModalHeader>
        <Form onSubmit={handleSubmit(handlePasswordChange)}>
          <ModalBody>
            <span style={{ color: 'red' }}>{formErrors['password']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <TextInput.PasswordInput
                value={password}
                id="password"
                labelText="Current password"
                onChange={(e) => { setFormErrors([]); setPassword(e.target.value)}}
              />
            </div>

            <span style={{ color: 'red' }}>{formErrors['newPassword']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <TextInput.PasswordInput
                value={newPassword}
                id="newPassword"
                labelText="New password"
                onChange={(e) => { setFormErrors([]); setNewPassword(e.target.value)}}
              />
            </div>

            <span style={{ color: 'red' }}>{formErrors['confirmNewPassword']}</span>
            <div style={{ marginBottom: '2rem' }}>
              <TextInput.PasswordInput
                value={confirmNewPassword}
                id="confirmNewPassword"
                labelText="Confirm new password"
                onChange={(e) => { setFormErrors([]); setConfirmNewPassword(e.target.value)}}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button kind="primary" type="submit" onClick={() => { handlePasswordChange(); }}>
              Submit
            </Button>
            <Button kind="secondary" onClick={() => {
                setFormErrors([]);
                setPassword('');
                setNewPassword('');
                setConfirmNewPassword('');
                setModalOpen(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </ComposedModal>

      <ComposedModal open={timeoutModalOpen} onClose={() => { stay(); }} size="sm">
        <ModalHeader>
          <h3 className="umt--heading">Session timeout</h3>
        </ModalHeader>
        <ModalBody>
          Your session is about to time out.
        </ModalBody>
        <ModalFooter>
          <Button kind="primary" type="submit" onClick={() => { stay(); }}>
            Stay on page
          </Button>
          <Button kind="secondary" onClick={() => { handleLogout() }}>
            Log out
          </Button>
        </ModalFooter>
      </ComposedModal>
    </>
  );
}

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ComposedModal,
  Content,
  DataTable,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  OverflowMenu,
  OverflowMenuItem,
  Pagination,
  Tag
} from 'carbon-components-react';
import { Locked16, Unlocked16 } from '@carbon/icons-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const headers = [
  { key: 'name', header: 'Attribute name' },
  { key: 'labelen', header: 'Label (English)' },
  { key: 'type', header: 'Type' },
  { key: 'multiValued', header: 'Is multivalued' },
  { key: 'status', header: 'Status' }
];

const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableSelectAll,
  TableSelectRow,
  TableBatchAction,
  TableBatchActions
} = DataTable;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};



/**
 * Core function
 */
export default function Schema({ history }) {
  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [searchRows, setSearchRows] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [statuses, setStatuses] = useState([]);
  const [protectedStatus, setProtectedStatus] = useState([])



  /**
   * Load data
   */
  function loadData() {
    axios
      .get(apiuri + '/attribute/all', { headers: httpHeaders })
      .then((response) => {
        var payLoad = [];
        var statusArray = [];
        var protectedArray = [];
        var searchArray = [];

        response.data.forEach((element) => {
          statusArray[element.id] = element.enabled;
          protectedArray[element.id] = element.protected;
          var searchElement = '';

          if (element.parent) {
            element.name = element.name.replace(/\./g, " ⇨ ");
          }
          if (element.multiValued) {
            element.multiValued = "Multi-Valued";
          } else {
            element.multiValued = "";
          }
          searchElement = element.id + ":" + element.name + "|" + element.labelen;
          if (element.enabled === true) {
            element.name = <Link href={'/admin/schema/edit/' + element.id}>{element.name}</Link>;
            searchElement += "|Enabled";
            if (element.protected === true) {
              element.status = <Tag type='green'>Protected</Tag>;
            } else {
              element.status = <Tag type='green'>Enabled</Tag>;
            }
          } else {
            element.name = <Link disabled>{element.name}</Link>;
            element.labelen = <Link disabled>{element.labelen}</Link>;
            element.type = <Link disabled>{element.type}</Link>;
            element.multiValued = <Link disabled>{element.multiValued}</Link>;
            element.status = <Tag type='red'>Disabled</Tag>;
            searchElement += "|Disabled";
          }
          
          payLoad.push(element);
          searchArray.push(searchElement);
        });
        setRows(payLoad);
        setAllRows(payLoad);
        setSearchRows(searchArray);
        setTotalItems(response.data.length);
        setStatuses(statusArray);
        setProtectedStatus(protectedArray);
      })
      .catch((error) => {
        setModalMessage('An error has occurred retrieving the list of schema attributes.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred retrieving the list of schema attributes: ' + error.response.data.message);
            }
          }
        }
        setModalOpen(true);
      });
  }



  /**
   * Handle enable/disable operations
   */
  function setStatus(id, state) {
    var payLoad = { enabled: state }
    axios
      .put(apiuri + '/attribute/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { loadData(); })
      .catch((error) => {
        setModalMessage('An error has occurred setting the status of the attribute.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred setting the status of the attribute: ' + error.response.data.message);
            }
          }
        }
        setModalOpen(true);
      });
  }



  function handleFilter(filter) {
    if (filter === "") {
      setRows(allRows);
      setTotalItems(allRows.length);
    } else {
      var newRows = [];
      searchRows.forEach((element) => {
        if (element.split(":")[1].toLowerCase().includes(filter.toLowerCase())) {
          allRows.forEach((aElement) => {
            if (aElement.id === element.split(":")[0]) {
              newRows.push(aElement);
            }
          })
        }
      });
      setRows(newRows);
      setTotalItems(newRows.length);
    }
  }



  /**
   * Initialise
   */
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  /**
   * Return details
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <Breadcrumb
          noTrailingSlash
          aria-label="Page navigation"
          className="umt--breadcrumb"
        >
          <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
        </Breadcrumb>
        <h1 className="umt--heading">Schema management</h1>
        <div className="bx-grid">
          <div className="bx--row">
            <div className="bx--col">
              <DataTable
                rows={rows.slice(
                  firstRowIndex,
                  firstRowIndex + currentPageSize
                )}
                headers={headers}
                isSortable
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getTableProps,
                  getSelectionProps,
                  getToolbarProps,
                  getBatchActionProps,
                  onInputChange,
                  selectedRows,
                  getTableContainerProps
                }) => (
                  <TableContainer className="umt--table-container">
                    <TableToolbar {...getToolbarProps()}>
                      <TableBatchActions {...getBatchActionProps()}>
                        <TableBatchAction
                          renderIcon={Unlocked16}
                          onClick={()=> {
                            selectedRows.forEach((element) => {
                              setStatus(element.id, true);
                            })
                            window.location.href = '/admin/schema';
                          }}
                        >
                          Enable
                        </TableBatchAction>
                        <TableBatchAction
                          renderIcon={Locked16}
                          onClick={()=> {
                            selectedRows.forEach((element) => {
                              setStatus(element.id, false); 
                            })
                            window.location.href = '/admin/schema';
                          }}
                        >
                          Disable
                        </TableBatchAction>
                      </TableBatchActions>
                      <TableToolbarContent>
                        <TableToolbarSearch onChange={(e) => { handleFilter(e.target.value)}} />
                      </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          <TableSelectAll {...getSelectionProps()} />
                          {headers.map((header) => (
                            <TableHeader {...getHeaderProps({ header })}>
                              {header.header}
                            </TableHeader>
                          ))}
                          <TableHeader className="umt--table-overflow" />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.id}>
                            <TableSelectRow {...getSelectionProps({ row })} />
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                            <TableCell className="umt--table-overflow">
                              {statuses[row.id] && !protectedStatus[row.id]
                              ?
                                <>
                                  <OverflowMenu flipped>
                                    <OverflowMenuItem itemText="Edit" onClick={() => {window.location.href = '/admin/schema/edit/' + row.id}} />
                                    <OverflowMenuItem itemText="Enable" hasDivider disabled />
                                    <OverflowMenuItem itemText="Disable" isDelete onClick={() => { setStatus(row.id, false); }} />
                                  </OverflowMenu>
                                </>
                              : (statuses[row.id] && protectedStatus[row.id]) ?
                                <>
                                  <OverflowMenu flipped>
                                    <OverflowMenuItem itemText="Edit" onClick={() => {window.location.href = '/admin/schema/edit/' + row.id}} />
                                  </OverflowMenu>
                                </>
                              :
                                <>
                                  <OverflowMenu flipped>
                                    <OverflowMenuItem itemText="Edit" onClick={() => {window.location.href = '/admin/schema/edit/' + row.id}} />
                                    <OverflowMenuItem itemText="Enable" hasDivider onClick={() => { setStatus(row.id, true); }} />
                                    <OverflowMenuItem itemText="Disable" disabled />
                                  </OverflowMenu>
                                </>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                totalItems={totalItems}
                backwardText="Previous page"
                forwardText="Next page"
                pageSize={currentPageSize}
                pageSizes={[1, 5, 10, 20, 50, 100, 200]}
                itemsPerPageText="Items per page"
                onChange={({ page, pageSize }) => {
                  if (pageSize !== currentPageSize) {
                    setCurrentPageSize(pageSize);
                  }
                  setFirstRowIndex(pageSize * (page - 1));
                }}
              />
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Role management</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind="danger" onClick={() => { setModalOpen(false); }} >
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

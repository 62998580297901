// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  ComposedModal,
  Content,
  Form,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TextArea,
  TextInput,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function MailTemplate({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [baseTemplate, setBaseTemplate] = useState(false);
  const [mtName, setMTName] = useState('');
  const [mtFrom, setMTFrom] = useState('');
  const [mtSubject, setMTSubject] = useState('');
  const [mtHeader, setMTHeader] = useState('');
  const [mtBody, setMTBody] = useState('');
  const [mtFooter, setMTFooter] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState();
  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;

    // Validate mtName
    if (mtName === '') {
      errorList.mtName = 'Mail template name cannot be empty';
      formIsValid = false;
    } else if (mtName.length > 64) {
      errorList.mtName = 'Mail template name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate mtSubject
    if (mtSubject === '') {
      errorList.mtSubject = 'Mail template subject cannot be empty';
      formIsValid = false;
    } else if (mtSubject.length > 64) {
      errorList.mtSubject = 'Mail template subject cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate mtFrom
    if (mtFrom != '' && (!mtFrom.includes('@') || !mtFrom.includes('.'))) {
      errorList.mtFrom = 'Mail template from address must be formatted correctly, e.g., support@madiganid.com';
      formIsValid = false;
    } else if (mtFrom.length > 64) {
      errorList.mtSubject = 'Mail template from address cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    if (validateForm()) {
      return isAddMode ? createMT() : updateMT();
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function createMT() {
    var payLoad = {
      name: mtName,
      subject: mtSubject,
      body: mtBody,
      from: mtFrom,
    };
    
    axios
      .post(apiuri + '/mailtemplate', payLoad, { headers: httpHeaders })
      .then((response) => {
        history.push('/admin/mailtemplate');
      })
      .catch((error) => {
        setModalMessage('An error has occurred creating the mail template.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            var errMsg = "";
            if (error.response.data.message) {
              errMsg = error.response.data.message;
            }
            if (error.response.data.reason[0]) {
              errMsg = error.response.data.reason[0];
            }
            setModalMessage('An error has occurred creating the mail template: ' + errMsg);
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateMT() {
    var payLoad = {
      name: mtName,
      subject: mtSubject,
      body: mtBody,
      from: mtFrom,
    };
    if (baseTemplate) {
      payLoad.header = mtHeader;
      payLoad.footer = mtFooter;
    }

    axios
      .put(apiuri + '/mailtemplate/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/mailtemplate'); })
      .catch((error) => {
        setModalMessage('An error has occurred updating the mail template.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred updating the mail template: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  /**
   * Load data
   */
  useEffect(() => {
    if (!isAddMode) {
      // pre-populate the form
      axios
        .get(apiuri + '/mailtemplate/' + id, { headers: httpHeaders })
        .then((response) => {
          setMTName(response.data.name);
          setMTSubject(response.data.subject);
          setMTBody(response.data.body);
          setMTFrom(response.data.from);
          if (response.data.id === "000000000000000000000501") {
            setMTHeader(response.data.header);
            setMTFooter(response.data.footer);
            setBaseTemplate(true);
          }
        })
        .catch((error) => {
          setModalMessage('An error has occurred retrieving the mail template.');
          if (error.response) {
            if (error.response.status === 401) {
              reauth();
            }
            if (error.response.status === 403) {
              setModalMessage('You are not permitted to perform that operation');
            } else {
              if (error.response.data.message) {
                setModalMessage('An error has occurred retrieving the mail tepmlate: ' + error.response.data.message);
              }
            }
          }
          setModalType('danger');
          setModalOpen(true);
        });
    }
  }, []);



  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />
      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-10">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/mailtemplate">
                  Mail templates
                </BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">
                {isAddMode ? 'Add mail template' : 'Edit mail template'}
              </h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['mtName']}</span>
                  <TextInput
                    value={mtName}
                    placeHolder="Mail template name"
                    id="name"
                    type="text"
                    labelText="Name"
                    onChange={(e) => setMTName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['mtFrom']}</span>
                  <TextInput
                    value={mtFrom}
                    placeHolder="Mail template from address"
                    id="from"
                    type="text"
                    labelText="From"
                    onChange={(e) => setMTFrom(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>
                    {formErrors['mtSubject']}
                  </span>
                  <TextInput
                    value={mtSubject}
                    placeHolder="Mail subject"
                    id="subject"
                    type="text"
                    labelText="Subject"
                    onChange={(e) => setMTSubject(e.target.value)}
                  />
                </div>

                {baseTemplate
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['mtHeader']}</span>
                      <TextArea
                        value={mtHeader}
                        cols={50}
                        id="header"
                        labelText="Mail header"
                        rows={15}
                        onChange={(e) => setMTHeader(e.target.value)}
                      />
                    </div>
                  </>
                :
                  null
                }

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['mtBody']}</span>
                  <TextArea
                    value={mtBody}
                    cols={50}
                    id="body"
                    labelText="Mail body"
                    rows={15}
                    onChange={(e) => setMTBody(e.target.value)}
                  />
                </div>

                {baseTemplate
                ?
                  <>
                    <div style={{ marginBottom: '2rem' }}>
                      <span style={{ color: 'red' }}>{formErrors['mtFooter']}</span>
                      <TextArea
                        value={mtFooter}
                        cols={50}
                        id="footer"
                        labelText="Mail footer"
                        rows={15}
                        onChange={(e) => setMTFooter(e.target.value)}
                      />
                    </div>
                  </>
                :
                  null
                }

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    {isAddMode ? 'Create template' : 'Update template'}
                  </Button>
                  <Button
                    kind="secondary"
                    onClick={() =>
                      (window.location.href = '/admin/mailtemplate/')
                    }
                  >
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-6">
              <h3 className="umt--helper-heading">Help</h3>
              <p className="umt--helper-p">
                Mail templates are used in UMT notifications. They are customised at run time by the substitution of variables.
              </p>
              <h3 className="umt--helper-heading">Variables</h3>
              <p className="umt--helper-p">
                Variables are constructed by concatenating the target object and the
                attribute together and wrapping them in double asterisks, **.
                <br /><br />
                Valid target object types are:
                <ul>
                  <li>- RECIPIENT: The recipient of the email</li>
                  <li>- BENEFICIARY: The beneficiary of the action</li>
                </ul>
                <br />
                Common variables include:<br />
                **BENEFICIARY.displayName**<br />
                **RECIPIENT.name.givenName**<br />
                **RECIPIENT.name.familyName**<br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">Mail template update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}

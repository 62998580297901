// Import core modules
import React from 'react';

// Import elements
import { SideNav, SideNavItems, SideNavLink, SwitcherDivider } from 'carbon-components-react';

/**
 * Core function
 */
const ReportNav = () => (
  <SideNav
    isFixedNav
    expanded={true}
    isChildOfHeader={false}
    aria-label="Reports navigation"
    className="umt--sidenav"
  >
    <SideNavItems className="umt--sidenavitems">
      <SideNavLink className="umt--sidenavlink" href="/reports">
        Home
      </SideNavLink>
      <SideNavLink className="umt--sidenavlink" href="/reports/myschedule">
        My scheduled reports
      </SideNavLink>
      <SideNavLink className="umt--sidenavlink" href="/reports/download">
        Download a report
      </SideNavLink>

      <SwitcherDivider />

      <SideNavLink className="umt--sidenavlink" href="/reports/allschedules">
        All scheduled reports
      </SideNavLink>

    </SideNavItems>
  </SideNav>
);

export default ReportNav;

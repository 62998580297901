// Import components
import LandingPageWithOidc from './LandingPageOidc';
import LandingPageWithoutOidc from './LandingPageNoOidc';

// Import libraries
import { useAppContext } from '../../lib/context';

/**
 * Core function
 */
const LandingPage = () => {
  const { isAuthenticated, isOidcConfig } = useAppContext();

  if (isOidcConfig.toString() === 'true') {
    return (
      <>
        <LandingPageWithOidc />
      </>
    );
  } else if (isOidcConfig.toString() === 'false') {
    return (
      <>
        <LandingPageWithoutOidc />
      </>
    );
  } else {
    return (
      <>
        <LandingPageWithoutOidc />
      </>
    )
  }
};

export default LandingPage;

// Import core modules
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';

// Import components
import UMTHeader from '../../components/Header';
import Navigation from './_Navigation';
import { reauth } from '../../lib/reauth';

// Import elements
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  ButtonSet,
  Checkbox,
  ComposedModal,
  Content,
  DataTable,
  Form,
  Link,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  Select,
  SelectItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TextArea,
  TextInput,
  Tile,
  Toggle,
} from 'carbon-components-react';

// Setup variables
const apiuri = process.env.REACT_APP_APIURI;

const headers = [
  { key: 'displayName', header: 'Display name' }
];

const attrHeaders = [
  {key: 'labelen', header: 'Label'},
  {key: 'name', header: 'Attribute'},
]

const httpHeaders = {
  'Content-Type': 'application/json',
  Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
};

/**
 * Core function
 */
export default function UserType({ history, match }) {
  const { id } = match.params;
  const isAddMode = !id;

  const [totalItems, setTotalItems] = useState(0);
  const [firstRowIndex, setFirstRowIndex] = useState(0);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [rows, setRows] = useState([]);

  const [userTypeName, setUserTypeName] = useState('');
  const [userTypeNameInit, setUserTypeNameInit] = useState('');
  const [userTypeDescription, setUserTypeDescription] = useState('');
  const [userTypeContractBased, setUserTypeContractBased] = useState(false);
  const [userTypeContractLength, setUserTypeContractLength] = useState('');
  const [userTypeEnabled, setUserTypeEnabled] = useState('');
  const [userTypeManaged, setUserTypeManaged] = useState(true);
  const [userTypeAdministrator, setUserTypeAdministrator] = useState('N/A');
  const [userTypeApprover, setUserTypeApprover] = useState('N/A');
  const [userTypeBulk, setUserTypeBulk] = useState(false);

  const [totalUsers, setTotalUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);
  const [totalInactiveUsers, setTotalInactiveUsers] = useState(0);
  const [approvers, setApprovers] = useState([]);
  const [administrators, setAdministrators] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [modalType, setModalType] = useState('');

  const [availableAttrs, setAvailableAttrs] = useState([]);
  const [utAttributes, setUtAttributes] = useState([]);
  const [attrFirstRowIndex, setAttrFirstRowIndex] = useState(0);
  const [attrCurrentPageSize, setAttrCurrentPageSize] = useState(5);
  const [attrTotalItems, setAttrTotalItems] = useState(0);

  const [formErrors, setFormErrors] = useState({});

  const {
    handleSubmit,
  } = useForm({});

  /**
   * Configure functions for handling form submissions
   */
  function validateForm() {
    let errorList = {};
    let formIsValid = true;

    // Validate userTypeName
    if (userTypeName === '') {
      errorList.userTypeName = 'User type name cannot be empty';
      formIsValid = false;
    } else if (userTypeName.length > 64) {
      errorList.userTypeName =
        'User type name cannot be greater than 64 characters in length';
      formIsValid = false;
    }

    // Validate userTypeDescription
    if (userTypeDescription === '') {
      // OK - it's not mandatory
    } else if (userTypeDescription.length > 256) {
      errorList.userTypeDescription = 'User type description cannot be greater than 256 characters in length';
      formIsValid = false;
    }

    // Return state
    setFormErrors(errorList);
    return formIsValid;
  }

  function onSubmit(data) {
    if (validateForm()) {
      return isAddMode ? createUserType() : updateUserType();
    } else {
      setModalMessage('There are errors in your submission - please re-check');
      setModalType('danger');
      setModalOpen(true);
    }
  }

  function toggle(attr) {
    var tmpAttrs = [...utAttributes];
    if (tmpAttrs.includes(attr)) {
      // remove it
      const index = tmpAttrs.indexOf(attr);
      if (index > -1) {
        tmpAttrs.splice(index, 1);
      }
    } else {
      // add it
      tmpAttrs.push(attr);
    }
    setUtAttributes(tmpAttrs);
  }

  function createUserType() {
    var payLoad = {
      name: userTypeName,
      description: userTypeDescription,
      contractBased: userTypeContractBased,
      contractLength: userTypeContractLength,
      enabled: userTypeEnabled,
      managed: userTypeManaged,
      bulkLoadable: userTypeBulk
    };
    if (userTypeApprover !== 'N/A') {
      payLoad.approver = userTypeApprover;
    }
    if (userTypeAdministrator !== 'N/A') {
      payLoad.administrator = userTypeAdministrator;
    }
    axios
      .post(apiuri + '/usertype', payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/usertype') })
      .catch((error) => {
        setModalMessage('An error has occurred creating the user type.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred creating the user type: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  function updateUserType() {
    var payLoad = {
      name: userTypeName,
      description: userTypeDescription,
      contractBased: userTypeContractBased,
      contractLength: userTypeContractLength,
      enabled: userTypeEnabled,
      managed: userTypeManaged,
      bulkLoadable: userTypeBulk,
      approver: null,
      administrator: null,
      attributes: utAttributes,
    };
    if (userTypeApprover !== 'N/A') {
      payLoad.approver = userTypeApprover;
    }
    if (userTypeAdministrator !== 'N/A') {
      payLoad.administrator = userTypeAdministrator;
    }
    axios
      .put(apiuri + '/usertype/' + id, payLoad, { headers: httpHeaders })
      .then((response) => { history.push('/admin/usertype'); })
      .catch((error) => {
        setModalMessage('An error has occurred modifying the user type.');
        if (error.response) {
          if (error.response.status === 401) {
            reauth();
          }
          if (error.response.status === 403) {
            setModalMessage('You are not permitted to perform that operation');
          } else {
            if (error.response.data.message) {
              setModalMessage('An error has occurred updating the user type: ' + error.response.data.message);
            }
          }
        }
        setModalType('danger');
        setModalOpen(true);
      });
  }

  /**
   * Load data
   */
  useEffect(() => {
    axios
    .get(apiuri + '/attribute/all', { headers: httpHeaders })
    .then((response) => {
      const availableAttributes = response.data.filter(attribute =>
        attribute.enabled === true && attribute.protected === false
      );
      var attrPayload = [];
      availableAttributes.forEach((element) => {
        element.id = element._id;
        attrPayload.push(element);
      });
      setAttrTotalItems(attrPayload.length);
      setAvailableAttrs(attrPayload);
      
      axios
        .get(apiuri + '/role/all', { headers: httpHeaders })
        .then((response) => {
          var tmpArray = [{ id: 'N/A', name: 'N/A' }];
          response.data.forEach((element) => {
            tmpArray.push({ id: element.id, name: element.name });
          });
          setApprovers(tmpArray);
          setAdministrators(tmpArray);

          if (!isAddMode) {
            axios
              .get(apiuri + '/usertype/' + id + "?expanded=true", { headers: httpHeaders })
              .then((response) => {
                var ut = response.data[0];
                setUserTypeName(ut.name);
                setUserTypeNameInit(ut.name);
                setUserTypeDescription(ut.description);
                setUserTypeContractBased(ut.contractBased);
                setUserTypeContractLength(ut.contractLength);
                setUserTypeApprover(ut.approver);
                setUserTypeAdministrator(ut.administrator);
                setUserTypeEnabled(ut.enabled);
                setUserTypeManaged(ut.managed);
                setUserTypeBulk(ut.bulkLoadable);
                setUtAttributes(ut.attributes);

                if (ut.members) {
                  var payLoad = [];
                  var aUsers = 0;
                  var iUsers = 0;
                  ut.members.forEach((element) => {
                    var obj = {
                      id: element._id
                    }
          
                    if (!element.displayName) {
                      if ((!element.name) || ((!element.name.givenName) && (!element.name.familyName))) {
                        // This should never happen!
                      } else {
                        element.displayName = element.name.givenName + " " + element.name.familyName;
                      }
                    }
                    
                    // If we still don't have a displayname - all bets are off
                    if (element.displayName) {
                      obj.displayName = <Link href={'/users/edit/' + element._id}>{element.displayName}</Link>;
                      payLoad.push(obj);
                      if (element.active) {
                        aUsers++;
                      } else {
                        iUsers++;
                      }
                    }
                  });
                  setRows(payLoad);
                  setTotalItems(payLoad.length);
                  setTotalUsers(payLoad.length);
                  setTotalActiveUsers(aUsers);
                  setTotalInactiveUsers(iUsers);
                }
              })
              .catch((error) => {
                setModalMessage('An error has occurred retrieving the user type.');
                if (error.response) {
                  if (error.response.status === 401) {
                    reauth();
                  }
                  if (error.response.status === 403) {
                    setModalMessage('You are not permitted to perform that operation');
                  } else {
                    if (error.response.data.message) {
                      setModalMessage('An error has occurred retrieving the user type: ' + error.response.data.message);
                    }
                  }
                }
                setModalType('danger');
                setModalOpen(true);
              }); // end of GET of usertype
          } else {
            setUserTypeEnabled('true');
            setUserTypeContractLength('0');
          }
        }, []);
    })
    .catch((error) => {
      console.log(error);
    })
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Return the component detail
   */
  return (
    <>
      <UMTHeader />
      <Navigation />

      <Content id="main-content">
        <div className="bx--grid bx--grid--full-width">
          <div className="bx--row">
            <div className="bx--col-lg-8">
              <Breadcrumb
                noTrailingSlash
                aria-label="Page navigation"
                className="umt--breadcrumb"
              >
                <BreadcrumbItem href="/admin">Administration</BreadcrumbItem>
                <BreadcrumbItem href="/admin/usertype">
                  User types
                </BreadcrumbItem>
              </Breadcrumb>

              <h3 className="umt--heading">
                {isAddMode ? 'Add user type' : 'Edit user type'}
              </h3>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['userTypeName']}</span>
                  <TextInput
                    value={userTypeName}
                    id="name"
                    type="text"
                    labelText="User type name"
                    helperText="Unique name for the user type, e.g., Guest"
                    onChange={(e) => setUserTypeName(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <span style={{ color: 'red' }}>{formErrors['userTypeDescription']}</span>
                  <TextArea
                    value={userTypeDescription}
                    cols={50}
                    id="description"
                    invalidText="Invalid error message."
                    labelText="User type description"
                    placeholder="Describe how the user type will be used"
                    rows={4}
                    onChange={(e) => setUserTypeDescription(e.target.value)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={userTypeEnabled}
                    id="enabled"
                    invalidText="This is an invalid error message."
                    labelText="Status"
                    onChange={(e) => setUserTypeEnabled(e.target.value)}
                  >
                    <SelectItem text="Enabled" value="true" />
                    <SelectItem text="Disabled" value="false" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={userTypeManaged}
                    id="managed"
                    invalidText="This is an invalid error message."
                    labelText="Managed by UMT"
                    onChange={(e) => setUserTypeManaged(e.target.value)}
                  >
                    <SelectItem text="True" value="true" />
                    <SelectItem text="False" value="false" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={userTypeAdministrator}
                    id="administrators"
                    invalidText="This is an invalid error message."
                    labelText="User type administrators"
                    helperText="User type administrators have visibility of all users of this user type"
                    onChange={(e) => setUserTypeAdministrator(e.target.value)}
                  >
                    {administrators.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={userTypeApprover}
                    id="approvers"
                    invalidText="This is an invalid error message."
                    labelText="User type approvers"
                    helperText="If the creation or extension of users associated with these types requires approval, the approval team should be selected here"
                    onChange={(e) => setUserTypeApprover(e.target.value)}
                  >
                    {approvers.map((element) => (
                      <SelectItem text={element.name} value={element.id} />
                    ))}
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Checkbox
                    labelText='Contract based'
                    id='checkbox-contract-based'
                    checked={userTypeContractBased}
                    onChange={(e) => setUserTypeContractBased(!userTypeContractBased)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Checkbox
                    labelText='Bulk load'
                    id='checkbox-bulk-load'
                    checked={userTypeBulk}
                    onChange={(e) => setUserTypeBulk(!userTypeBulk)}
                  />
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <Select
                    value={userTypeContractLength}
                    id="contractlength"
                    invalidText="This is an invalid error message."
                    labelText="Maximum contract length"
                    onChange={(e) => setUserTypeContractLength(e.target.value)}
                  >
                    <SelectItem text="N/A" value="0" />
                    <SelectItem text="2 weeks" value="1" />
                    <SelectItem text="4 weeks" value="2" />
                    <SelectItem text="3 months" value="3" />
                    <SelectItem text="6 months" value="4" />
                    <SelectItem text="12 months" value="5" />
                    <SelectItem text="24 months" value="6" />
                  </Select>
                </div>

                <div style={{ marginBottom: '2rem' }}>
                  <p>Attributes</p>
                  <br />
                  <DataTable
                    rows={availableAttrs.slice(
                      attrFirstRowIndex,
                      attrFirstRowIndex + attrCurrentPageSize
                    )}
                    headers={attrHeaders}
                    isSortable={false}
                    size="lg"
                    render={({
                      rows,
                      headers,
                      getHeaderProps,
                      getTableProps,
                      getToolbarProps
                    }) => (
                      <TableContainer className="umt--table-container">
                        <Table className="umt--table" {...getTableProps()}>
                          <TableHead>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader>
                              ))}
                              <TableHeader className="umt--table-overflow" />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.id}>
                                {row.cells.map((cell) => (
                                  <TableCell key={cell.id}>
                                    {cell.value}
                                  </TableCell>
                                ))}
                                <TableCell className="umt--table-cell">
                                  <Toggle
                                    id={row.id}
                                    labelA=''
                                    labelB=''
                                    toggled={utAttributes.includes(row.id)}
                                    onToggle={() => toggle(row.id)}
                                    size="sm"
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  />
                  <Pagination
                    className="umt--pagination"
                    size="sm"
                    totalItems={attrTotalItems}
                    backwardText="Previous page"
                    forwardText="Next page"
                    pageSize={attrCurrentPageSize}
                    pageSizes={[5]}
                    itemsPerPageText="# per page"
                    pageInputDisabled
                    pageSizeInputDisabled
                    onChange={({ page, pageSize }) => {
                      if (pageSize !== attrCurrentPageSize) {
                        setAttrCurrentPageSize(pageSize);
                      }
                      setAttrFirstRowIndex(pageSize * (page - 1));
                    }}
                  />
                </div>

                <ButtonSet>
                  <Button kind="primary" type="submit">
                    {isAddMode ? 'Create user type' : 'Update user type'}
                  </Button>
                  <Button kind="secondary" onClick={() => (window.location.href = '/admin/usertype/')}>
                    Cancel
                  </Button>
                </ButtonSet>
              </Form>
            </div>
            <div className="bx--col-lg-8">
              {!isAddMode
                ?
                <>
                  <h3 className="umt--helper-heading">{userTypeNameInit} statistics</h3>
                  <div className="bx--row">
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Total Users
                        </h3>
                        <p className="umt--tile-value">{totalUsers}</p>
                      </Tile>
                    </div>
                    <div className="bx--col-lg-3" />
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Active Users
                        </h3>
                        <p className="umt--tile-value">{totalActiveUsers}</p>
                      </Tile>
                    </div>
                    <div className="bx--col-lg-3" />
                    <div className="bx--col-lg-3">
                      <Tile>
                        <h3 className="umt--tile-header">
                          Inactive Users
                        </h3>
                        <p className="umt--tile-value">{totalInactiveUsers}</p>
                      </Tile>
                    </div>
                  </div>
                  <p className="umt--helper-p"></p>
                  
                  <h3 className="umt--helper-heading">{userTypeNameInit} users</h3>
                  <DataTable
                    rows={rows.slice(
                      firstRowIndex,
                      firstRowIndex + currentPageSize
                    )}
                    headers={headers}
                    isSortable
                    render={({
                      rows,
                      headers,
                      getHeaderProps,
                      getTableProps,
                      getSelectionProps
                    }) => (
                      <TableContainer className="umt--table-container">
                        <Table {...getTableProps()}>
                          <TableHead>
                            <TableRow>
                              {headers.map((header) => (
                                <TableHeader {...getHeaderProps({ header })}>
                                  {header.header}
                                </TableHeader>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.id}>
                                {row.cells.map((cell) => (
                                  <TableCell key={cell.id}>
                                      {cell.value}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  />
                  <Pagination
                    size="sm"
                    totalItems={totalItems}
                    backwardText="Previous page"
                    forwardText="Next page"
                    pageSize={currentPageSize}
                    pageSizes={[10]}
                    itemsPerPageText="# per page"
                    pageInputDisabled
                    pageSizeInputDisabled
                    onChange={({ page, pageSize }) => {
                      if (pageSize !== currentPageSize) {
                        setCurrentPageSize(pageSize);
                      }
                      setFirstRowIndex(pageSize * (page - 1));
                    }}
                  />
                </>
                :
                <>
                  <h3 className="umt--helper-heading">Help</h3>
                  <p className="umt--helper-p">
                    User types allow for classification of users and will drive
                    workflow and lifecycle features.
                  </p>
                </>
              }
            </div>
          </div>
        </div>
      </Content>
      {modalOpen ? (
        <ComposedModal open={modalOpen} onClose={() => setModalOpen(false)} size="sm">
          <ModalHeader>
            <h3 className="umt--heading">User type update</h3>
          </ModalHeader>
          <ModalBody>
            <p className=".bx--modal-content__text">{modalMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button kind={modalType} onClick={() => { setModalOpen(false); }}>
              OK
            </Button>
          </ModalFooter>
        </ComposedModal>
      ) : null}
    </>
  );
}
import {
  Content,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderGlobalBar,
} from 'carbon-components-react';

export default function ErrorPage() {
  return (
    <>
      <HeaderContainer
        render={() => (
          <>
            <Header aria-label="Madigan UMT">
              <HeaderName prefix="Madigan">
                UMT
              </HeaderName>
              <HeaderGlobalBar />
            </Header>
            <Content>
                <h2>
                  Error
                </h2>
                <br />
                <div>
                  An error has occurred. Contact your system administrator.
                </div>
              </Content>
          </>
        )}
      />
    </>
  )
}
import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAppContext } from './context';

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { isAuthenticated } = useAppContext();
  console.log('ProtectedRoute: ' + isAuthenticated.toString());

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated.toString() === 'true' ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
}

export default ProtectedRoute;
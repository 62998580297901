import React, { useEffect, useState } from 'react';
import './App.scss';
import { AppContext } from './lib/context';

import ErrorPage from './pages/LandingPage/ErrorPage.js';

import { OidcProvider, withOidcSecure } from '@axa-fr/react-oidc';
import { configurationIdentityServer, oidcConfig } from './configurations.js';

import Routes from './routes';

const Loading = () => <p />;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') || false);
  const [isOidcConfig, setIsOidcConfig] = useState(localStorage.getItem('isOidcConfig') || false);

  useEffect(() => {
    if (oidcConfig === true) {
      setIsOidcConfig(true);
    }    
  }, [oidcConfig]);

  return (
    <>
      {
        (configurationIdentityServer.error)
      ?
        <ErrorPage />
      : (oidcConfig !== true)
      ?
        <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated, isOidcConfig, setIsOidcConfig }}>
          <Routes />
        </AppContext.Provider>
      :
        <OidcProvider
          configuration={configurationIdentityServer}
          loadingComponent={Loading}
          authenticatingComponent={Loading}
          callbackSuccessComponent={Loading}
        >
          <AppContext.Provider value={{ isAuthenticated, setIsAuthenticated, isOidcConfig, setIsOidcConfig }}>
            <Routes />
          </AppContext.Provider>
        </OidcProvider>
      }
    </>
  );
}

export default App;
